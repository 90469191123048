import { EventBus } from 'light-event-bus';

const _eventBus = () => {
  if (!window.eventBus) {
    window.eventBus = new EventBus();
  }

  return window.eventBus;
};

const eventBus = _eventBus();

export default eventBus;
