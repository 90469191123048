import { createModule, mutation, action } from 'vuex-class-component';

import { Inconsistencias } from '@/core/types/clientes/importacao';

import CrmClient from '@/api/clients/crm';
import ImportacaoModel from '@/core/models/importacao';
import ImportacoesTypesHelper from '@/core/types/clientes/importacao';
import { AtualizarStatusImportacaoEvent, BuscarInconsistenciasResult, ImportacaoDeClientesDTO } from '@/api/contracts/crm/importacao-clientes/importacao';

const VuexModule = createModule({
  namespaced: 'importacao',
  strict: false,
});

export default class ImportacaoModule extends VuexModule {
  listaDeImportacoes: ImportacaoDeClientesDTO[] = [];
  importacaoSelecionada:ImportacaoDeClientesDTO | undefined = undefined;
  inconsistenciasDaImportacaoSelecionada: BuscarInconsistenciasResult | undefined = undefined;
  identificador = '';

  @mutation
  obterEstadoPadrao(): void {
    this.listaDeImportacoes = [];
    this.inconsistenciasDaImportacaoSelecionada = undefined;
  }

  @mutation
  atualizarStatusImportacao(evento: any) {    
    const importacaoParaAtualizar = this.listaDeImportacoes.find(function (importacao) {
      return importacao.Id == evento.idImportacaoClientes;
    });

    if (importacaoParaAtualizar) {
      importacaoParaAtualizar.QtdeRegistrosCriados = evento.clientesCriados;
      importacaoParaAtualizar.QtdeRegistrosAlterados = evento.clientesAlterados;
      importacaoParaAtualizar.QtdeRegistrosInconsistentes = evento.clientesComInconsistencias;
      importacaoParaAtualizar.TotaldeRegistros = evento.clientesProcessados;
      importacaoParaAtualizar.IdSituacaoImportacao = evento.idSituacao;
      importacaoParaAtualizar.PorcentualRegistrosProcessados = evento.porcentagemProcessado;
    }
  }

  @mutation
  selecionarImportacaoGrid(idImportacao: number) {
    if (!this.importacaoSelecionada || ( this.importacaoSelecionada && this.importacaoSelecionada.Id != idImportacao))
      this.importacaoSelecionada = this.listaDeImportacoes.find(importacao => importacao.Id === idImportacao);
  }

  @action
  async obterDadosIniciaisImportacao(): Promise<void> {
    return CrmClient.obterDadosIniciaisImportacao().then((importacoesContract) => {
      this.listaDeImportacoes = importacoesContract.ListaImportacoesClientes;
      return Promise.resolve();
    });
  }
  @action
  async buscarInconsistenciasImportacao(idImportacao: number): Promise<void> {
    return CrmClient.buscarInconsistenciasImportacao(idImportacao).then((inconsistenciasContract) => {
      this.inconsistenciasDaImportacaoSelecionada = inconsistenciasContract;
      return Promise.resolve();      
    });
  }

  @action
  async downloadArquivoInconsistencias(idImportacao: number): Promise<JSON> {
    return CrmClient.downloadArquivoInconsistencias(idImportacao).then((arquivoInconsistenciasContract) => {
      return arquivoInconsistenciasContract;
    });
  }
  @action
  async downloadArquivoModelo(): Promise<JSON> {
    return CrmClient.downloadArquivoModelo().then((arquivoModeloContract) => {
      return arquivoModeloContract;
    });
  }
  @action
  async cancelarImportacao(payload: any): Promise<void> {
    return CrmClient.cancelarImportacao(payload).then((response) => {
      const resp = response;
    });
  }
  @action
  async importarClientePorArquivo(payload: any): Promise<void> {
    return CrmClient.importarClientesPorArquivo(payload).then((response) => {
      return response;
    });
  }
  @action
  async importarClientePorArquivoV2(file: FormData): Promise<void> {
    return CrmClient.importarClientesPorArquivoV2(file).then((response) => {
      return response;
    });
  }
}
