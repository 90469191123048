import { ObterDadosIniciaisCadastroResult } from '../contracts/crm/cadastro-completo/dadosIniciais';
import { ObterPessoaResult } from '../contracts/crm/cadastro-completo/obterPessoa';
import { ObterParametrosCrmResult } from '../contracts/crm/parametros/obterParametrosCrm';
import { ObterParametrosEmpresaCrmResult } from '../contracts/crm/parametros/obterParametrosEmpresaCrm';
import { ParametrosCrmDTO } from '../contracts/crm/parametros/AtualizarParametrosCrm';
import { EfetuarAnaliseCredito } from '../contracts/crm/analise-credito/efetuarAnaliseCredito';
import {
  ObterDadosIniciaisListagemResult,
  ListaDeClientesAtualizadosResult,
  FiltrosPesquisaAvancadaResult,
  FiltrosDTO,
  ClientesDTO,
} from '../contracts/crm/listagem-clientes/listagem';
import { BiometriaClienteCrm } from '../contracts/crm/biometria/biometriaClienteCrm';

import {
  ObterDadosPainelImportacaoResult,
  BuscarInconsistenciasResult,
  CancelarImportacaoDTO,
  ImportarDTO,
} from '../contracts/crm/importacao-clientes/importacao';

import { ObterCepPorEnderecoDTO, RetornoObterCepPorEnderecoDTO } from '../contracts/encontraCep';
import { ObterPermissaoPorIdDTO, RetornoObterPermissaoPorIdDTO } from '../contracts/buscarPermissao';

import FetchHandler from '../utils/handler';
import RequestExtension from '../utils/request';

import CrmRoutes from '@/api/routes/crm';
import { SalvarPessoaDTO, InserirPessoaResult, AnonimizarPessoaDTO } from '../contracts/crm/cadastro-completo/salvarPessoa';
const { obterDadosIniciais, obterPessoaPorDocumento, obterPessoa } = CrmRoutes.cadastro;
const {
  obterDadosIniciaisListagem,
  obterListagemClientes,
  listarFiltrosPesquisaAvancada,
  listarOpcoesDoFiltro,
  pesquisarClientes,
  usuariosSupervisores,
} = CrmRoutes.listagem;
const { obterParametrosCrm, atualizarParametrosCrm, obterParametrosEmpresaCrm } = CrmRoutes.parametros;
const { atualizar } = CrmRoutes.biometria;

import mockDadosIniciaisCadastro from '@/api/mocks/clientes/obterDadosIniciaisCadastroClientes';
import mockPessoaFisicaCliente from '@/api/mocks/clientes/obterPessoaPorDocumento/pessoaFisica.Cliente';
import { ObterUsuariosSupervisoresResult } from '../contracts/crm/cadastro-completo/usuariosSupervisores';

class CrmClient extends FetchHandler {
  public obterPessoaPorDocumento = async (documento: string): Promise<ObterPessoaResult> => {
    const request = RequestExtension.getDefaultGET(`${obterPessoaPorDocumento()}/${documento}`);
    return this.typedFetch<ObterPessoaResult>(request);
    // return mockPessoaFisicaCliente;
  };
  public obterPessoaPorId = async (idPessoa: number): Promise<ObterPessoaResult> => {
    const request = RequestExtension.getDefaultGET(`${obterPessoa()}/${idPessoa}`);
    return this.typedFetch<ObterPessoaResult>(request);
  };
  public obterDadosIniciaisCadastroCliente = async (): Promise<ObterDadosIniciaisCadastroResult> => {
    const request = RequestExtension.getDefaultGET(obterDadosIniciais());
    return this.typedFetch<ObterDadosIniciaisCadastroResult>(request);
  };
  public obterUsuariosSupervisores = async (): Promise<ObterUsuariosSupervisoresResult> => {
    const request = RequestExtension.getDefaultGET(usuariosSupervisores());
    return this.typedFetch<ObterUsuariosSupervisoresResult>(request);
    // return mockDadosIniciaisCadastro;
  };
  public inserirPessoa = async (inserirPessoaDTO: SalvarPessoaDTO): Promise<number> => {
    const request = RequestExtension.getDefaultPOST(`${CrmRoutes.cadastro.inserirPessoa()}`, inserirPessoaDTO);
    return this.typedFetch<number>(request);
  };
  public atualizarPessoa = async (atualizarPessoaDTO: SalvarPessoaDTO): Promise<void> => {
    const request = RequestExtension.getDefaultPOST(`${CrmRoutes.cadastro.atualizarPessoa()}`, atualizarPessoaDTO);
    return this.typedFetch<void>(request);
  };
  public atualizarPessoaBancos = async (atualizarPessoaDTO: SalvarPessoaDTO): Promise<void> => {
    const request = RequestExtension.getDefaultPOST(`${CrmRoutes.cadastro.atualizarPessoaBancos()}`, atualizarPessoaDTO);
    return this.typedFetch<void>(request);
  };
  public atualizarPermissao = async (permissao: ObterPermissaoPorIdDTO): Promise<boolean> => {
    const request = RequestExtension.getDefaultPOST(`${CrmRoutes.cadastro.atualizarPermissoes()}`, permissao);
    return this.typedFetch<boolean>(request);
  };
  public anonimizarPessoa = async (anonimizarPessoaDTO: AnonimizarPessoaDTO): Promise<void> => {
    const request = RequestExtension.getDefaultPOST(`${CrmRoutes.cadastro.anonimizarPessoa()}`, anonimizarPessoaDTO);
    return this.typedFetch<void>(request);
  };
  public obterCepPorEndereco = async (
    obterCepPorEnderecoDTO: ObterCepPorEnderecoDTO,
  ): Promise<Array<RetornoObterCepPorEnderecoDTO>> => {
    const request = RequestExtension.getDefaultPOST(`${CrmRoutes.cadastro.obterCepPorEndereco()}`, obterCepPorEnderecoDTO);
    return this.typedFetch<Array<RetornoObterCepPorEnderecoDTO>>(request);
  };

  public obterDadosIniciaisListagemCliente = async (): Promise<ObterDadosIniciaisListagemResult> => {
    const request = RequestExtension.getDefaultGET(obterDadosIniciaisListagem());
    return this.typedFetch<ObterDadosIniciaisListagemResult>(request);
  };
  public obterDadosListagemCliente = async (): Promise<ListaDeClientesAtualizadosResult> => {
    const request = RequestExtension.getDefaultGET(obterListagemClientes());
    return this.typedFetch<ListaDeClientesAtualizadosResult>(request);
  };
  public obterfiltrosPesquisaAvancada = async (): Promise<FiltrosPesquisaAvancadaResult> => {
    const request = RequestExtension.getDefaultGET(listarFiltrosPesquisaAvancada());
    return this.typedFetch<FiltrosPesquisaAvancadaResult>(request);
  };
  public listarOpcoesDeFiltro = async (payload: FiltrosDTO): Promise<[]> => {
    const request = RequestExtension.getDefaultPOST(`${CrmRoutes.listagem.listarOpcoesDoFiltro()}`, payload);
    return this.typedFetch<[]>(request);
  };
  public pesquisarClientes = async (payload: ClientesDTO): Promise<[]> => {
    const request = RequestExtension.getDefaultPOST(`${CrmRoutes.listagem.pesquisarClientes()}`, payload);
    return this.typedFetch<[]>(request);
  };
  public obterDadosIniciaisImportacao = async (): Promise<ObterDadosPainelImportacaoResult> => {
    const request = RequestExtension.getDefaultGET(`${CrmRoutes.importacao.obterDadosIniciaisPainelImportacao()}`);
    return this.typedFetch<ObterDadosPainelImportacaoResult>(request);
  };
  public buscarInconsistenciasImportacao = async (idImportacao: number): Promise<BuscarInconsistenciasResult> => {
    const request = RequestExtension.getDefaultGET(`${CrmRoutes.importacao.buscarInconsistencias()}${idImportacao}`);
    return this.typedFetch<BuscarInconsistenciasResult>(request);
  };
  public downloadArquivoInconsistencias = async (idImportacao: number): Promise<JSON> => {
    const request = RequestExtension.getDefaultGET(`${CrmRoutes.importacao.downloadArquivoInconsistencias()}${idImportacao}`);
    return this.typedFetch<JSON>(request);
  };
  public downloadArquivoModelo = async (): Promise<JSON> => {
    const request = RequestExtension.getDefaultGET(`${CrmRoutes.importacao.downloadArquivoModelo()}`);
    return this.typedFetch<JSON>(request);
  };
  public cancelarImportacao = async (payload: CancelarImportacaoDTO): Promise<void> => {
    const request = RequestExtension.getDefaultPOST(`${CrmRoutes.importacao.cancelarImportacao()}`, payload);
    return this.typedFetch<void>(request);
  };
  public importarClientesPorArquivo = async (payload: ImportarDTO): Promise<void> => {
    const request = RequestExtension.getDefaultPOST(`${CrmRoutes.importacao.importarClientesPorArquivo()} `, payload);
    return this.typedFetch<void>(request);
  };

  public importarClientesPorArquivoV2 = async (file: FormData): Promise<void> => {
    const request = RequestExtension.getFormDataPOST(`${CrmRoutes.importacao.importarClientesPorArquivoV2()}`, file);
    return this.typedFetch<void>(request);
  };

  public downloadAnexo = async (idAnexo: number): Promise<Blob> => {
    const request = RequestExtension.getDefaultGET(`${CrmRoutes.anexos.obterAnexo()}/${idAnexo}`);
    return this.blobFetch(request);
  };

  public excluirAnexo = async (idAnexo: number): Promise<void> => {
    const request = RequestExtension.getDefaultDELETE(`${CrmRoutes.anexos.excluirAnexo()}/${idAnexo}`);
    return this.typedFetch<void>(request);
  };

  public inserirAnexo = async (anexo: FormData): Promise<number> => {
    const request = RequestExtension.getFormDataPOST(`${CrmRoutes.anexos.inserirAnexo()}`, anexo);
    return this.typedFetch<number>(request);
  };
  public obterFotoPessoa = async (pessoa: number): Promise<Blob> => {
    const request = RequestExtension.getDefaultGET(`${CrmRoutes.docs.obterFotoPessoa()}/${pessoa}`);
    return this.blobFetch(request);
  };
  public inserirFotoPessoa = async (fotoPessoa: FormData): Promise<void> => {
    const request = RequestExtension.getFormDataPOST(`${CrmRoutes.docs.inserirFotoPessoa()}`, fotoPessoa);
    return this.typedFetch<void>(request);
  };

  public downloadDoc = async (idDoc: number): Promise<Blob> => {
    const request = RequestExtension.getDefaultGET(`${CrmRoutes.docs.obterDoc()}/${idDoc}`);
    return this.blobFetch(request);
  };

  public excluirDoc = async (idDoc: number): Promise<void> => {
    const request = RequestExtension.getDefaultDELETE(`${CrmRoutes.docs.excluirDoc()}/${idDoc}`);
    return this.typedFetch<void>(request);
  };
  public excluirFoto = async (idPessoa: number): Promise<void> => {
    const request = RequestExtension.getDefaultDELETE(`${CrmRoutes.docs.excluirFoto()}/${idPessoa}`);
    return this.typedFetch<void>(request);
  };

  public inserirDoc = async (doc: FormData): Promise<number> => {
    const request = RequestExtension.getFormDataPOST(`${CrmRoutes.docs.inserirDoc()}`, doc);
    return this.typedFetch<number>(request);
  };

  public obterParametrosCrm = async (): Promise<ObterParametrosCrmResult> => {
    const request = RequestExtension.getDefaultGET(`${obterParametrosCrm()}`);
    const result = await this.typedFetch<ObterParametrosCrmResult>(request);
    return result;
  };

  public obterParametrosEmpresaCrm = async (): Promise<ObterParametrosEmpresaCrmResult> => {
    const request = RequestExtension.getDefaultGET(`${obterParametrosEmpresaCrm()}`);
    const result = await this.typedFetch<ObterParametrosEmpresaCrmResult>(request);
    return result;
  };

  public atualizarParametrosCrm = async (atualizarParametrosCrmDTO: ParametrosCrmDTO): Promise<void> => {
    const request = RequestExtension.getDefaultPOST(
      `${CrmRoutes.parametros.atualizarParametrosCrm()}`,
      atualizarParametrosCrmDTO,
    );
    return this.typedFetch<void>(request);
  };

  public permiteExcluirContato = async (idContato: number) : Promise<boolean> => {
    const request = RequestExtension.getDefaultGET(
      `${CrmRoutes.cadastro.permiteExcluirContato()}/${idContato}`
    )
    return this.typedFetch<boolean>(request);
  }

  public atualizarBiometria = async (biometriaClienteCrm: BiometriaClienteCrm) => {
    const request = RequestExtension.getDefaultPOST(`${atualizar()}`, biometriaClienteCrm);
    return this.typedFetch<void>(request);
  };

  public gerarAnaliseCredito = async (idCliente: number): Promise<void> => {
    const efetuarAnaliseCredito: EfetuarAnaliseCredito = {
      IdPortal: 0,
      IdEmpresa: 0,
      IdUsuario: 0,
      CodigoCliente: idCliente,
      IntegradoSerasa: false,
      AnaliseAutomatica: false,
    };
    const request = RequestExtension.getDefaultPOST(`${CrmRoutes.analiseCredito.gerarAnaliseCredito()}`, efetuarAnaliseCredito);
    return this.typedFetch<void>(request);
  };
}

export default new CrmClient();
