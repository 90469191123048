import ErpAdminRoutes from '@/api/routes/erpAdmin';
import { LoginPorTokenDTO, LoginPorTokenResult } from '../contracts/erpAdmin';

import FetchHandler from '../utils/handler';
import RequestExtension from '../utils/request';

class ErpAdminClient extends FetchHandler {
  public executarLoginPorToken = async (loginPorTokenDTO: LoginPorTokenDTO, tokenTemporario: string): Promise<LoginPorTokenResult> => {
    const customHeader = new Headers({
      authtoken: tokenTemporario,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    });
    const request = RequestExtension.getDefaultPOST(`${ErpAdminRoutes.autenticacao.loginPorToken}`, loginPorTokenDTO, customHeader);
    return this.typedFetch<LoginPorTokenResult>(request);
  };
}

export default new ErpAdminClient();
