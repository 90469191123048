
  // BIBLIOTECAS
  import { defineComponent, reactive, toRefs, computed, inject } from 'vue';
  import { useRouter } from 'vue-router';

  // COMPONENTES
  import SideBarImagem from './SideBarImagem.vue';
  import CardCliente from './CardCliente.vue';

  // OUTROS
  import { vxm } from '@/store/index';
  import { TipoPessoa, TipoCliente } from '@/core/enums/pessoa';
  import { PermissoesUsuario } from '@/core/types/clientes/listagem';
  import AlertsExtension from '@/utils/alerts';

  export default defineComponent({
    name: 'SideBarCliente',
    props: {
      clienteSelecionado: {
        type: Object,
        required: true,
      },
    },
    components: { SideBarImagem, CardCliente },
    setup(props) {
      const emitter: any = inject('emitter');
      const router = useRouter();
      const data = reactive({
        collapse: false,
        dropdown: {
          show: false,
          parentStyle: 'vsm--item',
          arrowStyle: 'vsm--arrow',
        },
        permissoesUsuario: computed(() => {
          const permissoesUsuario = vxm.listagem.dadosIniciaisListagem.PermissoesUsuario
            ? vxm.listagem.dadosIniciaisListagem.PermissoesUsuario
            : ({} as PermissoesUsuario);

          return permissoesUsuario;
        }),
        urlBase: computed((): string =>
          vxm.listagem.dadosIniciaisListagem.UrlBaseErp ? vxm.listagem.dadosIniciaisListagem.UrlBaseErp : ''
        ),
        isClienteSelecionadoPessoaFisica: computed((): boolean => props.clienteSelecionado.TipoPessoa === TipoPessoa.fisica),
        isClienteSelecionadoPessoaJuridica: computed((): boolean => props.clienteSelecionado.TipoPessoa === TipoPessoa.juridica),
        idPessoa: computed((): number => props.clienteSelecionado.Id),
        tipoClienteSelecionado: computed((): number => props.clienteSelecionado.TipoCliente),
        tipoPessoaClienteSelecionado: computed((): number => props.clienteSelecionado.TipoPessoa),
        isTipoClienteSelecionadoFornecedor: computed((): boolean => data.tipoClienteSelecionado === TipoCliente.fornecedor),
        showMenu: computed((): boolean => Object.keys(props.clienteSelecionado).length !== 0),
        showOpcaoPedidosGarantia: computed((): boolean => vxm.listagem.dadosIniciaisListagem.EmpModGarantia || false),
        showOpcaoContatos: computed((): boolean => data.permissoesUsuario.CrmPermissoesClientesAlterarContatos),
        showOpcaoEditarCliente: computed((): boolean => {
          const { ambos, ambosTransportador, cliente, fornecedor, prospect, transportador } = TipoCliente;

          if (data.tipoClienteSelecionado === cliente && data.permissoesUsuario.CrmPermissoesClientesAlteracao) return true;

          if (data.tipoClienteSelecionado === fornecedor && data.permissoesUsuario.CrmPermissoesFornecedoresAlteracao) return true;

          if (data.tipoClienteSelecionado === ambos && (data.permissoesUsuario.CrmPermissoesClientesAlteracao || data.permissoesUsuario.CrmPermissoesFornecedoresAlteracao)) return true;

          if (data.tipoClienteSelecionado === transportador && data.permissoesUsuario.CrmPermissoesTransportadoresAlteracao) return true;

          if (data.tipoClienteSelecionado === prospect) return true;

          if(data.tipoClienteSelecionado === ambosTransportador && (data.permissoesUsuario.CrmPermissoesTransportadoresAlteracao || data.permissoesUsuario.CrmPermissoesClientesAlteracao || data.permissoesUsuario.CrmPermissoesFornecedoresAlteracao)) return true;

          return false;
        }),
        showOpcaoAnonimizarCliente: computed((): boolean => {
          const { cliente, ambos, ambosTransportador } = TipoCliente;
          const { fisica } = TipoPessoa;

          const permissaoAnonimizar = data.permissoesUsuario.CrmPermissoesClientesAlteracao 
            && data.permissoesUsuario.CrmAnonimizarDadosCliente;

          const isClientePessoaFisica = data.tipoClienteSelecionado == cliente && data.tipoPessoaClienteSelecionado == fisica;
          if (isClientePessoaFisica && permissaoAnonimizar) return true;

          const isAmbosPessoaFisica = data.tipoClienteSelecionado == ambos && data.tipoPessoaClienteSelecionado == fisica;
          const hasPermissaoParaAlterarAmbos = data.permissoesUsuario.CrmPermissoesClientesAlteracao || data.permissoesUsuario.CrmPermissoesFornecedoresAlteracao;

          const isAmbosTransportadorPessoaFisica = data.tipoClienteSelecionado === ambosTransportador && data.tipoPessoaClienteSelecionado === fisica;
          const hasPermissaoParaALterarAmbosTransportador = data.permissoesUsuario.CrmPermissoesClientesAlteracao || data.permissoesUsuario.CrmPermissoesFornecedoresAlteracao || data.permissoesUsuario.CrmPermissoesTransportadoresAlteracao;
          
          return (isAmbosPessoaFisica && hasPermissaoParaAlterarAmbos || isAmbosTransportadorPessoaFisica && hasPermissaoParaALterarAmbosTransportador) && data.permissoesUsuario.CrmAnonimizarDadosCliente;
        }),
        showOpcaoFichaCrediario: computed((): boolean => {
          const utilizaCrediarioFacil = vxm.listagem.dadosIniciaisListagem.UtilizaCrediarioFacil || false;

          return (
            utilizaCrediarioFacil &&
            data.permissoesUsuario.CrediarioFacilPermissaoAcessoFichaCliente &&
            data.tipoClienteSelecionado != TipoCliente.transportador
          );
        }),
        showOpcaoInformacoesFinanceirasPagar: computed((): boolean => {
          const { ambos, fornecedor, transportador } = TipoCliente;

          const seTipoClienteEhFornecedorOuAmbosOuTransportador =
            data.tipoClienteSelecionado == fornecedor ||
            data.tipoClienteSelecionado == ambos ||
            data.tipoClienteSelecionado == transportador;

          if (
            seTipoClienteEhFornecedorOuAmbosOuTransportador &&
            data.permissoesUsuario.CrmPermissoesFornecedoresInformacoesFinanceiras
          )
            return true;

          return false;
        }),
        showOpcaoInformacoesFinanceirasReceber: computed((): boolean => {
          const { fornecedor } = TipoCliente;

          const isClienteTipoFornecedor = data.tipoClienteSelecionado === fornecedor;
          if (!isClienteTipoFornecedor && data.permissoesUsuario.CrmPermissoesClientesInformacoesFinanceiras) return true;

          return false;
        }),
        showOpcaoMaisInformacoes: computed((): boolean => !data.collapse),
        labelTipoCliente: computed((): string => {
          return (
            vxm.listagem.dadosIniciaisListagem.TiposCliente?.find((t) => t.Id == data.tipoClienteSelecionado)?.Descricao || ''
          );
        }),
      });

      const methods = reactive({
        editar: (idPessoa: number) => {
          router.push({ name: 'CadastroClientes', params: { idPessoa: idPessoa } });
        },
        anonimizarCliente: (idPessoa: number) => {
          AlertsExtension.exibirMensagemPergunta(
            'Atenção!',
            'Ao transformar esta pessoa em anônima, todos os dados pessoais serão anonimizados e a pessoa será inativada. Essa operação não poderá ser revertida. Esta é uma ação que visa atender a Lei Geral de Proteção de Dados (LGPD). Deseja continuar?',
            (result) => {
              if (result) {
                vxm.loading.showLoading();
                var command = { IdPessoa: idPessoa };
                vxm.listagem
                  .anonimizarPessoa(command)
                  .then(() => {
                    AlertsExtension.exibirMensagemSucesso(`${data.labelTipoCliente} foi anonimizado com sucesso.`, false);
                    emitter.emit('atualizar-listagem');
                  })
                  .catch((error) => {
                    AlertsExtension.exibirMensagemErro(error, false);
                  })
                  .finally(() => {
                    vxm.loading.hideLoading();
                  });
              }
            },
          );
        },
        toggleState: () => {
          const elementCardCliente = document.getElementById('card-cliente');
          const elementCardClienteImagem = document.getElementById('img');
          const elementSideBar = document.getElementById('sidebar');

          if (elementCardCliente && elementCardClienteImagem && elementSideBar) {
            if (!data.collapse) {
              data.collapse = true;

              elementCardCliente.style.display = 'none';
              elementCardClienteImagem.style.display = 'none';

              let elements = document.getElementsByClassName('vsm--title');
              for (let element of elements) {
                element.classList.add('hide');
              }

              elementSideBar.style.width = '50px';
            } else {
              data.collapse = false;

              elementCardCliente.style.display = 'block';
              elementCardClienteImagem.style.display = 'block';

              let elements = document.getElementsByClassName('vsm--title');
              for (let element of elements) {
                element.classList.remove('hide');
              }

              elementSideBar.style.width = '300px';
            }
          }
        },
        handleOpenCloseDropdownMaisAcoes: () => {
          data.dropdown.show = !data.dropdown.show;

          if (data.dropdown.show) {
            data.dropdown.parentStyle = 'vsm--item vsm--item_open';
            data.dropdown.arrowStyle = 'vsm--arrow vsm--arrow_open';
          } else {
            data.dropdown.parentStyle = 'vsm--item';
            data.dropdown.arrowStyle = 'vsm--arrow';
          }
        },
      });

      return {
        ...toRefs(data),
        ...toRefs(methods),
        ...vxm,
      };
    },
  });
