
  import { defineComponent, reactive, toRefs, onMounted, computed, inject, watch, ref } from 'vue';

  import AlertsExtension from '@/utils/alerts';
  import { vxm } from '@/store/index';

  export default defineComponent({
    props: {
      idFiltro: { type: Number, default: 0 },
      nomeFiltro: { type: String, default: '' },
      selecaoMultipla: { type: Boolean, default: true },
      command: { type: String, default: '' },
      pesquisavel: { type: Boolean, default: false },
    },
    setup(props) {
      const emitter: any = inject('emitter');
      let opcoesSelecionadas = ref([]);

      const data = reactive({
        show: false,
        opcoes: [] as any[],
        idsOpcoesSelecionada: [],
        termoPesquisado: '',
        idxResultadoSelecionado: 0,
      });

      const tipoInputFiltro = computed(() => {
        return props.selecaoMultipla ? 'checkbox' : 'radio';
      });

      onMounted(() => {
        methods.buscarOpcoesSelecionaveisDoFiltro(true);
      });

      const methods = reactive({
        obterIdInputOpcaoFiltro: (idOpcao: any) => {
          if (idOpcao != null) return 'opcaoFiltro' + props.command + idOpcao;
        },
        buscarOpcoesSelecionaveisDoFiltro: (showLoading: boolean) => {
          if (showLoading) vxm.loading.showLoading('Buscando opções de filtro...');

          let command = {
            IdFiltro: props.idFiltro,
            TermoPesquisado: data.termoPesquisado,
          };

          vxm.listagem
            .listaOpcoesDoFiltro(command)
            .then((response: any) => {
              data.opcoes = response.Opcoes;
            })
            .catch((error) => {
              AlertsExtension.exibirMensagemErro(error, false);
            })
            .finally(() => {
              if (showLoading) vxm.loading.hideLoading();
            });
        },
        onArrowDown: () => {
          if (data.opcoes.length > 0) {
            if (data.idxResultadoSelecionado == null) {
              data.idxResultadoSelecionado = 0;
            } else {
              if (data.idxResultadoSelecionado == data.opcoes.length - 1) {
                data.idxResultadoSelecionado = 0;
              } else {
                data.idxResultadoSelecionado = data.idxResultadoSelecionado + 1;
              }
            }
          }
        },
        onArrowUp: () => {
          if (data.opcoes.length > 0) {
            if (data.idxResultadoSelecionado == null) {
              data.idxResultadoSelecionado = data.opcoes.length - 1;
            } else {
              if (data.idxResultadoSelecionado == 0) {
                data.idxResultadoSelecionado = data.opcoes.length - 1;
              } else {
                data.idxResultadoSelecionado = data.idxResultadoSelecionado - 1;
              }
            }
          }
        },
        adicionarResultadoSelecionado: (idxopcoes: number) => {
          data.idxResultadoSelecionado = idxopcoes;

          if (data.idxResultadoSelecionado != null) {
            var resultadoEncontrado = data.opcoes.find(function (opcao: any) {
              if (opcao.Id != null) return opcao.Id == data.opcoes[data.idxResultadoSelecionado];
            });
            if (!resultadoEncontrado) {
              data.opcoes.push(data.opcoes[data.idxResultadoSelecionado]);
            }
            data.termoPesquisado = '';
          }
        },
        removerFiltro: () => {
          emitter.emit('opcao-filtro-selecionada', Object.defineProperty({}, props.command, []));
          if (props.nomeFiltro == 'Cidade') {
            vxm.listagem.filtros.IdsCidades = [];
          }
          if (props.nomeFiltro == 'Tipo') {
            vxm.listagem.filtros.IdsTipoClientes = [];
          }
          if (props.nomeFiltro == 'Tipo') {
            vxm.listagem.filtros.IdsTipoClientes = [];
          }
          if (props.nomeFiltro == 'Classe') {
            vxm.listagem.filtros.IdsClasses = [];
          }
          if (props.nomeFiltro == 'Dependentes') {
            vxm.listagem.filtros.Dependentes = [];
          }
          emitter.emit('remover-filtro-selecionado', props.idFiltro);
        },
        fecharFiltro: () => {
          if (data.show) {
            console.log(data.show);
            data.show = false;
          }
        },
      });

      watch(opcoesSelecionadas, (opcoesSelecionadas) => {
        const idsOpcoesSelecionadas = opcoesSelecionadas.map((opcaoSelecionada: any) => opcaoSelecionada.Id) as Array<number>;
        if (props.nomeFiltro == 'Cidade') {
          vxm.listagem.filtros.IdsCidades = idsOpcoesSelecionadas;
        }
        if (props.nomeFiltro == 'Tipo') {
          vxm.listagem.filtros.IdsTipoClientes = idsOpcoesSelecionadas;
        }
        if (props.nomeFiltro == 'Tipo') {
          vxm.listagem.filtros.IdsTipoClientes = idsOpcoesSelecionadas;
        }
        if (props.nomeFiltro == 'Classe') {
          vxm.listagem.filtros.IdsClasses = idsOpcoesSelecionadas;
        }
        if (props.nomeFiltro == 'Dependentes') {
          const descricoesOpcoesSelecionadas = opcoesSelecionadas.map((opcao: any) => opcao.Descricao) as Array<string>;
          vxm.listagem.filtros.Dependentes = descricoesOpcoesSelecionadas;
        }
      });

      return {
        ...vxm,
        ...toRefs(data),
        ...toRefs(methods),
        tipoInputFiltro,
        opcoesSelecionadas,
      };
    },
  });
