//MODAJOI-37214 (US 001)
import { createModule, mutation, action } from 'vuex-class-component';

import EsClient from '@/api/clients/es';
import NotaSimplificadaMapping from '@/core/mappings/notaSimplificada';
import NotaSimplificadaTypes, { DadosFreteTipos, FreteTipos } from '@/core/types/es/notaSimplificada';

const { obterDadosFreteTiposMapping } = NotaSimplificadaMapping;

const VuexModule = createModule({
  namespaced: 'notaSimplificada',
  strict: false,
});
export default class NotaSimplificadaModule extends VuexModule {
  dadosFreteTipos = null as DadosFreteTipos | null;

  @action
  async obterListagemFreteTipos(): Promise<DadosFreteTipos> {
    return EsClient.obterListagemFreteTipos().then((dadosIniciaisFreteTiposResult) => {
      return obterDadosFreteTiposMapping(dadosIniciaisFreteTiposResult);
    });
  }
}