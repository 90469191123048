import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "multiselect-option" }

import { PropType } from 'vue';

  import VueFormMultiselect from '@vueform/multiselect';

  type Group = {
    label: string;
    options: any;
  };

  
export default _defineComponent({
  expose: [],
  props: {
    modelValue: {
      type: Array as PropType<Array<any>>,
      required: true,
    },
    groupOptions: {
      type: Array as PropType<Array<Group>>,
      required: true,
    },
  },
  emits: ['update:modelValue', 'input'],
  setup(__props, { emit }) {

const props = __props
  

  

  const getMultipleLabel = (value: Array<any>) => {
    if (value.length === 1) return '1 opção selecionada';
    else return `${value.length} opções selecionadas`;
  };

  const checkIfAllOptionsOfTheGroupAreSelected = (options: Array<any>) => {
    const found = options.every(({ value }) => props.modelValue.includes(value));
    return found;
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(VueFormMultiselect), {
    modelValue: props.modelValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (emit('update:modelValue', $event))),
    onInput: _cache[2] || (_cache[2] = ($event: any) => (emit('input', $event))),
    options: props.groupOptions,
    class: 'w-100',
    searchable: true,
    mode: 'multiple',
    placeholder: 'Selecione',
    noOptionsText: 'Não existem registros cadastrados.',
    noResultsText: 'Nenhum registro encontrado.',
    hideSelected: false,
    closeOnSelect: false,
    groups: true,
    groupHideEmpty: true,
    groupOptions: 'options',
    groupLabel: 'label',
    groupSelect: true,
    multipleLabel: getMultipleLabel
  }, {
    grouplabel: _withCtx(({ group }) => [
      _createVNode("template", _hoisted_1, [
        _createVNode("span", null, _toDisplayString(group.label) + " - " + _toDisplayString(checkIfAllOptionsOfTheGroupAreSelected(group.options) ? 'Clique para remover todos' : 'Clique para selecionar todos'), 1)
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "options", "noOptionsText", "noResultsText"]))
}
}

})