<template>
  <div :id="idSecaoMenu" class="card-panel bg-white shadow-hover shadow-sm mt-2">
    <h1 class="h1"> Campos adicionais </h1>

    <div>
      <div v-for="campo in cadastro.pessoa.camposAdicionaisValores" :key="campo.idCampo">
        <div
          class="d-flex flex-column margin-my-2 flex-grow-1 w-100"
          v-if="retornaTipoCampoAdicional(campo.idCampo.valor) == '1'"
        >
          <!--Texto-->
          <label class="m-0"> {{ retornaNomeCampoAdicional(campo.idCampo.valor) }} </label>
          <input
            class="form-control form-control-sm"
            type="text"
            id="text-campos-adicionais"
            v-model="campo.contentCampo.valor"
          />
        </div>

        <div class="custom-control custom-checkbox mt-2" v-if="retornaTipoCampoAdicional(campo.idCampo.valor) == '2'">
          <!--CheckBox-->
          <input
            class="custom-control-input"
            type="checkbox"
            :id="retornaNomeCampoAdicional(campo.idCampo.valor)"
            @change="identificaCliqueCheckBox(campo)"
            :checked="preencheValorCheckBox(campo.contentCampo.valor)"
          />
          <label :for="retornaNomeCampoAdicional(campo.idCampo.valor)" class="custom-control-label">
            {{ retornaNomeCampoAdicional(campo.idCampo.valor) }}</label
          >
        </div>

        <div
          class="d-flex flex-column margin-my-2 flex-grow-1 w-100"
          v-if="retornaTipoCampoAdicional(campo.idCampo.valor) == '3'"
        >
          <!--MultiSelect -->
          <label class="m-0"> {{ retornaNomeCampoAdicional(campo.idCampo.valor) }} </label>
          <select class="w-100 form-control" id="multiselect-campo-adicional" v-model="campo.contentCampo.valor">
            <option
              v-for="detalheCampoAdicional in retornaDetalhesCampoAdicional(campo.idCampo.valor)"
              :key="detalheCampoAdicional.id"
              :value="detalheCampoAdicional.id"
            >
              {{ detalheCampoAdicional.descricao }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between">
      <div class="vsm--item">
        <a
          tabindex="0"
          role="link"
          target="_blank"
          @click="acessarCadastroCamposAdicionais()"
          class="vsm--link vsm--link_level-1"
        >
          <span class="vsm--title">Cadastrar Campos Adicionais</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, onUpdated, onMounted, ref } from 'vue';
  import { vxm } from '@/store/index';
  import { uiAction } from '@/store/uiStore';
  import erpClient from '../../../../core/http/erpClient';

  export default defineComponent({
    name: 'SecaoCamposAdicionais',
    props: {
      idSecaoMenu: {
        type: String,
        default: '',
        required: true,
      },
    },
    setup() {
      const isModalOpen = ref(false);
      const retornaNomeCampoAdicional = (idCampo) => {
        var campoAdicional = vxm.cadastro.dadosIniciaisParaCadastro.camposAdicionais.filter((i) => i.id == idCampo);
        return campoAdicional && campoAdicional[0] ? campoAdicional[0].nomeCampo : '';
      };
      const urlBase = (id) => {
        let urlBase = vxm.listagem.dadosIniciaisListagem.UrlBaseErp;
        return urlBase;
      };

      const abrirCadastro = (url, titulo) => {
        uiAction.loading();

        erpClient.openNewTab(url);
      };

      const acessarCadastroCamposAdicionais = () => {
        let titulo = 'Campos Adicionais';
        let url = 'gestor_web/clientes/campos_adicionais_cadastro.asp';
        abrirCadastro(url, titulo);
      };

      const retornaTipoCampoAdicional = (idCampo) => {
        var campoAdicional = vxm.cadastro.dadosIniciaisParaCadastro.camposAdicionais.filter((i) => i.id == idCampo);
        return campoAdicional && campoAdicional[0] ? campoAdicional[0].tipoCampo : 0;
      };

      const retornaDetalhesCampoAdicional = (idCampo) => {
        var campoAdicional = vxm.cadastro.dadosIniciaisParaCadastro.camposAdicionais.filter((i) => i.id == idCampo);
        return campoAdicional[0].detalhes;
      };

      const preencheValorCheckBox = (valorContentCampo) => {
        var retornoValorCheckBox = false;
        if (valorContentCampo == 'S') retornoValorCheckBox = true;

        return retornoValorCheckBox;
      };

      const identificaCliqueCheckBox = (campoAdicional) => {
        var campoAdicionalExitente = false;
        vxm.cadastro.pessoa.camposAdicionaisValores.forEach((c) => {
          var tipoCampo = vxm.cadastro.dadosIniciaisParaCadastro.camposAdicionais.filter(
            (i) => i.id == campoAdicional.idCampo || i.id == campoAdicional.idCampo.valor,
          )[0].tipoCampo;
          if (c.idCampo.valor == campoAdicional.idCampo || c.idCampo.valor == campoAdicional.idCampo.valor) {
            campoAdicionalExitente = true;
            if (c.contentCampo.valor != 'S') c.contentCampo.valor = 'S';
            else {
              c.contentCampo.valor = 'N';
            }
          }
        });
        if (!campoAdicionalExitente) {
          var objCamposAdicionaisAux = {
            id: {
              label: '',
              descricao: 0,
              valor: 0,
              erros: [],
              validacoes: [],
            },
            idCampo: {
              label: '',
              descricao: campoAdicional.idCampo,
              valor: campoAdicional.idCampo,
              erros: [],
              validacoes: [],
            },
            contentCampo: {
              label: '',
              descricao: 'S', //campoAdicional.valorCampo,
              valor: 'S', //campoAdicional.valorCampo,
              erros: [],
              validacoes: [],
            },
            idPessoa: {
              label: '',
              descricao: vxm.cadastro.pessoa.id.valor,
              valor: vxm.cadastro.pessoa.id.valor,
              erros: [],
              validacoes: [],
            },
          };
          vxm.cadastro.pessoa.camposAdicionaisValores.push(objCamposAdicionaisAux);
        }
        return 0;
      };
      onUpdated(() => {
        prencherDadosIniciaisCamposAdicionais();
      });
      onMounted(() => {
        prencherDadosIniciaisCamposAdicionais();
      });
      const prencherDadosIniciaisCamposAdicionais = () => {
        vxm.cadastro.dadosIniciaisParaCadastro.camposAdicionais.forEach((dadosIniciaisCampoAdicional) => {
          if (
            vxm.cadastro.pessoa.camposAdicionaisValores.filter((i) => i.idCampo.valor == dadosIniciaisCampoAdicional.id).length ==
            0
          ) {
            var objCamposAdicionaisAux = {
              id: {
                label: '',
                descricao: 0,
                valor: 0,
                erros: [],
                validacoes: [],
              },
              idCampo: {
                label: '',
                descricao: dadosIniciaisCampoAdicional.id,
                valor: dadosIniciaisCampoAdicional.id,
                erros: [],
                validacoes: [],
              },
              contentCampo: {
                label: '',
                descricao: '', //campoAdicional.valorCampo,
                valor: '', //campoAdicional.valorCampo,
                erros: [],
                validacoes: [],
              },
              idPessoa: {
                label: '',
                descricao: vxm.cadastro.pessoa.id.valor,
                valor: vxm.cadastro.pessoa.id.valor,
                erros: [],
                validacoes: [],
              },
            };
            vxm.cadastro.pessoa.camposAdicionaisValores.push(objCamposAdicionaisAux);
            vxm.cadastro.pessoa.camposAdicionaisValores.sort();
          }
        });
      };

      return {
        ...vxm,
        identificaCliqueCheckBox,
        retornaNomeCampoAdicional,
        retornaTipoCampoAdicional,
        retornaDetalhesCampoAdicional,
        preencheValorCheckBox,
        isModalOpen,
        urlBase,
        acessarCadastroCamposAdicionais,
      };
    },
  });
</script>

<style scoped>
  .margin-my-2 {
    margin: 0.5rem 0 0.5rem 0;
  }
</style>
