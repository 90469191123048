import { createRouter, createWebHistory } from 'vue-router';

import routes from './routes';

import RouterMiddlewares from '@/router/middlewares';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => RouterMiddlewares.authGuardMiddleware(to, from, next));

export default router;
