import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "nav flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("ul", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.secoes, (secao, index) => {
      return _withDirectives((_openBlock(), _createBlock("li", {
        class: "nav-item",
        key: index
      }, [
        _createVNode("a", {
          class: "text-uppercase",
          href: "javascript:void(0);",
          onClick: ($event: any) => (_ctx.scrollar(secao))
        }, _toDisplayString(secao.titulo), 9, ["onClick"])
      ], 512)), [
        [_vShow, secao.visivel]
      ])
    }), 128))
  ]))
}