
  /* eslint-disable */
  import { computed, defineComponent, reactive, toRefs, watch } from 'vue';

  import { vxm } from '@/store/index';

  import Campo from '@/components/shared/Campo.vue';
  import Input from '@/components/shared/Input.vue';
  import Multiselect from '@/components/shared/Multiselect.vue';
  import MultiselectGroup from '@/components/shared/MultiselectGroup.vue';
  import Money from '@/components/shared/Money.vue';
  import DadosBancariosSelecao from '@/components/clientes/cadastro/comercial/dadosBancarios/DadosBancariosSelecao.vue'

  import { TipoCliente } from '@/core/enums/pessoa';
  import PagamentoEnum from '@/core/enums/pagamento';
  import { PlanoPagamento } from '@/core/types/clientes/cadastro';
  import BaseModal from '@/components/shared/BaseModal.vue';
  import ModalAcesso from '@/components/shared/ModalAcesso.vue';
  import AlertsExtension from '@/utils/alerts';
  import FiltrosExtension from '@/utils/filtros';
  import PessoaModel from '@/core/models/pessoa';

  export default defineComponent({
    name: 'SecaoComercialCadastro',
    components: {
      Campo,
      Input,
      Multiselect,
      MultiselectGroup,
      Money,
      BaseModal,
      ModalAcesso,
      DadosBancariosSelecao
    },
    props: {
      idSecaoMenu: {
        type: String,
        default: '',
        required: true,
      },
    },
    data() {
      return {
          opcoesTipoRenovacaoSaldo: [
            {id:1, descricao:"Prazo de Validade Atingido", ativo: true},
            {id:2, descricao:"Início de cada mês", ativo: true},
            {id:3, descricao:"Não renova", ativo: true}
          ],
          opcoesUtilizaCorreiosSedex: [
            {id: 0, descricao: "Não"},
            {id: 1, descricao: "Sim"}
          ]
      }
    },
    setup() {
      const idBloqueioOutrosMotivos = 6;

      const isShowMultiselectPlanosPagamentos = computed(() => {
        return vxm.cadastro.pessoa.tipoCliente.valor !== TipoCliente.transportador && !vxm.cadastro.isNovoCadastroPessoa;
      });

      const methods = reactive({
        btnAbreModalAcesso: () => {
          vxm.cadastro.abrirModalTabelaDePreco();
        },
        btnFecharModalAcesso: () => {
          vxm.cadastro.fecharModalTabelaDePreco();
        },
      });

      const { validarDocumento } = PessoaModel;

      const multiselectPlanosPagamentos = computed((): Array<PlanoPagamento> => {
        const planosPagamentos = vxm.cadastro.dadosIniciaisParaCadastro.planosPagamentos;

        if (vxm.cadastro.pessoa.tipoCliente.valor === TipoCliente.cliente)
          return planosPagamentos.filter(({ tipoPlano }) => tipoPlano === 'V');

        if (vxm.cadastro.pessoa.tipoCliente.valor === TipoCliente.fornecedor)
          return planosPagamentos.filter(({ tipoPlano }) => tipoPlano === 'C');

        if (vxm.cadastro.pessoa.tipoCliente.valor === TipoCliente.ambos) return planosPagamentos;

        return [] as Array<PlanoPagamento>;
      });

      type Group = {
        label: string;
        options: any;
      };

      const multiselectPlanosPagamentosAgrupado = computed((): Array<Group> => {
        const { planosPagamentos } = vxm.cadastro.dadosIniciaisParaCadastro;
        const { valor: tipoCliente } = vxm.cadastro.pessoa.tipoCliente;
        const planosPagamentoFiltradoPorTipoCliente = planosPagamentos.filter(({ tipoPlano }) => {
          if (tipoCliente === TipoCliente.cliente) return tipoPlano === 'V';
          if (tipoCliente === TipoCliente.fornecedor) return tipoPlano === 'C';
          return true;
        });
        const formasDePagamento = [...new Set(planosPagamentoFiltradoPorTipoCliente.map(({ formaPgto }) => formaPgto))];
        formasDePagamento.sort();
        return formasDePagamento.map((forma) => {
          const planosDePagamentoAgrupadoPorForma = {
            label: PagamentoEnum.getDescricaoFormaPagamento(parseInt(forma)),
            options: planosPagamentoFiltradoPorTipoCliente
              .filter(({ formaPgto }) => formaPgto == forma)
              .map(({ descricao, id }) => ({
                label: descricao,
                value: id,
              })),
          } as Group;
          return planosDePagamentoAgrupadoPorForma;
        });
      });

      watch(
        () => multiselectPlanosPagamentosAgrupado.value,
        () => {
          const { valor: planosPagamentosSelecionados } = vxm.cadastro.pessoa.comercial.planosPagamentos;
          vxm.cadastro.pessoa.comercial.planosPagamentos.valor = planosPagamentosSelecionados.filter(
            (idPlanoPagamentoSelecionado) =>
              multiselectPlanosPagamentosAgrupado.value.some(({ options }: Group) =>
                options.some(({ value }: any) => value === idPlanoPagamentoSelecionado),
              ),
          );
        },
      );

      const isFornecedor = computed(() => {
        return vxm.cadastro.pessoa.tipoCliente.valor === TipoCliente.fornecedor;
      });

      const isCliente = computed(() => {
        return vxm.cadastro.pessoa.tipoCliente.valor === TipoCliente.cliente;
      });

      const isAmbos = computed(() => {
        return vxm.cadastro.pessoa.tipoCliente.valor === TipoCliente.ambos;
      });

      const isTransportador = computed(() => {
        return vxm.cadastro.pessoa.tipoCliente.valor === TipoCliente.transportador;
      });

      const isRamoOptico = computed(() => {
        return vxm.listagem.dadosIniciaisListagem.IdRamoPortal == 3;
      });

      const isBloquearAlterarPlanosPagamentos = computed((): boolean => {
        return !vxm.parametros.permissoesUsuario.alterarPlanosPagamentoCliente;
      });

      const isShowInputMotivoBloqueio = computed((): boolean => {
        return (
          vxm.cadastro.pessoa.comercial.idMotivoBloqueioFaturamento.valor !== 0 &&
          !vxm.cadastro.pessoa.comercial.isFaturamentoLiberado.valor
        );
      });

      const isShowCheckboxDestacaIcmsProprio = computed(
        (): boolean => vxm.parametros.parametrosCrm.isEmpresaOptanteSimplesEstadual,
      );

      const atualizaIdMotivoBloqueio = (isFaturamentoLiberado: any) => {
        if (isFaturamentoLiberado.target.checked) {
          vxm.cadastro.pessoa.comercial.idMotivoBloqueioFaturamento.valor = 0;
          atualizaDescricaoMotivoBloqueio(0);
        }
      };

      const atualizaDescricaoMotivoBloqueio = (idMotivoBloqueio: any) => {
        const motivoBloqueio = vxm.cadastro.dadosIniciaisParaCadastro.motivosBloqueios.find(({ id }) => id === idMotivoBloqueio);
        if (motivoBloqueio) vxm.cadastro.pessoa.comercial.idMotivoBloqueioFaturamento.descricao = motivoBloqueio.descricao;
      };

      const isPermitirDesbloqueioFaturamento = computed((): boolean => {
        if (vxm.cadastro.isNovoCadastroPessoa)
          if (!vxm.parametros.parametrosCrm.FaturamentoLiberado)
            vxm.cadastro.pessoa.comercial.isFaturamentoLiberado.valor = false;
          else vxm.cadastro.pessoa.comercial.isFaturamentoLiberado.valor = true;
        if (!vxm.cadastro.isNovoCadastroPessoa && !vxm.cadastro.pessoa.comercial.isFaturamentoLiberado.valor)
          return vxm.parametros.permissoesUsuario.desbloqueioClienteFaturamento;

        return true;
      });

      const formatarDocumento = (documento: string) => {
        return FiltrosExtension.inserirMascaraDocumento(documento);
      };

      const validarDocumentoCnpjVinculado = () => {
        const doc = vxm.cadastro.pessoa.comercial.cnpjVinculado;
        const documentoValido = validarDocumento(doc);
        const documentoDisponivel = !vxm.cadastro.pessoa.comercial.listaCnpjsVinculados.includes(doc.valor);

        if (!documentoDisponivel) AlertsExtension.exibirMensagemErro('Documento já está inserido na lista.', true);

        return documentoValido && documentoDisponivel;
      };

      const adicionarCnpjVinculado = () => {
        if (!validarDocumentoCnpjVinculado()) return;

        vxm.cadastro.pessoa.comercial.listaCnpjsVinculados = [
          ...vxm.cadastro.pessoa.comercial.listaCnpjsVinculados,
          vxm.cadastro.pessoa.comercial.cnpjVinculado.valor,
        ];
        vxm.cadastro.pessoa.comercial.cnpjVinculado.valor = '';
        AlertsExtension.exibirMensagemAlerta('A inserção será confirmada ao salvar a ficha.', true);
      };

      const removerCnpjVinculado = (indice: number) => {
        vxm.cadastro.pessoa.comercial.listaCnpjsVinculados = vxm.cadastro.pessoa.comercial.listaCnpjsVinculados.filter(
          (doc, i) => i != indice,
        );
        AlertsExtension.exibirMensagemAlerta('A exclusão será confirmada ao salvar a ficha.', true);
      };

      return {
        ...toRefs(methods),
        ...vxm,
        isShowMultiselectPlanosPagamentos,
        multiselectPlanosPagamentos,
        multiselectPlanosPagamentosAgrupado,
        atualizaIdMotivoBloqueio,
        atualizaDescricaoMotivoBloqueio,
        idBloqueioOutrosMotivos,
        isShowInputMotivoBloqueio,
        isPermitirDesbloqueioFaturamento,
        isBloquearAlterarPlanosPagamentos,
        isCliente,
        isFornecedor,
        isAmbos,
        isTransportador,
        isRamoOptico,
        isShowCheckboxDestacaIcmsProprio,
        validarDocumentoCnpjVinculado,
        adicionarCnpjVinculado,
        removerCnpjVinculado,
        formatarDocumento,
      };
    },
  });
