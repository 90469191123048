import { RouteRecordRaw } from 'vue-router';

import Autenticacao from '@/views/Autenticacao.vue';

import Clientes from '@/views/Clientes.vue';
import ListagemClientes from '@/views/clientes/Listagem.vue';
import CadastroClientes from '@/views/clientes/Cadastro.vue';
import ImportacoesClientes from '@/views/clientes/PainelImportacoes.vue';
import AvisoScarf from '@/views/clientes/AvisoScarf.vue';
import { idSecao } from '@/core/types/menu';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Autenticacao',
    component: Autenticacao,
  },
  {
    path: '/clientes',
    component: Clientes,
    children: [
      {
        path: 'listagem',
        name: 'ListagemClientes',
        component: ListagemClientes,
      },
      {
        path: 'cadastro',
        name: 'CadastroClientes',
        component: CadastroClientes,
        props: true,
      },
      {
        path: 'importacao',
        name: 'ImportacaoClientes',
        component: ImportacoesClientes,
      },
      {
        path: 'scarf',
        name: 'AvisoScarf',
        component: AvisoScarf,
      },
      {
        path: 'cadastro-dados-bancarios/:idCliente?',
        name: 'CadastroDadosBancarios',
        component: CadastroClientes,
        meta: {
          secaoCadastroParcial: idSecao.dadosBancarios,
        },
        props: true,
      },
    ],
  },
];

export default routes;
