import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")
  const _component_Campo = _resolveComponent("Campo")

  return (_openBlock(), _createBlock(_component_Campo, {
    modelValue: _ctx.dadoBancarioSelecionado.chavePix,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.dadoBancarioSelecionado.chavePix = $event)),
    label: "Chave PIX",
    id: "campo-comercial-dados-bancarios-chave-pix",
    class: "w-100",
    isRequired: 
      !!_ctx.dadoBancarioSelecionado.idTipoChavePix.valor &&
      _ctx.dadoBancarioSelecionado.idTipoChavePix.valor !== _ctx.TipoChavePix.ContaBancaria
    
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Input, {
        id: "input-comercial-dados-bancarios-chave-pix",
        modelValue: _ctx.dadoBancarioSelecionado.chavePix.valor,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.dadoBancarioSelecionado.chavePix.valor = $event)),
        tamanho: "md",
        "max-length": _ctx.objetoChavePix.maxLength,
        campo: _ctx.objetoChavePix.campo,
        placeholder: _ctx.objetoChavePix.placeholder,
        disabled: _ctx.objetoChavePix.disabled,
        type: _ctx.objetoChavePix.tipo
      }, null, 8, ["modelValue", "max-length", "campo", "placeholder", "disabled", "type"])
    ]),
    _: 1
  }, 8, ["modelValue", "isRequired"]))
}