import ErrosHandler from '@/core/validations/handler';
import { TipoValidacao } from '../types/campos';
import { ContatoAdicional } from '../types/clientes/pessoa/contato';

const { validarEntidade } = ErrosHandler;

class ContatoAdicionalModel {
  public static validarContatoAdicional = (contatoAdicional: ContatoAdicional): boolean => {
    contatoAdicional.nome.validacoes = [TipoValidacao.required];
    contatoAdicional.telefone.validacoes = [TipoValidacao.telefone];
    contatoAdicional.celular.validacoes = [TipoValidacao.celular];
    contatoAdicional.email.validacoes = [TipoValidacao.email];

    const isContatoAdicionalValido = validarEntidade(contatoAdicional);

    return isContatoAdicionalValido;
  };
}

export default ContatoAdicionalModel;
