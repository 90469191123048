import { ProdutosApi } from '@/core/types/autenticacao';
import { ProdutoResult } from '../contracts/erpAdmin';

const STORAGEKEY_TOKEN = 'token_api';
const STORAGEKEY_TOKEN_ANONIMO = 'token_api_anonimo';

const STORAGEKEY_URL_API_ES = 'url_es_api';
const STORAGEKEY_URL_ERP = 'url_base_erp';
const STORAGEKEY_URL_API_NFE = 'url_nfe_api';
const STORAGEKEY_URL_API_CRM = 'url_crm_api';
const STORAGEKEY_URL_API_FISCAL = 'url_fiscal_api';
const STORAGEKEY_URL_API_FATURAMENTO = 'url_faturamento_api';
const STORAGEKEY_URL_API_FINANCEIRO = 'url_financeiro_api';
const STORAGEKEY_URL_API_LIS = 'url_lis_api';
const STORAGEKEY_URL_API_SUPRIMENTOS = 'url_suprimentos_api';
const STORAGEKEY_URL_API_IMAGENS = 'url_produtos_imagens_api';
const STORAGEKEY_URL_CONNECT_HUB = 'url_connecthub_api';

const urlEsApiDev = process.env.VUE_APP_URL_ESAPI;
const urlErpDev = process.env.VUE_APP_URL_ERP;
const urlNFeApiDev = process.env.VUE_APP_URL_NFEAPI;
const urlCrmApiDev = process.env.VUE_APP_URL_CRMAPI;
const urlFiscalApiDev = process.env.VUE_APP_URL_FISCALAPI;
const urlFaturamentoApiDev = process.env.VUE_APP_URL_FATURAMENTOAPI;
const urlFinanceiroApiDev = process.env.VUE_APP_URL_FINANCEIROAPI;
const urlLisApiDev = process.env.VUE_APP_URL_LISAPI;
const urlSuprimentosApiDev = process.env.VUE_APP_URL_SUPRIMENTOSAPI;
const urlImagensProdutoApiDev = process.env.VUE_APP_URL_IMAGENSAPI;
const urlConnectHub = process.env.VUE_APP_URL_CONNECT_HUB;

class ApiConfig {
  public static getTokenJWT = (): string => localStorage.getItem(STORAGEKEY_TOKEN) || '';
  public static getTokenAnonimo = (): string => localStorage.getItem(STORAGEKEY_TOKEN_ANONIMO) || '';

  public static setTokenJWT = (val: string): void => {
    if (!val) localStorage.removeItem(STORAGEKEY_TOKEN);
    else localStorage.setItem(STORAGEKEY_TOKEN, val);
  };
  public static setTokenAnonimo = (val: string): void => {
    if (!val) localStorage.removeItem(STORAGEKEY_TOKEN_ANONIMO);
    else localStorage.setItem(STORAGEKEY_TOKEN_ANONIMO, val);
  };

  public static getUrlBaseErp = (): string => urlErpDev || localStorage.getItem(STORAGEKEY_URL_ERP) || '';
  public static getUrlEsApi = (): string => urlEsApiDev || localStorage.getItem(STORAGEKEY_URL_API_ES) || '';
  public static getUrlNFeApi = (): string => urlNFeApiDev || localStorage.getItem(STORAGEKEY_URL_API_NFE) || '';
  public static getUrlCrmApi = (): string => urlCrmApiDev || localStorage.getItem(STORAGEKEY_URL_API_CRM) || '';
  public static getUrlLisApi = (): string => urlLisApiDev || localStorage.getItem(STORAGEKEY_URL_API_LIS) || '';
  public static getUrlFiscalApi = (): string => urlFiscalApiDev || localStorage.getItem(STORAGEKEY_URL_API_FISCAL) || '';
  public static getUrlFaturamentoApi = (): string => urlFaturamentoApiDev || localStorage.getItem(STORAGEKEY_URL_API_FATURAMENTO) || '';
  public static getUrlFinanceiroApi = (): string => urlFinanceiroApiDev || localStorage.getItem(STORAGEKEY_URL_API_FINANCEIRO) || '';
  public static getUrlSuprimentosApi = (): string => urlSuprimentosApiDev || localStorage.getItem(STORAGEKEY_URL_API_SUPRIMENTOS) || '';
  public static getUrlErpAdminApi = (): string => process.env.VUE_APP_URL_ERPADMIN || '';
  public static getUrlImagensProdutoApi = (): string => urlImagensProdutoApiDev || localStorage.getItem(STORAGEKEY_URL_API_IMAGENS) || '';
  public static getUrlConnectHub = (): string => urlConnectHub || localStorage.getItem(STORAGEKEY_URL_CONNECT_HUB) || '';

  public static setUrlEsApi = (val: string): void => {
    if (!val) localStorage.removeItem(STORAGEKEY_URL_API_ES);
    else localStorage.setItem(STORAGEKEY_URL_API_ES, val);
  };
  public static setUrlErp = (val: string): void => {
    if (!val) localStorage.removeItem(STORAGEKEY_URL_ERP);
    else localStorage.setItem(STORAGEKEY_URL_ERP, val);
  };
  public static setUrlNFeApi = (val: string): void => {
    if (!val) localStorage.removeItem(STORAGEKEY_URL_API_NFE);
    else localStorage.setItem(STORAGEKEY_URL_API_NFE, val);
  };
  public static setUrlCrmApi = (val: string): void => {
    if (!val) localStorage.removeItem(STORAGEKEY_URL_API_CRM);
    else localStorage.setItem(STORAGEKEY_URL_API_CRM, val);
  };
  public static setUrlLisApi = (val: string): void => {
    if (!val) localStorage.removeItem(STORAGEKEY_URL_API_LIS);
    else localStorage.setItem(STORAGEKEY_URL_API_LIS, val);
  };
  public static setUrlFiscalApi = (val: string): void => {
    if (!val) localStorage.removeItem(STORAGEKEY_URL_API_FISCAL);
    else localStorage.setItem(STORAGEKEY_URL_API_FISCAL, val);
  };
  public static setUrlFaturamentoApi = (val: string): void => {
    if (!val) localStorage.removeItem(STORAGEKEY_URL_API_FATURAMENTO);
    else localStorage.setItem(STORAGEKEY_URL_API_FATURAMENTO, val);
  };
  public static setUrlFinanceiroApi = (val: string): void => {
    if (!val) localStorage.removeItem(STORAGEKEY_URL_API_FINANCEIRO);
    else localStorage.setItem(STORAGEKEY_URL_API_FINANCEIRO, val);
  };
  public static setUrlSuprimentosApi = (val: string): void => {
    if (!val) localStorage.removeItem(STORAGEKEY_URL_API_SUPRIMENTOS);
    else localStorage.setItem(STORAGEKEY_URL_API_SUPRIMENTOS, val);
  };
  public static setUrlImagensProdutosApi = (val: string): void => {
    if (!val) localStorage.removeItem(STORAGEKEY_URL_API_IMAGENS);
    else localStorage.setItem(STORAGEKEY_URL_API_IMAGENS, val);
  };
  public static setUrlConnectHubApi = (val: string): void => {
    if(!val) localStorage.removeItem(STORAGEKEY_URL_CONNECT_HUB);
    else localStorage.setItem(STORAGEKEY_URL_CONNECT_HUB, val);
  }

  public static setBaseUrls = (produtosResult: Array<ProdutoResult>) => {
    ApiConfig.setUrlErp(produtosResult.filter((x) => x.Produto === <string>ProdutosApi.MicrovixERP)[0]?.Url);
    ApiConfig.setUrlEsApi(produtosResult.filter((x) => x.Produto === <string>ProdutosApi.ES)[0].Url);
    ApiConfig.setUrlNFeApi(produtosResult.filter((x) => x.Produto === <string>ProdutosApi.NFE)[0].Url);
    ApiConfig.setUrlCrmApi(produtosResult.filter((x) => x.Produto === <string>ProdutosApi.CRM)[0].Url);
    ApiConfig.setUrlFiscalApi(produtosResult.filter((x) => x.Produto === <string>ProdutosApi.Fiscal)[0].Url);
    ApiConfig.setUrlFaturamentoApi(produtosResult.filter((x) => x.Produto === <string>ProdutosApi.Faturamento)[0].Url);
    ApiConfig.setUrlFinanceiroApi(produtosResult.filter((x) => x.Produto === <string>ProdutosApi.AdmFinanceiro)[0].Url);
    ApiConfig.setUrlLisApi(produtosResult.filter((x) => x.Produto === <string>ProdutosApi.LIS)[0].Url);
    ApiConfig.setUrlSuprimentosApi(produtosResult.filter((x) => x.Produto === <string>ProdutosApi.Suprimentos)[0].Url);
    ApiConfig.setUrlImagensProdutosApi(produtosResult.filter((x) => x.Produto === <string>ProdutosApi.ProdutosImagens)[0].Url);
    ApiConfig.setUrlConnectHubApi(produtosResult.filter((x) => x.Produto === <string>ProdutosApi.ConnectHub)[0]?.Url);
  };

  public static reset = (): void => {
    localStorage.removeItem(STORAGEKEY_TOKEN);
    localStorage.removeItem(STORAGEKEY_URL_API_ES);
    localStorage.removeItem(STORAGEKEY_URL_API_NFE);
    localStorage.removeItem(STORAGEKEY_URL_API_CRM);
    localStorage.removeItem(STORAGEKEY_URL_API_FISCAL);
    localStorage.removeItem(STORAGEKEY_URL_API_FATURAMENTO);
    localStorage.removeItem(STORAGEKEY_URL_API_FINANCEIRO);
    localStorage.removeItem(STORAGEKEY_URL_API_LIS);
    localStorage.removeItem(STORAGEKEY_URL_API_SUPRIMENTOS);
    localStorage.removeItem(STORAGEKEY_URL_API_IMAGENS);
    localStorage.removeItem(STORAGEKEY_URL_CONNECT_HUB);
  };
}

export default ApiConfig;
