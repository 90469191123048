<template>
  <div id="component" class="m-auto h-100 w-100" style="margin-top: 17px" v-if="!cadastro.cameraAberta">
    <file-pond
      name="test"
      ref="pond"
      allow-multiple="false"
      credits="false"
      v-bind:server="myServer"
      :beforeAddFile="beforeAddFile"
      :files="listaArquivos"
      :label-idle="labelPrincipal"
      :accepted-file-types="tiposDeArquivoAceitos"
      :max-files="maxArquivos"
      :max-file-size="maxTamanhoArquivo"
      :max-total-file-size="maxTamanhoTodosArquivos"
      style-panel-layout="compact circle"
      style-button-remove-item-position="center top"
      :label-invalid-field="Labels.labelInvalidField"
      :label-file-waiting-for-size="Labels.labelFileWaitingForSize"
      :label-file-size-not-available="Labels.labelFileSizeNotAvailable"
      :label-file-loading="Labels.labelFileLoading"
      :label-file-load-error="Labels.labelFileLoadError"
      :label-file-processing="Labels.labelFileProcessing"
      :label-file-processing-complete="Labels.labelFileProcessingComplete"
      :label-file-processing-aborted="Labels.labelFileProcessingAborted"
      :label-file-processing-error="Labels.labelFileProcessingError"
      :label-file-processing-revert-error="Labels.labelFileProcessingRevertError"
      :label-file-remove-error="Labels.labelFileRemoveError"
      :label-tap-to-cancel="Labels.labelTapToCancel"
      :label-tap-to-retry="Labels.labelTapToRetry"
      :label-tap-to-undo="Labels.labelTapToUndo"
      :label-button-remove-item="Labels.labelButtonRemoveItem"
      :label-button-abort-item-load="Labels.labelButtonAbortItemLoad"
      :label-button-retry-item-load="Labels.labelButtonRetryItemLoad"
      :label-button-abort-item-processing="Labels.labelButtonAbortItemProcessing"
      :label-button-undo-item-processing="Labels.labelButtonUndoItemProcessing"
      :label-button-retry-item-processing="Labels.labelButtonRetryItemProcessing"
      :label-button-process-item="Labels.labelButtonProcessItem"
      :label-file-type-not-allowed="Labels.labelFileTypeNotAllowed"
      :file-validate-type-label-expected-types="Labels.fileValidateTypeLabelExpectedTypes"
      :file-validate-type-label-expected-types-map="Labels.fileValidateTypeLabelExpectedTypesMap"
      :label-max-file-size-exceeded="Labels.labelMaxFileSizeExceeded"
      :label-max-file-size="Labels.labelMaxFileSize"
      :label-max-total-file-size-exceeded="Labels.labelMaxTotalFileSizeExceeded"
      :label-max-total-file-size="Labels.labelMaxTotalFileSize"
    />
  </div>
</template>

<script>
  /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
  import { defineComponent, computed, toRefs, reactive, watchEffect, onMounted, ref } from 'vue';
  import { vxm } from '@/store/index';
  import AlertsExtension from '@/utils/alerts';

  import vueFilePond from 'vue-filepond';

  import 'filepond/dist/filepond.min.css';

  import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
  import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
  import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

  const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
  export default defineComponent({
    name: 'UploadImagem',
    components: { FilePond },
    props: {
      maxArquivos: { type: Number, default: 1 },
      maxTamanhoArquivo: { type: String, default: '2MB' },
      maxTamanhoTodosArquivos: { type: String, default: null },
      tiposDeArquivoAceitos: {
        type: Array,
        default: function () {
          return ['image/jpeg', 'image/jpg', 'image/png'];
        },
      },
    },
    setup(props) {
      const pond = ref(null);
      const data = reactive({
        Labels: {
          labelInvalidField: 'Arquivos inv\u00e1lidos!',
          labelFileWaitingForSize: ' ',
          labelFileSizeNotAvailable: ' ',
          labelFileLoading: ' ',
          labelFileLoadError: ' ',
          labelFileProcessing: ' ',
          labelFileProcessingComplete: ' ',
          labelFileProcessingAborted: ' ',
          labelFileProcessingError: ' ',
          labelFileProcessingRevertError: ' ',
          labelFileRemoveError: ' ',
          labelTapToCancel: ' ',
          labelTapToRetry: ' ',
          labelTapToUndo: ' ',
          labelButtonRemoveItem: ' ',
          labelButtonAbortItemLoad: ' ',
          labelButtonRetryItemLoad: ' ',
          labelButtonAbortItemProcessing: ' ',
          labelButtonUndoItemProcessing: ' ',
          labelButtonRetryItemProcessing: ' ',
          labelButtonProcessItem: ' ',
          labelFileTypeNotAllowed: ' ',
          fileValidateTypeLabelExpectedTypes: ' ',
          fileValidateTypeLabelExpectedTypesMap: { 'image/jpeg': '.jpg', 'image/png': '.png' },
          labelMaxFileSizeExceeded: ' ',
          labelMaxFileSize: ' ',
          labelMaxTotalFileSizeExceeded: ' ',
          labelMaxTotalFileSize: ' ',
        },
        cameraAberta: false,
        exibir: true,
        myServer: {
          url: localStorage.getItem('url_crm_api') + '/api/Documento/ObterFotoPessoa/',
          headers: { Authorization: localStorage.getItem('token_api') },
          process: function (fieldName, file, metadata, load, error, progress, abort, transfer, options) {
            progress(true, 0, 1024);
            load(file);
            vxm.cadastro.fotoNovaParaInserir = file;
            return {
              abort: () => {
                abort();
              },
            };
          },
          revert: function (load) {
            vxm.cadastro.fotoCadastro = [];
            if (vxm.cadastro.possuiFoto) vxm.cadastro.exlcuirFotoCadastro = true;
            load();
          },

          load: '',
        },
      });
      onMounted(() => { 
        pond.value.removeFile(); 
      });
      const labelPrincipal = computed(() => {
        var labelProdPrincipal =
          '<p style="font-size:0.8rem;margin:0.4rem 0 0 0"><span class="filepond--label-action">Clique ou arraste para adicionar foto.</p>';

        var labelFormatos =
          '<p style="font-size:0.75rem;">Formatos: ' +
          props.tiposDeArquivoAceitos.join(', ').replace(/image\//g, '.') +
          ' - Tamanho m\u00e1ximo: ' +
          props.maxTamanhoArquivo +
          '</p>';
        return labelProdPrincipal + labelFormatos;
      });
      const listaArquivos = computed(() => {
        return vxm.cadastro.fotoCadastro;
      });
      const exibirMensagemExtensaoInvalida = () => {
        const extensoes = props.tiposDeArquivoAceitos
          .map((tipo) => tipo.split('/').pop())
          .join(', ');

        AlertsExtension.exibirMensagemErro(
          `Extensão de arquivo não permitida. Por favor, envie uma imagem válida (${extensoes}).!`,
        );
      };
      watchEffect(() => {
        let idPessoa = Number(vxm.cadastro.pessoa.id.valor);
        if (!vxm.cadastro.isNovoCadastroPessoa) methods.obterImagem(idPessoa);
      });

      const methods = reactive({
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        handleFilePondInit() {},

        beforeAddFile: function (item) {
          return new Promise((resolve) => {
            const extensao = item.fileExtension.toLowerCase();
            const extensoesPermitidas = props.tiposDeArquivoAceitos.map((tipo) =>
              tipo.split('/').pop()
            );

            if (!extensoesPermitidas.includes(extensao)) {
              exibirMensagemExtensaoInvalida();
              setTimeout(() => {
                pond.value.removeFile();
              }, 0);
            } else {
              resolve(item);
            }
          });
        },

        obterImagem(idPessoa) {
          vxm.cadastro
            .obterFotoPessoa(idPessoa)
            .then(this.popularDados)
            .catch(function (err) {
              console.log(err);
            });
        },
        popularDados: function (retorno) {
          if (retorno.size > 0) {
            var file = null;
            let id = vxm.cadastro.pessoa.id.valor;
            var blob = new Blob([retorno], { type: 'image/png' });
            file = new File([blob], id, { type: 'image/png' });
            vxm.cadastro.fotoCadastro = [
              {
                source: id,
                options: {
                  type: 'limbo',
                  file: file,
                },
              },
            ];
            vxm.cadastro.possuiFoto = true;
            return Promise.resolve();
          }
        },
      });

      return { ...vxm, ...toRefs(data), labelPrincipal, listaArquivos, pond, ...methods };
    },
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .filepond--item {
    height: 208px !important;
  }
  #__unset__ {
    height: 208px !important;
  }
  [data-filepond-item-state='processing-complete'] .filepond--item-panel {
    background-color: blue !important;
  }
</style>
