
  import { defineComponent, reactive, toRefs, ref, computed, onMounted, inject } from 'vue';
  import { vxm } from '@/store/index';
  import FiltroSelecao from './PesquisaFiltroSelecao.vue';
  import FiltroBooleano from './PesquisaFiltroBooleano.vue';
  import FiltroIntevalo from './PesquisaFiltroIntervalo.vue';
  import FiltroCampo from './PesquisaFiltroCampo.vue';
  import FiltroCamposAdicionais from './PesquisaFiltroCamposAdicionais.vue';
  import AlertsExtension from '@/utils/alerts';

  export default defineComponent({
    components: { FiltroBooleano, FiltroSelecao, FiltroIntevalo, FiltroCampo, FiltroCamposAdicionais },
    setup() {
      const filtros: any[] = [];

      const data = reactive({
        show: false,
        filtros: filtros,
        TiposFiltroPesquisa: {
          Campo: 0,
          SelecaoUnica: 1,
          SelecaoMultipla: 2,
          Intervalo: 3,
          Booleano: 4,
          SelecaoCamposAdicionais: 5,
        },
      });

      onMounted(() => {
        registrarEventos();
        methods.buscarFiltrosDisponiveis();
      });
      const emitter: any = inject('emitter');
      const filtrosDisponiveis = computed(() => {
        return data.filtros.filter(function (filtro) {
          return !filtro.Selecionado;
        });
      });

      const filtrosSelecionados = computed(() => {
        return data.filtros.filter(function (filtro) {
          return filtro.Selecionado;
        });
      });
      const filtrosSelecaoUnica = computed(() => {
        return filtrosSelecionados.value.filter(function (filtro: any) {
          return filtro.TipoCampo == data.TiposFiltroPesquisa.SelecaoUnica;
        });
      });
      const filtrosSelecaoMultipla = computed(() => {
        return filtrosSelecionados.value.filter(function (filtro: any) {
          return filtro.TipoCampo == data.TiposFiltroPesquisa.SelecaoMultipla;
        });
      });
      const filtrosIntervalo = computed(() => {
        return filtrosSelecionados.value.filter(function (filtro: any) {
          return filtro.TipoCampo == data.TiposFiltroPesquisa.Intervalo;
        });
      });
      const filtrosBooleanos = computed(() => {
        return filtrosSelecionados.value.filter(function (filtro: any) {
          return filtro.TipoCampo == data.TiposFiltroPesquisa.Booleano;
        });
      });
      const filtrosCampo = computed(() => {
        return filtrosSelecionados.value.filter(function (filtro: any) {
          return filtro.TipoCampo == data.TiposFiltroPesquisa.Campo;
        });
      });
      const filtroSelecaoCamposAdicionais = computed(() => {
        return filtrosSelecionados.value.filter(function (filtro: any) {
          return filtro.TipoCampo == data.TiposFiltroPesquisa.SelecaoCamposAdicionais;
        });
      });
      const methods = reactive({
        buscarFiltrosDisponiveis: () => {
          vxm.loading.showLoading();
          vxm.listagem
            .listarFiltrosPesquisa()
            .then((filtrosPesquisa) => {
              if (filtrosPesquisa) {
                vxm.listagem.filtrosPesquisaAvancada = filtrosPesquisa;
                data.filtros = vxm.listagem.filtrosPesquisaAvancada;
              }
            })
            .catch((error) => {
              AlertsExtension.exibirMensagemErro(error, false);
            })
            .finally(() => {
              vxm.loading.hideLoading();
            });
        },
        incluirFiltro: (idFiltro: any) => {
          let filtroEncontrado = data.filtros.find((filtro) => {
            return filtro.Id == idFiltro;
          });
          if (filtroEncontrado) {
            filtroEncontrado.Selecionado = true;
          }
          data.show = false;
        },
        removerFiltro: (idFiltro: any) => {
          var filtroEncontrado = data.filtros.find((filtro) => {
            return filtro.Id == idFiltro;
          });
          if (filtroEncontrado) {
            filtroEncontrado.Selecionado = false;
          }
        },
        limparFiltros: () => {
          emitter.emit('limpar-todos-filtros-selecionados');
          data.filtros.forEach(function (filtro) {
            filtro.Selecionado = false;
          });
        },
      });

      const registrarEventos = (): void => {
        emitter.on('opcao-filtro-selecionada', atualizarCommandDTOPesquisa);
        emitter.on('remover-filtro-selecionado', methods.removerFiltro);
      };
      const atualizarCommandDTOPesquisa = (filtroDTO: any): void => {
        emitter.emit('command-pesquisa-atualizado', filtroDTO);
      };

      return {
        ...vxm,
        ...toRefs(data),
        ...toRefs(methods),
        filtrosDisponiveis,
        filtrosSelecionados,
        filtrosSelecaoUnica,
        filtrosIntervalo,
        filtrosSelecaoMultipla,
        filtrosBooleanos,
        filtrosCampo,
        filtroSelecaoCamposAdicionais,
      };
    },
  });
