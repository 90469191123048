//MODAJOI-37214 (US 001)
import { createModule, mutation, action } from 'vuex-class-component';

import EsClient from '@/api/clients/es';
import ParametrosCommerceMapping from '@/core/mappings/parametrosCommerce';
import ParametrosCommerceTypes from '@/core/types/es/parametrosCommerce';

const { obterParametrosCommerceMapping } = ParametrosCommerceMapping;

const VuexModule = createModule({
  namespaced: 'linxIO',
  strict: false,
});
export default class ParametrosCommerceModule extends VuexModule {
  linxIO = false as boolean | false;

  @action
  async obterLinxIO(): Promise<boolean> {
    return EsClient.obterParametrosCommerce().then((parametrosCommerceResult) => {
      return parametrosCommerceResult;
    });
  }
}
