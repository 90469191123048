
  import { defineComponent, reactive, toRefs, computed, inject, ref, watch } from 'vue';
  import { vxm } from '@/store/index';
  import SideBarCliente from './SideBarCliente.vue';
  import FiltrosExtension from '@/utils/filtros';
  import Utils from '@/utils/utils';
  import ValidacoesExtension from '@/core/validations/validacoes';
  import ModalConfiguracoes from './ModalConfiguracoes.vue';
  import Badge from '@/components/shared/Badge.vue'

  export default defineComponent({
    name: 'TabelaListagem',
    components: {
      SideBarCliente, ModalConfiguracoes,
      Badge,
    },
    props: {
      listaDeClientes: Array,
      registrosPorPagina: Number,
      controleTela: Object,
    },

    setup(props) {
      const isModalOpen = ref(false);
      const data = reactive({
        controlePaginacao: false,
        ordenacao: {
          campo: '',
          tipo: 1,
          enum: { Asc: 1, Desc: 0 },
        },
        colunasExibicao: [
          {
            NomeCampo: 'Id',
            Label: 'Código',
            Tipo: 'int',
          },
          {
            NomeCampo: 'NomeRazaoSocial',
            Label: 'Nome',
            Tipo: 'string',
          },
          {
            NomeCampo: 'Documento',
            Label: 'Documento',
            Tipo: 'string',
          },
          {
            NomeCampo: 'InscricaoEstadual',
            Label: 'Inscrição Estadual',
            Tipo: 'string',
          },
          {
            NomeCampo: 'TipoCliente',
            Label: 'Tipo',
            Tipo: 'string',
          },
          {
            NomeCampo: 'Telefone',
            Label: 'Telefone',
            Tipo: 'string',
          },

          {
            NomeCampo: 'Email',
            Label: 'E-mail',
            Tipo: 'string',
          },
        ],

        clienteSelecionado: ref({}),
        paginacao: {
          paginaAtual: 1,
        },
      });
      const emitter: any = inject('emitter');
      const { listaDeClientes, registrosPorPagina } = toRefs(props);
      const { validarCpf, validarCnpj } = ValidacoesExtension;

      const listaAtualizada = async () => {
        data.clienteSelecionado = {};
      };
      const registroDePaginasAtualizados = async () => {
        data.paginacao.paginaAtual = 1;
      };
      const alterarPaginaAtual = async () =>{
        if(Math.ceil(listaOrdenada.value.length / props.registrosPorPagina!) == 1)
          data.paginacao.paginaAtual = 1;
      };
      watch([listaDeClientes!], listaAtualizada);
      watch(registrosPorPagina!, registroDePaginasAtualizados);
      const colunasParaExibicao = computed(() => {
        if (data.colunasExibicao.length > 0) {
          return data.colunasExibicao.map(function (coluna) {
            return coluna;
          });
        } else if (Array.isArray(listaOrdenada.value)) {
          if (listaOrdenada.value.length > 0) {
            return Object.keys(listaOrdenada.value[0]).map(function (coluna) {
              var objColuna = { NomeCampo: coluna, Label: coluna };
              return objColuna;
            });
          }
        }
        return [];
      });
      const listaOrdenada = computed(() => {
        return props.listaDeClientes!.sort((a: any, b: any): number => {
          if (data.ordenacao.tipo == data.ordenacao.enum.Asc) {
            return a[data.ordenacao.campo] > b[data.ordenacao.campo]
              ? 1
              : a[data.ordenacao.campo] < b[data.ordenacao.campo]
              ? -1
              : 0;
          }
          if (data.ordenacao.tipo == data.ordenacao.enum.Desc) {
            return a[data.ordenacao.campo] < b[data.ordenacao.campo]
              ? 1
              : a[data.ordenacao.campo] > b[data.ordenacao.campo]
              ? -1
              : 0;
          }
          return a[data.ordenacao.campo] - b[data.ordenacao.campo];
        });
      });

      const indexInicial = computed(() => {
        return props.registrosPorPagina! * (data.paginacao.paginaAtual - 1);
      });
      const indexFinal = computed(() => {
        let indexFinal = Number(props.registrosPorPagina!) * (data.paginacao.paginaAtual - 1) + Number(props.registrosPorPagina!);

        return (indexFinal = +indexFinal);
      });
      const totalPaginas = computed(() => {
        return Math.ceil(listaOrdenada.value.length / props.registrosPorPagina!);
      });
      watch(totalPaginas!, alterarPaginaAtual);
      const registrosTotais = computed(() => {
        return listaOrdenada.value.length;
      });
      const registrosAtuais = computed(() => {
        if (registrosTotais.value === 0) return 0;

        if (registrosTotais.value < props.registrosPorPagina!) return registrosTotais.value;

        if (indexFinal.value > registrosTotais.value) return registrosTotais.value;

        return indexFinal.value;
      });
      const registrosComeco = computed(() => {
        if (registrosTotais.value === 0) return 0;

        if (props.registrosPorPagina! > registrosTotais.value) return 1;

        return indexInicial.value + 1;
      });
      function isEmpty(obj: any) {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
      }
      const exibirMenuLateral = computed(() => {
        return isEmpty(data.clienteSelecionado);
      });
      const permissaoAcessoParametrosGlobais = computed(() => {
        return vxm.listagem.dadosIniciaisListagem?.PermissoesUsuario?.PermissaoAcessoParametrosGlobais;
      });

      const methods = reactive({
        selecionarClienteGrid: (cliente: any) => {
          if (data.clienteSelecionado && data.clienteSelecionado == cliente) {
            data.clienteSelecionado = {};
          } else {
            data.clienteSelecionado = cliente;
          }
        },

        obterClasseOrdenacao: (nomeColuna: string) => {
          if (nomeColuna != data.ordenacao.campo) {
            return 'fa-xs fas fa-sort';
          } else if (data.ordenacao.tipo == data.ordenacao.enum.Asc) {
            return 'fa-xs fas fa-sort-up';
          } else {
            return 'fa-xs fas fa-sort-down';
          }
        },
        ordenarPor: (nomeColuna: string) => {
          if (nomeColuna != data.ordenacao.campo) {
            data.ordenacao.campo = nomeColuna;
            data.ordenacao.tipo = data.ordenacao.enum.Asc;
          } else {
            data.ordenacao.tipo =
              data.ordenacao.tipo == data.ordenacao.enum.Asc ? data.ordenacao.enum.Desc : data.ordenacao.enum.Asc;
          }
        },
        verificarSeExibeBotaoPagina: (pagina: number) => {
          data.controlePaginacao = true;
          return (
            data.paginacao.paginaAtual == pagina ||
            data.paginacao.paginaAtual + 1 == pagina ||
            data.paginacao.paginaAtual - 1 == pagina ||
            1 == pagina ||
            2 == pagina
          );
        },
        formatarTelefone: (telefone: string) => {
          if (telefone != '' && telefone != null) {
            Utils.obterSomenteNumeros(telefone);
            return FiltrosExtension.inserirMascaraTelefone(telefone);
          }
        },
        formatarDocumento: (cliente: any) => {
          if (cliente.ClienteInternacional) return cliente.DocumentoEstrangeiro;
          else {
            Utils.obterSomenteNumeros(cliente.Documento);
            if (cliente.Documento.length == 11) return FiltrosExtension.inserirMascaraCpf(cliente.Documento);
            return FiltrosExtension.inserirMascaraCnpj(cliente.Documento);
          }
        },
        obterTipoCliente: (tipo: number) => {
          return vxm.listagem.dadosIniciaisListagem.TiposCliente?.find(t => t.Id == tipo)?.Descricao || '';
        },
        atualizarListagem: () => {
          emitter.emit('voltar-listagem-inicial');
        },
        isDocumentoInvalido: (cliente: any) => {
          var doc = Utils.obterSomenteNumeros(cliente.Documento);
          return !validarCpf(doc) && !validarCnpj(doc) && !cliente.ClienteInternacional;
        },
        isFaturamentoLiberado:(cliente: any) => {
          return cliente.FaturamentoLiberado === false;
        }
      });

      return {
        ...toRefs(data),
        ...toRefs(methods),
        colunasParaExibicao,
        listaOrdenada,
        totalPaginas,
        indexInicial,
        indexFinal,
        registrosAtuais,
        registrosComeco,
        registrosTotais,
        exibirMenuLateral,
        isModalOpen,
        permissaoAcessoParametrosGlobais,
      };
    },
  });
