
  import { defineComponent, ref, watchEffect } from 'vue';
  import { vxm } from '@/store/index';

  import Campo from '@/components/shared/Campo.vue';
  import Input from '@/components/shared/Input.vue';
  import Multiselect from '@/components/shared/Multiselect.vue';
  import DatePicker from '@/components/shared/DatePicker.vue';

  import ContatoAdicionalModel from '@/core/models/contatoAdicional';
  import AlertsExtension from '@/utils/alerts';
  import { ContatoAdicional } from '@/core/types/clientes/pessoa/contato';

  export default defineComponent({
    name: 'SecaoContatoAdicionalCadastro',
    components: {
      Campo,
      Input,
      Multiselect,
      DatePicker,
    },
    props: {
      idSecaoMenu: {
        type: String,
        required: true,
      },
    },
    setup() {
      const isNovoContatoAdicional = ref(true);

      const limparContatoAdicional = () => {
        vxm.cadastro.contatoAdicional.valor = null;
        vxm.cadastro.contatoAdicionalSelecionado.valor = null;
      };

      const cadastrarContatoAdicional = (contatoAdicional: ContatoAdicional) => {
        vxm.cadastro.pessoa.contatos.contatosAdicionais.valor.push(contatoAdicional);
      };

      const atualizarContatoAdicional = (contatoAdicional: ContatoAdicional, idContatoAdicionalExistente: number) => {
        vxm.cadastro.pessoa.contatos.contatosAdicionais.valor[idContatoAdicionalExistente] = contatoAdicional;
      };

      const getIdContatoAdicionalExistente = (contatoAdicional: ContatoAdicional): number => {
        const contatosAdicionaisPessoa = vxm.cadastro.pessoa.contatos.contatosAdicionais.valor;
        return contatosAdicionaisPessoa.findIndex(({ id }) => id.valor === contatoAdicional.id.valor);
      };

      const checkIsNovoContatoAdicional = (contatoAdicional: ContatoAdicional): boolean => {
        const idContatoAdicionalExistente = getIdContatoAdicionalExistente(contatoAdicional);
        return idContatoAdicionalExistente === -1;
      };

      const salvarContatoAdicional = () => {
        if (vxm.cadastro.contatoAdicional.valor) {
          vxm.cadastro.contatoAdicional.valor.nome.valor = vxm.cadastro.contatoAdicional.valor.nome.valor.trim();
          const isContatoAdicionalValido = ContatoAdicionalModel.validarContatoAdicional(vxm.cadastro.contatoAdicional.valor);

          if (isContatoAdicionalValido) {
            const idContatoAdicionalExistente = getIdContatoAdicionalExistente(vxm.cadastro.contatoAdicional.valor);

            if (idContatoAdicionalExistente === -1) cadastrarContatoAdicional(vxm.cadastro.contatoAdicional.valor);
            else atualizarContatoAdicional(vxm.cadastro.contatoAdicional.valor, idContatoAdicionalExistente);

            limparContatoAdicional();

            AlertsExtension.exibirMensagemSucesso('Contato adicional salvo com sucesso', false);
          }
        }
      };

      const excluirContatoAdicional = () => {
        if(vxm.cadastro.contatoAdicional.valor){
          const idContato = parseInt(vxm.cadastro.contatoAdicional.valor.id.valor.toString());
          vxm.cadastro.permiteExcluirContato(idContato).then((permiteExcluirContato ) => {
            if(!permiteExcluirContato)
            {
              AlertsExtension.exibirMensagemErro('Não é possível excluir um contato já utilizado!', false);
              return;
            }

            AlertsExtension.exibirMensagemPergunta('Contato Adicional', 'Tem certeza que deseja excluir este registro?', (result) => {
              if (result && vxm.cadastro.contatoAdicional.valor) {
                const idContatoAdicionalExistente = getIdContatoAdicionalExistente(vxm.cadastro.contatoAdicional.valor);
                vxm.cadastro.pessoa.contatos.contatosAdicionais.valor.splice(idContatoAdicionalExistente, 1);
                limparContatoAdicional();
                AlertsExtension.exibirMensagemSucesso('Contato adicional excluído com sucesso', false);
              }
            });
          });
        }
      };

      watchEffect(() => {
        if (vxm.cadastro.contatoAdicional.valor)
          isNovoContatoAdicional.value = checkIsNovoContatoAdicional(vxm.cadastro.contatoAdicional.valor);
      });

      return {
        ...vxm,
        isNovoContatoAdicional,
        limparContatoAdicional,
        salvarContatoAdicional,
        excluirContatoAdicional,
      };
    },
  });
