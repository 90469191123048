
  import { defineComponent, reactive, ref, toRefs, computed } from 'vue';
  import { vxm } from '@/store/index';
  import { useRouter } from 'vue-router';
  import ApiConfig from '@/api/utils/apiConfig';

  import FiltrosExtension from '@/utils/filtros';
  import Utils from '@/utils/utils';
  import { TipoCliente, TipoPessoa } from '@/core/enums/pessoa';

  export default defineComponent({
    name: 'CardCliente',
    props: {
      clienteSelecionado: {
        type: Object,
      },
    },

    setup(props) {
      const router = useRouter();
      const data = reactive({
        collapse: false,
        dropdown: {
          show: false,
          parentStyle: 'vsm--item',
          arrowStyle: 'vsm--arrow-more-info',
        },
        urlBase: computed((): any => ApiConfig.getUrlBaseErp()),
      });
      const methods = reactive({
        editar: (idPessoa: number) => {
          router.push({ name: 'CadastroClientes', params: { idPessoa: idPessoa } });
        },
        handleOpenCloseDropdownMaisAcoes: () => {
          data.dropdown.show = !data.dropdown.show;

          if (data.dropdown.show) {
            data.dropdown.parentStyle = 'vsm--item vsm--item_open';
            data.dropdown.arrowStyle = 'vsm--arrow-more-info vsm--arrow_open_more_info';
          } else {
            data.dropdown.parentStyle = 'vsm--item';
            data.dropdown.arrowStyle = 'vsm--arrow-more-info';
          }
        },
        obterLabelDocumento: function (documento: any) {
          documento = documento.replace(/[^0-9]/g, '');
          if (documento.length > 12) return 'CNPJ: ' + methods.formatarDocumento(documento);

          if (documento.length < 9) return 'Documento: ' + documento;

          return 'CPF: ' + methods.formatarDocumento(documento);
        },
        exibirNomeFantasia: function (clienteSelecionado: any) {
          if (clienteSelecionado?.NomeFantasia != '' && clienteSelecionado?.NomeFantasia != null) {
            if (clienteSelecionado?.NomeFantasia != clienteSelecionado?.NomeRazaoSocial &&
              (clienteSelecionado?.TipoPessoa != TipoPessoa.fisica &&
              clienteSelecionado?.TipoCliente != TipoCliente.cliente)) return true;
          }
          return false;
        },
        exibirMaisInformacoes: function (clienteSelecionado: any) {
          return (
            (clienteSelecionado?.Observacoes ?? '') ||
            (clienteSelecionado?.ContatosAdicionais?.length ?? 0) ||
            (clienteSelecionado?.Vendedores?.length ?? 0)
          );
        },

        obterUrlEmail: function (email: string) {
          if (!email) return;

          return 'mailto:' + email;
        },
        formatarEnderecoCompletoCliente: function (endereco: any) {
          var enderecoCompleto = endereco.Logradouro + ', ';
          if (endereco.Numero && endereco.Numero !== '') {
            enderecoCompleto += endereco.Numero;
          }
          if (endereco.Complemento && endereco.Complemento !== '') {
            enderecoCompleto += ' -Complemento ' + endereco.Complemento;
          }

          enderecoCompleto +=
            '- CEP: ' + endereco.CEP + ', ' + endereco.Bairro + ' - ' + endereco.Cidade.NomeCidade + ' - ' + endereco.UF;
          return enderecoCompleto;
        },

        obterUrlWhatsapp: function (numeroWhats: string) {
          if (!numeroWhats) return '';

          var prefixoPais = '55';
          return 'https://wa.me/' + prefixoPais + Utils.obterSomenteNumeros(numeroWhats);
        },

        obterUrlTelefone: function (numeroTelefone: string) {
          if (!numeroTelefone) return '';

          return 'tel:' + Utils.obterSomenteNumeros(numeroTelefone);
        },

        obterUrlEnderecoClienteGoogleMaps: function (endereco: any) {
          var urlGoogleMaps = 'http://maps.google.com/maps?q=';
          urlGoogleMaps += endereco.Logradouro.replace(new RegExp(' ', 'g'), '+');

          if (endereco.Numero) urlGoogleMaps += ',' + endereco.Numero.replace(new RegExp(' ', 'g'), '');

          if (endereco.Bairro) urlGoogleMaps += '+' + endereco.Bairro.replace(new RegExp(' ', 'g'), '+');

          if (endereco.Cidade.NomeCidade) urlGoogleMaps += ',' + endereco.Cidade.NomeCidade.replace(new RegExp(' ', 'g'), '+');

          if (endereco.UF) urlGoogleMaps += '+' + endereco.UF.replace(new RegExp(' ', 'g'), '+');

          if (endereco.CEP) urlGoogleMaps += ',' + endereco.CEP.replace(new RegExp(' ', 'g'), '+');

          return urlGoogleMaps;
        },
        formatarTelefone: (telefone: string) => {
          if (telefone.length > 0) return FiltrosExtension.inserirMascaraTelefone(telefone);
        },
        formatarDocumento: (documento: string) => {
          if (documento.length <= 11) return FiltrosExtension.inserirMascaraCpf(documento);
          return FiltrosExtension.inserirMascaraCnpj(documento);
        },
        obterLabelTipoCliente: function (tipo: number) {
          return vxm.listagem.dadosIniciaisListagem.TiposCliente?.find((t) => t.Id == tipo)?.Descricao || '';
        },
      });
      return {
        ...vxm,
        ...toRefs(methods),
        ...toRefs(data),
      };
    },
  });
