
  import { defineComponent, watchEffect } from 'vue';
  import { vxm } from '@/store/index';

  import Campo from '@/components/shared/Campo.vue';
  import Input from '@/components/shared/Input.vue';
  import Multiselect from '@/components/shared/Multiselect.vue';
  import BaseModal from '@/components/shared/BaseModal.vue';
  import EncontraCEP from '@/components/clientes/cadastro/EncontraCEP.vue';

  export default defineComponent({
    name: 'SecaoEnderecoAdicionalCadastro',
    components: {
      Campo,
      Input,
      Multiselect,
      BaseModal,
      EncontraCEP,
    },
    setup() {
      const setaDescricaoEstado = (idEstado: number) => {
        const estadoSelecionado = vxm.cadastro.obterMultiselectEstadosComEstadoEX.find(({ id }) => idEstado === id);
        if (estadoSelecionado && vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor)
          vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor.estado.descricao = estadoSelecionado.descricao;
      };

      const handleCampoEstado = () => {
        if (vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor) {
          setaDescricaoEstado(Number(vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor.estado.valor));
          vxm.cadastro.sugerirClasseFiscalEnderecoAdicional();
        }
      };

      const sugerirClasseFiscal = () => {
        vxm.cadastro.sugerirClasseFiscalEnderecoAdicional();
      };

      watchEffect(() => {
        handleCampoEstado();
      });

      return {
        ...vxm,
        sugerirClasseFiscal,
      };
    },
  });
