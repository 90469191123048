
  import { computed, defineComponent, PropType, watch } from 'vue';
  import useCurrencyInput, { CurrencyInputOptions } from 'vue-currency-input';

  export default defineComponent({
    name: 'InputMoney',
    props: {
      modelValue: {
        type: Number,
        default: 0,
        required: true,
      },
      options: {
        type: Object as PropType<CurrencyInputOptions>,
        default: () => {
          return {
            currency: 'BRL',
            locale: 'pt-BR',
            precision: 2,
            currencyDisplay: 'symbol',
            hideCurrencySymbolOnFocus: false,
            hideGroupingSeparatorOnFocus: false,
            hideNegligibleDecimalDigitsOnFocus: true,
          } as CurrencyInputOptions;
        },
        required: false,
      },
    },
    setup(props) {
      const { formattedValue, inputRef, setOptions, setValue } = useCurrencyInput(props.options);

      watch(
        () => props.modelValue,
        (value) => {
          setValue(value);
        },
      );

      watch(
        () => props.options,
        (options) => {
          setOptions(options);
        },
      );

      return { inputRef, formattedValue };
    },
  });
