import { Campo } from '../../campos';

export interface DadosCnpj {
  nome: Campo<string>;
  fantasia: Campo<string>;
  cep: Campo<string>;
  logradouro: Campo<string>;
  numero: Campo<string>;
  complemento: Campo<string>;
  bairro: Campo<string>;
  municipio: Campo<string>;
  uf: Campo<number | string>;
  telefone: Campo<string>;
  email: Campo<string>;
  status: Campo<string>;
}

class CnpjDadosTypes {
  public static obterDadosCnpj = (
    nome: Campo<string>,
    fantasia: Campo<string>,
    cep: Campo<string>,
    logradouro: Campo<string>,
    numero: Campo<string>,
    complemento: Campo<string>,
    bairro: Campo<string>,
    municipio: Campo<string>,
    uf: Campo<number | string>,
    telefone: Campo<string>,
    email: Campo<string>,
    status: Campo<string>,
  ): DadosCnpj => {
    return {
      nome,
      fantasia,
      cep,
      logradouro,
      numero,
      complemento,
      bairro,
      municipio,
      uf,
      telefone,
      email,
      status,
    } as DadosCnpj;
  };
}

export default CnpjDadosTypes;
