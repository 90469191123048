export type ListaClientes = [];
export type Nullable<T> = T | undefined | null;
export type FiltrosPesquisaAvancada = {
  TermoPesquisa?: string;
  ExibirInativos?: boolean;
  IdsTipoClientes?: Array<number>;
  IdsClasses?: Array<number>;
  IdsCidades?: Array<number>;
  Dependentes?: Array<string>;
  CamposAdicionais?: Nullable<CamposAdicionais>;
  CartaoFidelidade?: string;
  DataAniversarioInicial?: Nullable<string>;
  DataAniversarioFinal?: Nullable<string>;
  DataCadastroInicial?: Nullable<string>;
  DataCadastroFinal?: Nullable<string>;
};
export type TipoCliente = {
  Id: number;
  Descricao: string;
};

export type Filtros = [];

export type PermissoesUsuario = {
  CrediarioFacilPermissaoAcessoFichaCliente: boolean;
  CrmBloquearImportacaoClientesFornecedores: boolean;
  CrmPermissoesClientesInclusao: boolean;
  CrmPermissoesClientesAlteracao: boolean;
  CrmPermissoesClientesAlterarContatos: boolean;
  CrmPermissoesClientesExclusao: boolean;
  CrmPermissoesFornecedoresInclusao: boolean;
  CrmPermissoesClientesInformacoesFinanceiras: boolean;
  CrmPermissoesFornecedoresAlteracao: boolean;
  CrmPermissoesFornecedoresExclusao: boolean;
  CrmPermissoesFornecedoresInformacoesFinanceiras: boolean;
  CrmPermissoesTransportadoresInclusao: boolean;
  CrmPermissoesTransportadoresAlteracao: boolean;
  CrmAnonimizarDadosCliente: boolean;
  PermissaoAcessoParametrosGlobais: boolean;
};

export type CamposAdicionais = {
  IdCampoAdicional: number;
  TipoCampo: number;
  Valor: string;
};

export type DadosIniciais = {
  IdRamoPortal?: number;
  UrlBaseErp?: string;
  UtilizaCrediarioFacil?: boolean;
  EmpModGarantia?: boolean;
  PermissoesUsuario?: PermissoesUsuario;
  TiposCliente?: Array<TipoCliente>;
};

export enum TiposCamposAdicionais {
  Texto = 1,
  SimNao = 2,
  PreOpcional = 3,
}

class ListagemTypes {
  public obterDadosIniciaisListagem = (): DadosIniciais => {
    const dadosIniciais: DadosIniciais = {};
    return dadosIniciais;
  };
  public listagemUltimosClientesAtualizados = (): ListaClientes => {
    const listagemClientesAtualizados: ListaClientes = [];

    return listagemClientesAtualizados;
  };
  public obterFiltrosPesquisaAvancada = (): Filtros => {
    const filtrosPesquisaAvancada: Filtros = [];

    return filtrosPesquisaAvancada;
  };
  public filtrosPesquisaAvancada = (): FiltrosPesquisaAvancada => {
    const filtros: FiltrosPesquisaAvancada = {
      TermoPesquisa: '',
      CartaoFidelidade: '',
    };

    return filtros;
  };
}

export default new ListagemTypes();
