import CampoTypes, { Campo } from '../../campos';
import PessoaDadosAdicionaisTypes, { DadosAdicionais } from './adicionais';
import PessoaComercialTypes, { DadosComerciais } from './comercial';
import PessoaContatoTypes, { Contatos } from './contato';
import PessoaConvenioTypes, { ConvenioCliente, ConvenioFornecedor } from './convenio';
import PessoaEnderecoTypes, { Endereco, EnderecoAdicional, EnderecoCobranca } from './endereco';
import PessoaCamposAdicionaisTypes, { PessoaCamposAdicionaisValores } from './camposAdicionais';

const { obterCampo } = CampoTypes;

export interface PessoaFisica {
  rg: Campo<string>;
  dataEmissaoRg: Campo<string>;
  orgaoEmissor: Campo<string>;
  dataNascimento: Campo<string>;
  estadoCivil: Campo<number | string>;
  sexo: Campo<number | string>;
  cartaoFidelidade: Campo<string>;
  localTrabalho: Campo<string>;
  atividadeExercida: Campo<string>;
  tempoEmpregoAtual: Campo<string>;
  rendaMensal: Campo<number>;
  escolaridade: Campo<number | string>;
  nomeMae: Campo<string>;
  nomePai: Campo<string>;
  nomeConjuge: Campo<string>;
  referenciasPessoais: Campo<string>;
  quantidadeDependentes: Campo<number | string>;
  naturalidade: Campo<string>;
}

export interface Doc {
  id: number;
  descricao: string;
  nomeArquivo: string;
  tipoDoc?: number;
}

export interface PessoasClasses {
  id: number;
  idClasse: number;
  idPessoa: number;
}

export interface PessoasTransportadorasIO {
  id: number;
  idTransportadoraIO: number;
  idPessoa: number;
}

export interface PessoaJuridica {
  isOptanteSimplesEstadual: Campo<boolean>;
  inscricaoSuframa: Campo<number | string>;
  inscricaoMunicipal: Campo<string>;
}

export interface Fornecedor {
  isDiferencialIcms: Campo<boolean>;
  isSomaDespesasParaIcmsEmNf: Campo<boolean>;
  isSomaDespesasParaIpiEmNf: Campo<boolean>;
  isSomaFreteParaIpiEmNf: Campo<boolean>;
  isSomaSeguroParaIpiEmNf: Campo<boolean>;
  isDescontosAcrescimosFornecedoresIpiPedidosDeCompra: Campo<boolean>;
  isLancaIcmsIpiEntradaDevolucaoCompras: Campo<boolean>;
  diferencialIcms: Campo<number | string>;
  categoriaFinanceira: Campo<number | string>;
}

export interface Pessoa {
  id: Campo<number>;
  documento: Campo<string>;
  nome: Campo<string>;
  nomeFantasia: Campo<string>;
  documentoEstrangeiro: Campo<string>;
  inscricaoEstadual: Campo<string>;
  classe: Campo<number | string>;
  subclasse: Campo<number | string>;
  isInscricaoEstadual: Campo<boolean>;
  isRevendedor: Campo<boolean>;
  isClienteInternacional: Campo<boolean>;
  isContribuinteIcms: Campo<boolean>;
  isAtivo: Campo<boolean>;
  isAnonimo: Campo<boolean>;
  tipoCliente: Campo<number | string>;
  tipoPessoa: Campo<number | string>;
  endereco: Endereco;
  enderecoCobranca: EnderecoCobranca;
  pessoaFisica: PessoaFisica;
  pessoaJuridica: PessoaJuridica;
  fornecedor: Fornecedor;
  contatos: Contatos;
  comercial: DadosComerciais;
  convenioCliente: ConvenioCliente;
  convenioFornecedor: ConvenioFornecedor;
  dadosAdicionais: DadosAdicionais;
  camposAdicionaisValores: Array<PessoaCamposAdicionaisValores>;
  empresas: Campo<Array<number>>;
  docs: Campo<Array<Doc>>;
  pessoasClasses: Campo<Array<number>>;
  transportadoraIO: Campo<number | string>;
  receberEmailMarketing: Campo<boolean>;
  percentualMaximoDevolucao: Campo<number>;
  registroProfissional: Campo<string>;
  idTipoRegistroProfissional: Campo<number | string>;
  transportadorIdentificacao: Campo<boolean>;
  conveniadorIdentificacao: Campo<boolean>;
}

class PessoaTypes {
  public static obterFornecedor = (
    isDiferencialIcms: Campo<boolean>,
    isSomaDespesasParaIcmsEmNf: Campo<boolean>,
    isSomaDespesasParaIpiEmNf: Campo<boolean>,
    isSomaFreteParaIpiEmNf: Campo<boolean>,
    isSomaSeguroParaIpiEmNf: Campo<boolean>,
    isDescontosAcrescimosFornecedoresIpiPedidosDeCompra: Campo<boolean>,
    isLancaIcmsIpiEntradaDevolucaoCompras: Campo<boolean>,
    diferencialIcms: Campo<number | string>,
    categoriaFinanceira: Campo<number | string>,
  ): Fornecedor => {
    return {
      diferencialIcms,
      isDiferencialIcms,
      isSomaDespesasParaIcmsEmNf,
      isSomaDespesasParaIpiEmNf,
      isSomaFreteParaIpiEmNf,
      isSomaSeguroParaIpiEmNf,
      isDescontosAcrescimosFornecedoresIpiPedidosDeCompra,
      isLancaIcmsIpiEntradaDevolucaoCompras,
      categoriaFinanceira,
    } as Fornecedor;
  };

  public static obterPessoaFisica = (
    rg: Campo<string>,
    orgaoEmissor: Campo<string>,
    dataEmissaoRg: Campo<string>,
    estadoCivil: Campo<number | string>,
    sexo: Campo<number | string>,
    cartaoFidelidade: Campo<string>,
    localTrabalho: Campo<string>,
    atividadeExercida: Campo<string>,
    tempoEmpregoAtual: Campo<string>,
    rendaMensal: Campo<number>,
    escolaridade: Campo<number | string>,
    nomeMae: Campo<string>,
    nomePai: Campo<string>,
    nomeConjuge: Campo<string>,
    referenciasPessoais: Campo<string>,
    quantidadeDependentes: Campo<number | string>,
    naturalidade: Campo<string>,
    dataNascimento: Campo<string>,
  ): PessoaFisica => {
    return {
      rg,
      dataEmissaoRg,
      orgaoEmissor,
      dataNascimento,
      estadoCivil,
      sexo,
      cartaoFidelidade,
      localTrabalho,
      atividadeExercida,
      tempoEmpregoAtual,
      rendaMensal,
      escolaridade,
      nomeMae,
      nomePai,
      nomeConjuge,
      referenciasPessoais,
      quantidadeDependentes,
      naturalidade,
    } as PessoaFisica;
  };

  public static ObterDoc = (id: number, nomeArquivo: string, descricao: string, tipoDoc?: number): Doc => {
    return {
      id,
      nomeArquivo,
      descricao,
      tipoDoc,
    } as Doc;
  };

  public static obterPessoasClasses = (id: number, idClasse: number, idPessoa: number): PessoasClasses => {
    return {
      id,
      idClasse,
      idPessoa,
    } as PessoasClasses;
  };

  public static obterPessoaJuridica = (
    isOptanteSimplesEstadual: Campo<boolean>,
    inscricaoSuframa: Campo<number | string>,
    inscricaoMunicipal: Campo<string>,
  ): PessoaJuridica => {
    return {
      inscricaoMunicipal,
      inscricaoSuframa,
      isOptanteSimplesEstadual,
    } as PessoaJuridica;
  };

  public static obterPessoa = (
    id: Campo<number | string>,
    documento: Campo<string>,
    nome: Campo<string>,
    nomeFantasia: Campo<string>,
    documentoEstrangeiro: Campo<string>,
    inscricaoEstadual: Campo<string>,
    classe: Campo<number | string>,
    subclasse: Campo<number | string>,
    isInscricaoEstadual: Campo<boolean>,
    isRevendedor: Campo<boolean>,
    isClienteInternacional: Campo<boolean>,
    isContribuinteIcms: Campo<boolean>,
    isAtivo: Campo<boolean>,
    isAnonimo: Campo<boolean>,
    tipoCliente: Campo<number | string>,
    tipoPessoa: Campo<number | string>,
    pessoaFisica: PessoaFisica,
    pessoaJuridica: PessoaJuridica,
    fornecedor: Fornecedor,
    endereco: Endereco,
    enderecoCobranca: EnderecoCobranca,
    contatos: Contatos,
    comercial: DadosComerciais,
    convenioCliente: ConvenioCliente,
    convenioFornecedor: ConvenioFornecedor,
    dadosAdicionais: DadosAdicionais,
    camposAdicionaisValores: Array<PessoaCamposAdicionaisValores>,
    empresas: Campo<Array<number>>,
    docs: Campo<Array<Doc>>,
    pessoasClasses: Campo<Array<number>>,
    transportadoraIO: Campo<number | string>,
    receberEmailMarketing: Campo<boolean>,
    percentualMaximoDevolucao: Campo<number>,
    registroProfissional: Campo<string>,
    idTipoRegistroProfissional: Campo<number | string>,
    transportadorIdentificacao: Campo<boolean>,
    conveniadorIdentificacao: Campo<boolean>,
  ): Pessoa => {
    return {
      id,
      documento,
      nome,
      nomeFantasia,
      documentoEstrangeiro,
      inscricaoEstadual,
      classe,
      subclasse,
      isInscricaoEstadual,
      isRevendedor,
      isClienteInternacional,
      isContribuinteIcms,
      isAtivo,
      tipoCliente,
      tipoPessoa,
      isAnonimo,
      pessoaFisica,
      pessoaJuridica,
      fornecedor,
      endereco,
      enderecoCobranca,
      contatos,
      comercial,
      convenioCliente,
      convenioFornecedor,
      dadosAdicionais,
      camposAdicionaisValores,
      empresas,
      docs,
      pessoasClasses,
      transportadoraIO,
      receberEmailMarketing,
      percentualMaximoDevolucao,
      registroProfissional,
      idTipoRegistroProfissional,
      transportadorIdentificacao,
      conveniadorIdentificacao,
    } as Pessoa;
  };

  public static obterNovaPessoa = (): Pessoa => {
    return {
      id: obterCampo(0),
      documento: obterCampo(''),
      nome: obterCampo(''),
      nomeFantasia: obterCampo(''),
      documentoEstrangeiro: obterCampo(''),
      inscricaoEstadual: obterCampo(''),
      classe: obterCampo(''),
      subclasse: obterCampo(''),
      tipoCliente: obterCampo(''),
      tipoPessoa: obterCampo(''),
      isInscricaoEstadual: obterCampo(false),
      isRevendedor: obterCampo(false),
      isClienteInternacional: obterCampo(false),
      isContribuinteIcms: obterCampo(false),
      isAtivo: obterCampo(true),
      isAnonimo: obterCampo(false),
      endereco: PessoaEnderecoTypes.obterEndereco(
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo([]),
        obterCampo(0),
      ),
      enderecoCobranca: PessoaEnderecoTypes.obterEnderecoCobranca(
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
      ),
      pessoaFisica: PessoaTypes.obterPessoaFisica(
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(0),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
      ),
      pessoaJuridica: PessoaTypes.obterPessoaJuridica(obterCampo(false), obterCampo(''), obterCampo('')),
      fornecedor: PessoaTypes.obterFornecedor(
        obterCampo(false),
        obterCampo(true),
        obterCampo(false),
        obterCampo(false),
        obterCampo(false),
        obterCampo(false),
        obterCampo(false),
        obterCampo(''),
        obterCampo(''),
      ),
      contatos: PessoaContatoTypes.obterContatos(
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo([]),
      ),
      comercial: PessoaComercialTypes.obterDadosComerciais(
        obterCampo(true),
        obterCampo(0),
        obterCampo(''),
        obterCampo(0),
        obterCampo(0),
        obterCampo(0),
        obterCampo(0),
        obterCampo(0),
        obterCampo(0),
        obterCampo(''),
        obterCampo(0),
        obterCampo(0),
        obterCampo(false),
        obterCampo([]),
        obterCampo([]),
        obterCampo(0),
        obterCampo(''), //MODAJOI-37214 (US 001)
        obterCampo(false), //MODAJOI-37214 (US 002)
        obterCampo([]),
        obterCampo(0),
        obterCampo(''),
        obterCampo(true),
        obterCampo(0),
        obterCampo(false),
        [],
        obterCampo(''),
        obterCampo(true),
        obterCampo(0),
      ),
      convenioCliente: PessoaConvenioTypes.obterConvenioCliente(obterCampo(''), obterCampo(''), obterCampo(0), obterCampo(false)),
      convenioFornecedor: PessoaConvenioTypes.obterConvenioFornecedor(
        obterCampo(0),
        obterCampo(false),
        obterCampo(''),
        obterCampo(''),        
      ),
      dadosAdicionais: PessoaDadosAdicionaisTypes.ObterDadosAdicionais(
        obterCampo([]),
        obterCampo(''),
        obterCampo(''),
        obterCampo(''),
        obterCampo(0),
      ),
      camposAdicionaisValores: [] as Array<PessoaCamposAdicionaisValores>,
      empresas: obterCampo([]),
      docs: obterCampo([]),
      pessoasClasses: obterCampo([]),
      transportadoraIO: obterCampo(''),
      receberEmailMarketing: obterCampo(false),
      percentualMaximoDevolucao: obterCampo(0),
      registroProfissional: obterCampo(''),
      idTipoRegistroProfissional: obterCampo(''),
      transportadorIdentificacao: obterCampo(false),
      conveniadorIdentificacao: obterCampo(false),
    } as Pessoa;
  };
}

export default PessoaTypes;
