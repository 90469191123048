import CampoTypes, { Campo, CampoMultiselect } from '../../campos';

const { obterCampo } = CampoTypes;

export interface Contatos {
  email: Campo<string>;
  celular: Campo<string>;
  telefonePrincipal: Campo<string>;
  telefoneSecundario: Campo<string>;
  site: Campo<string>;
  contatosAdicionais: Campo<Array<ContatoAdicional>>;
}

export interface ContatoAdicional {
  id: Campo<number | string>;
  nome: Campo<string>;
  sexo: Campo<number | string>;
  parentesco: Campo<number | string>;
  dataNascimento: Campo<string>;
  telefone: Campo<string>;
  celular: Campo<string>;
  email: Campo<string>;
  tipo: Campo<string>;
  observacoes: Campo<string>;
  permiteExcluir: Campo<boolean>;
}

export interface ContatoAdicionalMultiselect extends CampoMultiselect {}

class PessoaContatoTypes {
  public static obterContatos = (
    email: Campo<string>,
    celular: Campo<string>,
    telefonePrincipal: Campo<string>,
    telefoneSecundario: Campo<string>,
    site: Campo<string>,
    contatosAdicionais: Campo<Array<ContatoAdicional>>,
  ): Contatos => {
    return {
      email,
      celular,
      telefonePrincipal,
      telefoneSecundario,
      site,
      contatosAdicionais,
    } as Contatos;
  };

  public static obterContatoAdicional = (
    id: Campo<number | string>,
    nome: Campo<string>,
    sexo: Campo<number | string>,
    parentesco: Campo<number | string>,
    dataNascimento: Campo<string>,
    telefone: Campo<string>,
    celular: Campo<string>,
    email: Campo<string>,
    tipo: Campo<string>,
    observacoes: Campo<string>,
    permiteExcluir: Campo<boolean>,
  ): ContatoAdicional => {
    return {
      id,
      nome,
      celular,
      dataNascimento,
      email,
      parentesco,
      sexo,
      telefone,
      tipo,
      observacoes,
      permiteExcluir,
    } as ContatoAdicional;
  };

  public static obterNovoContatoAdicional = (): ContatoAdicional => {
    return {
      id: obterCampo(''),
      nome: obterCampo(''),
      celular: obterCampo(''),
      dataNascimento: obterCampo(''),
      email: obterCampo(''),
      parentesco: obterCampo(''),
      sexo: obterCampo(''),
      telefone: obterCampo(''),
      tipo: obterCampo(''),
      observacoes: obterCampo(''),
      permiteExcluir: obterCampo(true),
    } as ContatoAdicional;
  };
}

export default PessoaContatoTypes;
