<template>
  <DadosBancariosSelecao />
  <div v-if="alerta.visivel" class="alert alert-warning text-center small mt-2 mb-1" role="alert">
    <span v-html="alerta.texto"></span>
  </div>
</template>
<script setup>
  import { computed } from 'vue';
  import { vxm } from '@/store';
  import DadosBancariosSelecao from '@/components/clientes/cadastro/comercial/dadosBancarios/DadosBancariosSelecao.vue';

  const alerta = computed(() => {
    const alertaObj = {
      visivel: false,
      texto: '',
    };

    const adicaoBloqueada = vxm.cadastro.componenteDadosBancarios.bloquearAdicaoDadosBancarios;
    const visibilidadePadrao = (visivel) => adicaoBloqueada || visivel;
    const textoPadrao = (multiplosDadosBancarios) => {
      return `Para realizar o agendamento, ${
        multiplosDadosBancarios
          ? 'escolha um dos Dados Bancarários acima e selecione a opção <b>Conta/PIX para agendamento de Pagamentos</b>.'
          : ' a opção <b>Conta/PIX para agendamento de Pagamentos</b> de um dos dados bancários deve estar selecionada.'
      }`;
    };

    const dadosBancarios = vxm.cadastro.pessoa.comercial.bancos.valor;
    if (!dadosBancarios?.length)
      return {
        ...alertaObj,
        visivel: visibilidadePadrao(true),
        texto: textoPadrao(false),
      };

    const dadosBancariosSelecionados = vxm.cadastro.componenteDadosBancarios.pessoaBancoSelecionado?.valor;
    if (dadosBancariosSelecionados?.isContaAgendamentoPagamento?.valor) return alertaObj;

    const dadoBancarioSalvosComAgendamento = dadosBancarios.some((db) => db.isContaAgendamentoPagamento.valor);
    if (!dadoBancarioSalvosComAgendamento)
      return {
        ...alertaObj,
        visivel: visibilidadePadrao(true),
        texto: textoPadrao(dadosBancarios.length > 1),
      };

    return alertaObj;
  });
</script>
