import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "row"
}
const _hoisted_2 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DadosBancariosFormularioConta = _resolveComponent("DadosBancariosFormularioConta")
  const _component_DadosBancariosFormularioPixTipos = _resolveComponent("DadosBancariosFormularioPixTipos")

  return (_ctx.tipoChavePix === _ctx.TipoChavePix.ContaBancaria)
    ? (_openBlock(), _createBlock(_component_DadosBancariosFormularioConta, {
        key: 0,
        isPix: true
      }))
    : (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_DadosBancariosFormularioPixTipos, { tipoChavePix: _ctx.tipoChavePix }, null, 8, ["tipoChavePix"])
        ])
      ]))
}