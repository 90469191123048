
  import { defineComponent, reactive, toRefs, watch, ref, computed } from 'vue';
  import AlertsExtension from '@/utils/alerts';
  import { vxm } from '@/store/index';

  export default defineComponent({
    name: 'SideBarImagem',
    props: {
      idPessoa: { type: Number, default: 0 },
    },
    setup(props) {
      const data = reactive({
        imagensDict: ref({}),
        foto: false,
      });
      const { idPessoa } = toRefs(props);
      const atualizafoto = async () => {
        data.foto = false;
        obterImagem(props.idPessoa);
      };

      watch([idPessoa!], atualizafoto);
      const obterImagem = async (idPessoa: any): Promise<void> => {
        await vxm.listagem
          .obterFoto(idPessoa)
          .then((blobResponse) => {
            if (blobResponse.size > 0) {
              (data.foto = true), (data.imagensDict = URL.createObjectURL(blobResponse));
            }
          })
          .catch((error) => {
            AlertsExtension.exibirMensagemErro(error, false);
          });
      };
      obterImagem(props.idPessoa);

      return { ...toRefs(data) };
    },
  });
