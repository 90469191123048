export interface Secao {
  id: string;
  titulo: string;
  visivel: boolean;
}

export const idSecao = {
  identificacao: 'secao-identificacao-cadastro-clientes',
  endereco: 'secao-endereco-cadastro-clientes',
  enderecoAdicional: 'secao-endereco-adicional-cadastro-clientes',
  enderecoCobranca: 'secao-endereco-cobranca-cadastro-clientes',
  contatos: 'secao-contato-cadastro-clientes',
  contatoAdicional: 'secao-contato-adicional-cadastro-clientes',
  comercial: 'secao-comercial-cadastro-clientes',
  dadosBancarios: 'secao-comercial-dados-bancarios',
  convenio: 'secao-convenio-cadastro-clientes',
  adicionais: 'secao-adicionais-cadastro-clientes',
  camposAdicionais: 'secao-campos-adicionais-cadastro-clientes',
  cadastroBiometrico: 'secao-cadastro-biometrico-cadastro-clientes',
  politica: 'secao-politica-cadastro-clientes',
}

class MenuTypes {
  public static obterSecao(id = '', titulo = '', visivel = false) {
    const secao: Secao = { id, titulo, visivel };
    return secao;
  }
}

export default MenuTypes;
