//MODAJOI-37214 (US 001)
import { CampoMultiselect } from "../campos";

export interface FreteTipos extends CampoMultiselect { }

export interface DadosFreteTipos {
  freteTipos: Array<FreteTipos>;
}

class NotaSimplificadaTypes {
  public static obterFreteTipos = (
    freteTipos: Array<FreteTipos> = [],
  ) => {
    return {
      freteTipos
    } as DadosFreteTipos
  };
}

export default NotaSimplificadaTypes;