
  import { defineComponent, reactive, toRefs, onMounted } from 'vue';

  import Header from '@/components/clientes/listagem/Header.vue';

  export default defineComponent({
    name: 'ListagemClientes',
    components: {
      Header,
    },
  });
