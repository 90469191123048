
  import { computed, defineComponent, reactive, toRefs } from 'vue';

  import Campo from '@/components/shared/Campo.vue';
  import Multiselect from '@/components/shared/Multiselect.vue';

  import { vxm } from '@/store/index';

  import { Doc } from '@/core/types/clientes/pessoa/pessoa';
  import { ApiError } from '@/api/utils/handler';
  import AlertsExtension from '@/utils/alerts';
  import { useDropzone } from 'vue3-dropzone';
  import { FileUploadOptions } from 'vue3-dropzone/dist/useDropzone';
  import { DocParaInserir } from '@/core/types/clientes/cadastro';

  export default defineComponent({
    name: 'CadastroPessoaFisica',
    components: {
      Campo,
      Multiselect,
    },
    setup() {
      const methods = {
        obterDescricaoTipoDoc: (tipoDoc?: number): string => {
          if (tipoDoc) {
            const tipoEncontrado = vxm.cadastro.dadosIniciaisParaCadastro.tiposDocs.find((tipo) => tipo.id == tipoDoc);
            if (tipoEncontrado) return tipoEncontrado.descricao;
          }
          return '-';
        },

        downloadDoc: async (doc: Doc, indexDoc: number): Promise<void> => {
          if (doc.id) {
            await vxm.cadastro
              .downloadDoc(doc.id)
              .then((blobResponse) => {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveOrOpenBlob(blobResponse, doc.nomeArquivo);
                  return;
                }
                const data = window.URL.createObjectURL(blobResponse);
                const link = document.createElement('a');
                link.href = data;
                link.download = doc.nomeArquivo;
                link.click();
                setTimeout(function () {
                  window.URL.revokeObjectURL(data);
                }, 100);
                return;
              })
              .catch((error: ApiError) => {
                AlertsExtension.exibirMensagemErro(error, false);
              });
          }
        },

        excluirDoc: async (doc: Doc, indexDoc: number): Promise<void> => {
          if (doc.id) {
            const idDoc = doc.id;
            AlertsExtension.exibirMensagemPergunta('Confirmar exclusão', 'Tem certeza que deseja excluir este doc?', (isSim) => {
              if (isSim)
                vxm.cadastro
                  .excluirDoc(idDoc)
                  .then(() => {
                    AlertsExtension.exibirMensagemSucesso('Doc excluído com sucesso!', true);
                  })
                  .catch((error: ApiError) => {
                    AlertsExtension.exibirMensagemErro(error, false);
                  });
            });
          } else {
            vxm.cadastro.pessoa.docs.valor.splice(indexDoc, 1);
          }
        },

        removerDosDocsParaInserir: (index: number): void => {
          vxm.cadastro.docsParaInserir.splice(index, 1);
        },
      };

      const tamanhoMaximoDoc = 2097152;
      const tiposAceitos = ['.rar', '.txt', '.jpg', '.jpeg', '.gif', '.doc', '.docx', '.zip', '.pdf', '.xls', '.xlsx', '.png'];
      const mensagensRejeicao = {
        'file-invalid-type': `Formato do arquivo inválido. Tipos permitidos: ${tiposAceitos.join(', ')}`,
        'file-too-large': `Tamanho do arquivo excedido. Tamanho máximo: ${tamanhoMaximoDoc / 1024 / 1024}MB`,
        'file-too-small': 'Arquivo muito pequeno.',
        'too-many-files': 'Muitos arquivos simultâneos.',
      } as { [key: string]: string };
      const options: Partial<FileUploadOptions> = {
        accept: tiposAceitos,
        disabled: false,
        maxSize: tamanhoMaximoDoc,
        minSize: 0,
        multiple: true,
        maxFiles: 10,
        onDrop: (acceptedFiles: any[], rejectReasons: any[], event: Event) => {
          acceptedFiles.forEach((acceptedDoc) => {
            const doc = {
              descricao: '',
              tipoDoc: undefined,
              file: acceptedDoc,
            } as DocParaInserir;
            vxm.cadastro.docsParaInserir.push(doc);
          });
          if (rejectReasons.length > 0) {
            let mensagemErro = '';
            rejectReasons.forEach((reason) => {
              mensagemErro += `${reason.file.name}: ${reason.errors
                .map((error: { code: string; message: string }) => mensagensRejeicao[error.code])
                .join(' ')}`;
              mensagemErro += '<br>';
            });
            AlertsExtension.exibirMensagemErro(mensagemErro, false);
          }
        },
        preventDropOnDocument: true,
        noClick: false,
        noKeyboard: false,
        noDrag: true,
        noDragEventsBubbling: true,
      };
      const dropzone = useDropzone(options);

      return {
        ...vxm,
        ...methods,
        ...dropzone,
      };
    },
  });
