
  // import loading from '@/common/loading';
  import { defineComponent, reactive, toRefs } from 'vue';
  import { vxm } from '@/store/index';
  import { RetornoObterCepPorEnderecoDTO } from '@/api/contracts/encontraCep';

  export default defineComponent({
    name: 'EncontraCEP',
    props: {
      isCobranca: {
        type: Boolean,
        default: false,
        required: false,
      },
      isAdicional: {
        type: Boolean,
        default: false,
        required: false,
      },
    },

    setup(props) {
      const data = reactive({
        listaEnderecos: [] as Array<RetornoObterCepPorEnderecoDTO>,
        logradouro: '',
        numero: '',
        localidade: '',
        uf: '',
        errorMsg: '',
      });

      const methods = reactive({
        buscaCepApi: () => {
          if (!data.logradouro || !data.localidade || !data.uf) {
            document.forms[0].classList.add('was-validated');
          } else {
            document.forms[0].classList.remove('was-validated');

            vxm.loading.showLoading();

            vxm.cadastro
              .obterCepPorEndereco({
                UF: data.uf,
                Cidade: data.localidade,
                Logradouro: data.logradouro,
              })
              .then((res) => {
                data.listaEnderecos = res;

                if (res.length === 0) {
                  throw new Error();
                } else {
                  vxm.loading.hideLoading();
                  data.errorMsg = '';
                }
              })
              .catch(() => {
                vxm.loading.hideLoading();
                data.errorMsg = 'Opa';
              });
          }
        },

        achaUF: (uf: string) => {
          const idEstado: string | number =
            vxm.cadastro.dadosIniciaisParaCadastro.estados.find(({ descricao }) => descricao === uf)?.id || '';
          return idEstado;
        },

        btnSaveCep_Click: (retornoEncontraCep: {
          CEP: string;
          Logradouro: string;
          Numero: string;
          Cidade: string;
          UF: string | number;
          Bairro: string;
        }) => {
          retornoEncontraCep.Numero = data.numero;
          retornoEncontraCep.UF = methods.achaUF(data.uf);

          if (props.isCobranca) {
            vxm.cadastro.atualizaEnderecoCobrancaApiInterna(retornoEncontraCep);
          } else if (props.isAdicional) {
            vxm.cadastro.atualizarEnderecoAdicionalAoBuscarEnderecoPorEndereco(retornoEncontraCep);
          } else {
            vxm.cadastro.atualizaEnderecoApiInterna(retornoEncontraCep);
            vxm.cadastro.desabilitaCampos(true);
          }

          vxm.cadastro.fecharModalCep();
        },
      });

      return {
        ...toRefs(data),
        ...toRefs(methods),
      };
    },
  });
