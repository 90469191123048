import { Campo } from '../../campos';

export interface PessoaCamposAdicionais {
  id: Campo<number>;
  nomeCampo: Campo<string>;
  ordemCampo: Campo<number>;
  tipoCampo: Campo<number>;
  ativo: Campo<boolean>;
  detalhes: Campo<Array<PessoaCamposAdicionaisDetalhes>>;
}

export interface PessoaCamposAdicionaisValores {
  id: Campo<number>;
  idCampo: Campo<number>;
  contentCampo: Campo<string>;
  idPessoa: Campo<number>;
}

export interface PessoaCamposAdicionaisDetalhes {
  id: Campo<number>;
  idCampo: Campo<number>;
  descricao: Campo<string>;
}

class PessoaCamposAdicionaisTypes {
  public static obterPessoaCamposAdicionais = (
    id: Campo<number>,
    nomeCampo: Campo<string>,
    ordemCampo: Campo<number>,
    tipoCampo: Campo<number>,
    ativo: Campo<boolean>,
    detalhes: Campo<Array<PessoaCamposAdicionaisDetalhes>>,
  ): PessoaCamposAdicionais => {
    return {
      id,
      nomeCampo,
      ordemCampo,
      tipoCampo,
      ativo,
      detalhes,
    } as PessoaCamposAdicionais;
  };

  public static obterPessoaCamposAdicionaisValores = (
    id: Campo<number>,
    idCampo: Campo<number>,
    contentCampo: Campo<string>,
    idPessoa: Campo<number>,
  ): PessoaCamposAdicionaisValores => {
    return {
      id,
      idCampo,
      contentCampo,
      idPessoa,
    } as PessoaCamposAdicionaisValores;
  };

  public static obterPessoaCamposAdicionaisDetalhes = (
    id: Campo<number>,
    idCampo: Campo<number>,
    descricao: Campo<string>,
  ): PessoaCamposAdicionaisDetalhes => {
    return {
      id,
      idCampo,
      descricao,
    } as PessoaCamposAdicionaisDetalhes;
  };
}

export default PessoaCamposAdicionaisTypes;
