
  import { defineComponent, onMounted } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  import { vxm } from '@/store/index';
  import { Login } from '@/core/types/autenticacao';

  import AlertsExtension from '@/utils/alerts';

  export default defineComponent({
    name: 'Home',
    setup() {
      const loginPorToken = (): Promise<void> => {
        const router = useRouter();
        const route = useRoute();

        vxm.loading.showLoading();

        const { authToken, appName, rotina, codigoCliente } = route.query;
        const idCliente = codigoCliente ? codigoCliente : (null as any);
        const origemName = appName ? appName : (null as any);
        const routeQueryParams = {
          appOrigem: appName,
          //Lyncas - Alterar Token para testar a aplicação localmente - MODAJOI-37214
          //token: '73da3a35-9c2f-4990-92fd-c0bcde2de50b',
          token: authToken,
          rotina,
          codigoCliente,
        } as Login;

        return vxm.autenticacao
          .loginPorToken(routeQueryParams)
          .then(() => {
            if (vxm.autenticacao.isAutenticado) router.push({ name: routeQueryParams.rotina, params: { idCliente, origemName } });
            else AlertsExtension.exibirMensagemErro('Não foi possível realizar a Autenticação integrada.', false);
            return Promise.resolve();
          })
          .catch((error) => {
            AlertsExtension.exibirMensagemErro(error, false);
          })
          .finally(() => {
            vxm.loading.hideLoading();
          });
      };

      onMounted(async () => {
        await loginPorToken();
      });

      return {};
    },
  });
