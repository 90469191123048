import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex",
  id: "container-principal"
}
const _hoisted_2 = {
  key: 0,
  class: "col-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Identificacao = _resolveComponent("Identificacao")
  const _component_Endereco = _resolveComponent("Endereco")
  const _component_EnderecoAdicional = _resolveComponent("EnderecoAdicional")
  const _component_EnderecoCobranca = _resolveComponent("EnderecoCobranca")
  const _component_Contatos = _resolveComponent("Contatos")
  const _component_ContatoAdicional = _resolveComponent("ContatoAdicional")
  const _component_Comercial = _resolveComponent("Comercial")
  const _component_DadosBancariosCadastroSelecao = _resolveComponent("DadosBancariosCadastroSelecao")
  const _component_DadosBancarios = _resolveComponent("DadosBancarios")
  const _component_Convenio = _resolveComponent("Convenio")
  const _component_DadosAdicionais = _resolveComponent("DadosAdicionais")
  const _component_CamposAdicionais = _resolveComponent("CamposAdicionais")
  const _component_CadastroBiometrico = _resolveComponent("CadastroBiometrico")
  const _component_Politica = _resolveComponent("Politica")
  const _component_Menu = _resolveComponent("Menu")

  return (_ctx.isShowSecoesCadastroClientes)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("div", {
          class: ["d-flex flex-column", !_ctx.rotaCadastroParcial ? 'col-9' : 'col-12'],
          id: "container-cadastro-clientes"
        }, [
          (_ctx.verificarVisibilidadeSecao(_ctx.idSecao.identificacao))
            ? (_openBlock(), _createBlock(_component_Identificacao, {
                key: 0,
                idSecaoMenu: _ctx.idSecao.identificacao,
                integraLinxIO: _ctx.integraLinxIO
              }, null, 8, ["idSecaoMenu", "integraLinxIO"]))
            : _createCommentVNode("", true),
          (_ctx.verificarVisibilidadeSecao(_ctx.idSecao.endereco))
            ? (_openBlock(), _createBlock(_component_Endereco, {
                key: 1,
                idSecaoMenu: _ctx.idSecao.endereco
              }, null, 8, ["idSecaoMenu"]))
            : _createCommentVNode("", true),
          (_ctx.verificarVisibilidadeSecao(_ctx.idSecao.enderecoAdicional, _ctx.isShowEnderecoAdicional))
            ? (_openBlock(), _createBlock(_component_EnderecoAdicional, {
                key: 2,
                idSecaoMenu: _ctx.idSecao.enderecoAdicional
              }, null, 8, ["idSecaoMenu"]))
            : _createCommentVNode("", true),
          (_ctx.verificarVisibilidadeSecao(_ctx.idSecao.enderecoCobranca, _ctx.cadastro.isEnderecoCobranca))
            ? (_openBlock(), _createBlock(_component_EnderecoCobranca, {
                key: 3,
                idSecaoMenu: _ctx.idSecao.enderecoCobranca
              }, null, 8, ["idSecaoMenu"]))
            : _createCommentVNode("", true),
          (_ctx.verificarVisibilidadeSecao(_ctx.idSecao.contatos))
            ? (_openBlock(), _createBlock(_component_Contatos, {
                key: 4,
                idSecaoMenu: _ctx.idSecao.contatos,
                integraLinxIO: _ctx.integraLinxIO
              }, null, 8, ["idSecaoMenu", "integraLinxIO"]))
            : _createCommentVNode("", true),
          (_ctx.verificarVisibilidadeSecao(_ctx.idSecao.contatoAdicional, _ctx.isShowContatoAdicional))
            ? (_openBlock(), _createBlock(_component_ContatoAdicional, {
                key: 5,
                idSecaoMenu: _ctx.idSecao.contatoAdicional
              }, null, 8, ["idSecaoMenu"]))
            : _createCommentVNode("", true),
          (_ctx.verificarVisibilidadeSecao(_ctx.idSecao.comercial))
            ? (_openBlock(), _createBlock(_component_Comercial, {
                key: 6,
                idSecaoMenu: _ctx.idSecao.comercial
              }, null, 8, ["idSecaoMenu"]))
            : _createCommentVNode("", true),
          (_ctx.verificarVisibilidadeSecao(_ctx.idSecao.dadosBancarios, !!_ctx.idCliente))
            ? (_openBlock(), _createBlock(_component_DadosBancariosCadastroSelecao, { key: 7 }))
            : _createCommentVNode("", true),
          (_ctx.verificarVisibilidadeSecao(_ctx.idSecao.dadosBancarios, _ctx.isShowDadosBancarios))
            ? (_openBlock(), _createBlock(_component_DadosBancarios, {
                key: 8,
                idSecaoMenu: _ctx.idSecao.dadosBancarios,
                ehCadastroParcial: !!_ctx.rotaCadastroParcial,
                onExcluirCadastroParcial: _cache[1] || (_cache[1] = ($event: any) => (_ctx.excluirCadastroParcial(_ctx.idSecao.dadosBancarios))),
                onSalvarPessoa: _cache[2] || (_cache[2] = ($event: any) => (_ctx.salvarPessoa(_ctx.idSecao.dadosBancarios)))
              }, null, 8, ["idSecaoMenu", "ehCadastroParcial"]))
            : _createCommentVNode("", true),
          (_ctx.verificarVisibilidadeSecao(_ctx.idSecao.convenio, _ctx.isShowSecaoConvenio))
            ? (_openBlock(), _createBlock(_component_Convenio, {
                key: 9,
                idSecaoMenu: _ctx.idSecao.convenio
              }, null, 8, ["idSecaoMenu"]))
            : _createCommentVNode("", true),
          (_ctx.verificarVisibilidadeSecao(_ctx.idSecao.adicionais))
            ? (_openBlock(), _createBlock(_component_DadosAdicionais, {
                key: 10,
                idSecaoMenu: _ctx.idSecao.adicionais
              }, null, 8, ["idSecaoMenu"]))
            : _createCommentVNode("", true),
          (_ctx.verificarVisibilidadeSecao(_ctx.idSecao.camposAdicionais, _ctx.isShowCamposAdicionais))
            ? (_openBlock(), _createBlock(_component_CamposAdicionais, {
                key: 11,
                idSecaoMenu: _ctx.idSecao.camposAdicionais
              }, null, 8, ["idSecaoMenu"]))
            : _createCommentVNode("", true),
          (_ctx.verificarVisibilidadeSecao(_ctx.idSecao.cadastroBiometrico, _ctx.isShowCadastroBiometrico))
            ? (_openBlock(), _createBlock(_component_CadastroBiometrico, {
                key: 12,
                idSecaoMenu: _ctx.idSecao.cadastroBiometrico
              }, null, 8, ["idSecaoMenu"]))
            : _createCommentVNode("", true),
          (_ctx.verificarVisibilidadeSecao(_ctx.idSecao.politica))
            ? (_openBlock(), _createBlock(_component_Politica, {
                key: 13,
                idSecaoMenu: _ctx.idSecao.politica
              }, null, 8, ["idSecaoMenu"]))
            : _createCommentVNode("", true)
        ], 2),
        (!_ctx.rotaCadastroParcial)
          ? (_openBlock(), _createBlock("div", _hoisted_2, [
              _createVNode(_component_Menu, {
                secoes: _ctx.listaSecoesMenu,
                onSalvarPessoa: _cache[3] || (_cache[3] = ($event: any) => (_ctx.salvarPessoa())),
                bloqueioIntegracaoSalesForce: _ctx.bloqueioIntegracaoSalesForce
              }, null, 8, ["secoes", "bloqueioIntegracaoSalesForce"])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}