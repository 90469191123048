
  import { defineComponent } from 'vue';
  import { vxm } from '@/store/index';

  export default defineComponent({
    name: 'SecaoPoliticaCadastro',
    components: {},
    props: {
      idSecaoMenu: {
        type: String,
        default: '',
        required: true,
      },
    },
    setup() {
      const mensagemPadraoParaPoliticaDeDados =
        'O texto para informar ao cliente sobre a política de dados da empresa para atender à LGPD pode ser cadastrado em Empresa > Dados da Empresa.';
      const mensagemParaPoliticaDeDados =
        vxm.cadastro.dadosIniciaisParaCadastro.politicaDeDados || mensagemPadraoParaPoliticaDeDados;
      return {
        ...vxm,
        mensagemParaPoliticaDeDados,
      };
    },
  });
