
/* eslint-disable */
  import { computed, defineComponent, PropType, reactive } from 'vue';
  import { useRouter, useRoute } from 'vue-router';

  import MenuNavegacao from '@/components/shared/MenuNavegacao.vue';

  import UploadImagem from '@/components/shared/UploadImagem.vue';

  import Webcam from '@/components/shared/Webcam.vue';

  import { vxm } from '@/store/index';

  import { Secao } from '@/core/types/menu';
  import AlertsExtension from '@/utils/alerts';
  import { FileWithPath } from 'file-selector';

  export default defineComponent({
    name: 'MenuCadastroClientes',
    components: {
      MenuNavegacao,
      UploadImagem,
      Webcam,
    },
    props: {
      secoes: {
        type: Array as PropType<Array<Secao>>,
        default: () => [],
        required: false,
      },
      bloqueioIntegracaoSalesForce: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    setup() {
      const router = useRouter();
      const route = useRoute();
      const textoButton = computed(() => (vxm.cadastro.isNovoCadastroPessoa ? 'CADASTRAR' : 'ATUALIZAR'));
      const origemName = String(route.params.origemName)
      const cancelarCadastroAtualizacao = (): void => {
        AlertsExtension.exibirMensagemPergunta('Cancelar', 'Tem certeza que deseja cancelar?', (result) => {
          if (result) {
            vxm.cadastro.fotoCadastro = [];
            vxm.cadastro.possuiFoto = false;
            vxm.cadastro.cameraAberta = false;
            
            if(vxm.autenticacao.isRotaPos)
            {
              try {
                  //console.log(vxm.autenticacao.isRotaPos)
                    // @ts-ignore
                    dotNetMessage.show(JSON.stringify({Sucesso: true,AcaoRealizar: 'FecharCadastroCrm'}));
                  } 
                  catch (error){
                    console.log('Ocorreu um erro:' + error);
                  }
            }
            else router.push({ name: 'ListagemClientes' });
          }
        });
      };

      const setFotoCliente = (fotoCliente: Array<FileWithPath>): void => {
        const fotoEnviada = fotoCliente.pop();
        vxm.cadastro.fotoNovaParaInserir = fotoEnviada;
        vxm.cadastro.fotoNovaParaExibir = URL.createObjectURL(vxm.cadastro.fotoNovaParaInserir);
      };

      const removerFotoCliente = (): void => {
        vxm.cadastro.fotoNovaParaInserir = undefined;
        vxm.cadastro.fotoNovaParaExibir = undefined;
      };

      return {
        textoButton,
        cancelarCadastroAtualizacao,
        setFotoCliente,
        removerFotoCliente,
      };
    },
  });
