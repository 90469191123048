//MODAJOI-37214 (US 001)
import { ObterDadosFreteTiposResult } from "@/api/contracts/es/notaSimplificada";
import CamposTypes from "../types/campos";
import NotaSimplificadaTypes, { DadosFreteTipos, FreteTipos } from "../types/es/notaSimplificada";

const { obterCampoMultiselect } = CamposTypes;
const { obterFreteTipos } = NotaSimplificadaTypes;

class NotaSimplificadaMapping {

    public static obterDadosFreteTiposMapping = (obterDadosFreteTiposResult: Array<ObterDadosFreteTiposResult>): DadosFreteTipos => {
        const freteTipos: Array<FreteTipos> = [];

        obterDadosFreteTiposResult.forEach(({Id, Descricao }) => freteTipos.push(obterCampoMultiselect(Id, `${Descricao} (${Id})`)));
        return obterFreteTipos(freteTipos) ;
    };
}

export default NotaSimplificadaMapping;