import Vuex from 'vuex';
import { extractVuexModule, createProxy } from 'vuex-class-component';

import VuexPersistence from 'vuex-persist';

import CadastroClientesModule from './modules/clientes/cadastro';
import ImportacaoClientesModule from './modules/clientes/importacao';
import ListagemClientesModule from './modules/clientes/listagem';

import ParametrosModule from './modules/parametros';
import LoadingModule from './modules/loading';
import AutenticacaoModule from './modules/autenticacao';
import PermissoesModule from './modules/clientes/permissao';
import NotaSimplificadaModule from './modules/es/notaSimplificada'; //MODAJOI-37214 (US 001)
import ParametrosCommerceModule from './modules/es/parametrosCommerce';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: 'crm-store',
  modules: ['autenticacao'],
});

export const store = new Vuex.Store({
  modules: {
    ...extractVuexModule(CadastroClientesModule),
    ...extractVuexModule(ParametrosModule),
    ...extractVuexModule(LoadingModule),
    ...extractVuexModule(ImportacaoClientesModule),
    ...extractVuexModule(AutenticacaoModule),
    ...extractVuexModule(ListagemClientesModule),
    ...extractVuexModule(PermissoesModule),
    ...extractVuexModule(NotaSimplificadaModule), //MODAJOI-37214 (US 001)
    ...extractVuexModule(ParametrosCommerceModule),
  },
  plugins: [vuexLocal.plugin],
});

export const vxm = {
  cadastro: createProxy(store, CadastroClientesModule),
  importacao: createProxy(store, ImportacaoClientesModule),
  parametros: createProxy(store, ParametrosModule),
  loading: createProxy(store, LoadingModule),
  autenticacao: createProxy(store, AutenticacaoModule),
  listagem: createProxy(store, ListagemClientesModule),
  permissao: createProxy(store, PermissoesModule),
  notaSimplificada: createProxy(store, NotaSimplificadaModule), //MODAJOI-37214 (US 001)
  parametrosCommerce: createProxy(store, ParametrosCommerceModule),
};
