import { createModule, mutation, action } from 'vuex-class-component';

import CrmClient from '@/api/clients/crm';
import ListagemTypes, { ListaClientes, DadosIniciais, Filtros, TiposCamposAdicionais } from '@/core/types/clientes/listagem';

const VuexModule = createModule({
  namespaced: 'listagem',
  strict: false,
});
const { filtrosPesquisaAvancada } = ListagemTypes;
export default class ListagemClientesModule extends VuexModule {
  dadosIniciaisListagem = ListagemTypes.obterDadosIniciaisListagem();
  listagemClientesAtualizados = ListagemTypes.listagemUltimosClientesAtualizados();
  filtrosPesquisaAvancada = ListagemTypes.obterFiltrosPesquisaAvancada();
  filtros = filtrosPesquisaAvancada();
  tiposCamposAdicionais = TiposCamposAdicionais;
  anonimizado = false;

  @mutation
  obterEstadoPadrao(): void {
    this.dadosIniciaisListagem = ListagemTypes.obterDadosIniciaisListagem();
    this.listagemClientesAtualizados = ListagemTypes.listagemUltimosClientesAtualizados();
  }
  @mutation
  limparFiltrosPesquisa(): void {
    this.filtros.CamposAdicionais = null;
    this.filtros = filtrosPesquisaAvancada();
  }
  @action
  async obterDadosIniciaisListagem(): Promise<DadosIniciais> {
    return CrmClient.obterDadosIniciaisListagemCliente().then((dodosIniciaisResult) => {
      return dodosIniciaisResult;
    });
  }
  @action
  async obterListagemUltimosClientesAtualizados(): Promise<ListaClientes> {
    return CrmClient.obterDadosListagemCliente().then((dadosIniciaisResult) => {
      return dadosIniciaisResult.UltimasPessoasAtualizadas;
    });
  }
  @action
  async listarFiltrosPesquisa(): Promise<Filtros> {
    return CrmClient.obterfiltrosPesquisaAvancada().then((filtrosResult) => {
      return filtrosResult.Filtros;
    });
  }
  @action
  async listaOpcoesDoFiltro(payload: any): Promise<[]> {
    return CrmClient.listarOpcoesDeFiltro(payload).then((response) => {
      return response;
    });
  }
  @action
  async pesquisarClientes(payload: any): Promise<[]> {
    return CrmClient.pesquisarClientes(payload).then((response) => {
      return response;
    });
  }
  @action
  async anonimizarPessoa(payload: any): Promise<void> {
    return CrmClient.anonimizarPessoa(payload).then((response) => {
      return response;
    });
  }
  @action
  async obterFoto(idPessoa: number): Promise<Blob> {
    return CrmClient.obterFotoPessoa(idPessoa);
  }
  
}
