import { ObterParametrosCommerceResult } from "@/api/contracts/es/parametrosCommerce";
import ParametrosCommerceTypes, { } from "../types/es/parametrosCommerce";

const { obterParametrosCommerce } = ParametrosCommerceTypes;

class ParametrosCommerceMapping {
  public static obterParametrosCommerceMapping = (ObterParametrosCommerceResult: boolean): boolean => {
    return obterParametrosCommerce(ObterParametrosCommerceResult);
  };
}

export default ParametrosCommerceMapping;
