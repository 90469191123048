
  import { defineComponent } from 'vue';

  import VueFormMultiselect from '@vueform/multiselect';

  export default defineComponent({
    name: 'Multiselect',
    components: {
      VueFormMultiselect,
    },
    setup() {
      const multipleLabel = (value: Array<any>) => {
        if (value.length === 1) return '1 opção selecionada';
        else return `${value.length} opções selecionadas`;
      };

      return {
        multipleLabel,
      };
    },
  });
