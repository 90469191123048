import CampoTypes, { Campo } from '../campos';
const { obterCampo } = CampoTypes;

export interface ParametrosCrmGravacao {
  tipoClienteBaseCentral: Campo<string>;
  atualizarBaseCentralClientesPor: Campo<string>;
  cadastroClientesCentral: Campo<boolean>;
  exigeClasse: Campo<boolean>;
  exigeEmail: Campo<boolean>;
  exigeDataNascimento: Campo<boolean>;
  exigeSexo: Campo<boolean>;
  exigeTelefone: Campo<boolean>;
  exigeCelular: Campo<boolean>;
  exigeUf: Campo<boolean>;
  exigeDocProspect: Campo<boolean>;
  exigeTelefoneProspect: Campo<boolean>;
  exigeEmailProspect: Campo<boolean>;
  portalFranqueador: Campo<number>;
  cadastroBiometricoCliente: Campo<boolean>;
}

class ParametrosCrmGravacaoTypes {
  public static obterParametrosCrmGravacao = (
    tipoClienteBaseCentral: Campo<string>,
    atualizarBaseCentralClientesPor: Campo<string>,
    cadastroClientesCentral: Campo<boolean>,
    exigeClasse: Campo<boolean>,
    exigeEmail: Campo<boolean>,
    exigeDataNascimento: Campo<boolean>,
    exigeSexo: Campo<boolean>,
    exigeTelefone: Campo<boolean>,
    exigeCelular: Campo<boolean>,
    exigeUf: Campo<boolean>,
    exigeDocProspect: Campo<boolean>,
    exigeTelefoneProspect: Campo<boolean>,
    exigeEmailProspect: Campo<boolean>,
    portalFranqueador: Campo<number>,
    cadastroBiometricoCliente: Campo<boolean>,
  ): ParametrosCrmGravacao => {
    return {
      tipoClienteBaseCentral,
      atualizarBaseCentralClientesPor,
      cadastroClientesCentral,
      exigeClasse,
      exigeEmail,
      exigeDataNascimento,
      exigeSexo,
      exigeTelefone,
      exigeCelular,
      exigeUf,
      exigeDocProspect,
      exigeTelefoneProspect,
      exigeEmailProspect,
      portalFranqueador,
      cadastroBiometricoCliente,
    } as ParametrosCrmGravacao;
  };

  public static obterNovoParametrosCrmGravacao = (): ParametrosCrmGravacao => {
    return {
      tipoClienteBaseCentral: obterCampo(''),
      atualizarBaseCentralClientesPor: obterCampo(''),
      cadastroClientesCentral: obterCampo(true),
      exigeClasse: obterCampo(true),
      exigeEmail: obterCampo(true),
      exigeDataNascimento: obterCampo(true),
      exigeSexo: obterCampo(true),
      exigeTelefone: obterCampo(true),
      exigeCelular: obterCampo(true),
      exigeUf: obterCampo(true),
      exigeDocProspect: obterCampo(true),
      exigeTelefoneProspect: obterCampo(true),
      exigeEmailProspect: obterCampo(true),
      portalFranqueador: obterCampo(0),
      cadastroBiometricoCliente: obterCampo(true),
    } as ParametrosCrmGravacao;
  };
}

export default ParametrosCrmGravacaoTypes;
