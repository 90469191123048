/* eslint-disable */
import { createModule, mutation, action } from 'vuex-class-component';
import CrmClient from '@/api/clients/crm';
import { ObterPermissaoPorIdDTO, RetornoObterPermissaoPorIdDTO } from '@/api/contracts/buscarPermissao';
import { vxm } from '@/store';
const VuexModule = createModule({
  namespaced: 'permissao',
  strict: false,
});

export default class PermissoesModule extends VuexModule {
  //#region State

  //#endregion
  //#region Mutation

  @mutation
  setPermissaoTabelaDePreco(retorno: boolean) {
    vxm.cadastro.tabelaDePrecoDesabilitada = !retorno;
  }

  @mutation
  setPermissaoTabelaDeClasseSubClasse(retorno: boolean) {
    vxm.cadastro.tabelaDeClasseSubClasseDesabilitada = !retorno;
  }

  //#endregion
  //#region Action
  @action
  async ativarPermissao(permissao: ObterPermissaoPorIdDTO): Promise<void> {
    if (permissao.nomePermissao == 'Tabela de Preço') {
      return await CrmClient.atualizarPermissao(permissao).then((result) => {
        this.setPermissaoTabelaDePreco(result);
        vxm.cadastro.abrirModalTabelaDePreco();
      });
    }
    if (permissao.nomePermissao == 'Tabela de Classe/Subclasse') {
      return await CrmClient.atualizarPermissao(permissao).then((result) => {
        this.setPermissaoTabelaDeClasseSubClasse(result);
        vxm.cadastro.fecharModalTabelaDePreco();
      });
    }
  }
  //#endregion
}
