//MODAJOI-37214 (US 001)
import ApiConfig from '@/api/utils/apiConfig';

class EsRoutes {
  private getUrlBase = () => ApiConfig.getUrlEsApi();
  //private getUrlBase = () => 'http://localhost:23909';

  public notaSimplificada = {
    obterListagemFreteTipos: () => `${this.getUrlBase()}/api/NotaSimplificada/ObterListagemFreteTipos`,
  };

  public obterParametrosCommerce = {
    obterParametrosCommerce: () => `${this.getUrlBase()}/api/PainelVendasB2C/ObterParametrosCommerce`,
  };
}

export default new EsRoutes();
