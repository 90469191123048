
  import { computed, defineComponent, onBeforeUpdate, onMounted, reactive, ref, toRefs, watch, watchEffect } from 'vue';

  import { vxm } from '@/store/index';

  import Campo from '@/components/shared/Campo.vue';
  import Input from '@/components/shared/Input.vue';
  import Multiselect from '@/components/shared/Multiselect.vue';
  import BaseModal from '@/components/shared/BaseModal.vue';
  import EncontraCEP from '@/components/clientes/cadastro/EncontraCEP.vue';

  import FiltrosExtension from '@/utils/filtros';
  import AlertsExtension from '@/utils/alerts';

  import PessoaModel from '@/core/models/pessoa';
  import { Estado, Pais } from '@/core/types/clientes/cadastro';
  import CamposTypes from '@/core/types/campos';
  import { ApiError } from '@/api/utils/handler';
  import PessoaEnderecoTypes from '@/core/types/clientes/pessoa/endereco';

  export default defineComponent({
    name: 'SecaoEnderecoCobrancaCadastro',
    components: {
      Campo,
      Input,
      Multiselect,
      BaseModal,
      EncontraCEP,
    },
    props: {
      idSecaoMenu: {
        type: String,
        default: '',
        required: true,
      },
    },
    setup() {
      //TODO: Componente está duplicando funções do componente Endereço, verificar uma forma de simplificar a implementação.

      const ultimoCepEnviadoViaCep = ref('');
      const isCampoCidadeDisabled = ref(false);
      const isCampoEstadoDisabled = ref(false);
      const isCampoPaisDisabled = ref(false);
      const faltaInserirCEPSecundario = ref(true);
      const idBrasilMultiselect = ref(0);
      const multiselectPaises = ref([] as Array<Pais>);
      const multiselectEstados = ref([] as Array<Estado>);
      const ultimoEstadoSelecionado = ref(0);
      const ultimoPaisSelecionado = ref(0);
      const isEnderecoCobrancaInternacional = ref(false);

      const { validarCep, isPessoaFisica } = PessoaModel;

      const isNovoCep = computed(() => ultimoCepEnviadoViaCep.value !== vxm.cadastro.pessoa.enderecoCobranca.cep.valor);

      const obterDadosEnderecoViaCep = () => {
        const isCepValido = validarCep(vxm.cadastro.pessoa.enderecoCobranca.cep);

        if (vxm.cadastro.pessoa.enderecoCobranca.cep.valor && isCepValido && isNovoCep.value) {
          vxm.loading.showLoading('Buscando dados do endereço ...');

          vxm.cadastro
            .obterDadosEnderecoViaCep(vxm.cadastro.pessoa.enderecoCobranca.cep.valor)
            .then((enderecoCobrancaCliente) => {
              vxm.cadastro.pessoa.enderecoCobranca = enderecoCobrancaCliente;
              ultimoCepEnviadoViaCep.value = enderecoCobrancaCliente.cep.valor;
              faltaInserirCEPSecundario.value = false;

              if (enderecoCobrancaCliente.estado.valor && enderecoCobrancaCliente.cidade.valor) {
                isCampoCidadeDisabled.value = true;
                isCampoEstadoDisabled.value = true;
                // vxm.cadastro.desabilitaCidadeEstado(true);
              } else {
                isCampoCidadeDisabled.value = false;
                isCampoEstadoDisabled.value = false;
                // vxm.cadastro.desabilitaCidadeEstado(false);
              }
            })
            .catch((error) => {
              if (error instanceof ApiError && error.status === 404) {
                const idEstado =
                  vxm.cadastro.dadosIniciaisParaCadastro.estados.find(
                    ({ descricao }) => descricao === vxm.parametros.parametrosCrm.estadoEmpresa,
                  )?.id || '';
                faltaInserirCEPSecundario.value = false;
                ultimoCepEnviadoViaCep.value = vxm.cadastro.pessoa.enderecoCobranca.cep.valor;
                isCampoCidadeDisabled.value = false;
                isCampoEstadoDisabled.value = false;
                // vxm.cadastro.desabilitaCidadeEstado(false);
                vxm.cadastro.pessoa.enderecoCobranca = PessoaEnderecoTypes.obterNovoEndereco(
                  vxm.cadastro.pessoa.enderecoCobranca.cep.valor,
                );
                vxm.cadastro.pessoa.enderecoCobranca.estado.valor = idEstado;
                AlertsExtension.exibirMensagemAlerta(error.title, true);
              } else AlertsExtension.exibirMensagemErro(error, false);
            })
            .finally(() => {
              vxm.loading.hideLoading();
            });
        }
      };

      const handleCampoPais = () => {
        if (idBrasilMultiselect.value === 0) {
          const idBrasil = vxm.cadastro.dadosIniciaisParaCadastro.paises.find(({ descricao }) => {
            return descricao === 'BRASIL';
          })?.id;
          if (idBrasil) {
            idBrasilMultiselect.value = idBrasil;
            if (!vxm.cadastro.pessoa.isClienteInternacional.valor) isEnderecoCobrancaInternacional.value = false;
            else
              isEnderecoCobrancaInternacional.value =
                vxm.cadastro.pessoa.enderecoCobranca.pais.valor !== idBrasilMultiselect.value;
          }
        }

        if (isEnderecoCobrancaInternacional.value) {
          if (vxm.cadastro.pessoa.enderecoCobranca.pais.valor !== 30)
            ultimoPaisSelecionado.value = Number(vxm.cadastro.pessoa.enderecoCobranca.pais.valor);

          isCampoPaisDisabled.value = false;
          // vxm.cadastro.desabilitaPais(false);
          vxm.cadastro.pessoa.enderecoCobranca.pais.valor = ultimoPaisSelecionado.value || '';
          handleMultiselectPais();
        } else {
          multiselectPaises.value = vxm.cadastro.dadosIniciaisParaCadastro.paises;
          vxm.cadastro.pessoa.enderecoCobranca.pais.valor = idBrasilMultiselect.value;
          isCampoPaisDisabled.value = true;
          // vxm.cadastro.desabilitaPais(true);
        }
      };

      const handleCampoEstado = () => {
        if (vxm.cadastro.pessoa.enderecoCobranca.estado.valor !== 9999)
          ultimoEstadoSelecionado.value = Number(vxm.cadastro.pessoa.enderecoCobranca.estado.valor);

        if (isEnderecoCobrancaInternacional.value) {
          handleMultiselectEstado();
          vxm.cadastro.pessoa.enderecoCobranca.estado.valor = 9999;
          isCampoEstadoDisabled.value = true;
          // vxm.cadastro.desabilitaEstado(true);
        } else {
          vxm.cadastro.pessoa.enderecoCobranca.estado.valor = ultimoEstadoSelecionado.value || '';
          if (!vxm.cadastro.pessoa.enderecoCobranca.estado.valor) isCampoEstadoDisabled.value = false; //vxm.cadastro.desabilitaEstado(false);
          multiselectEstados.value = vxm.cadastro.dadosIniciaisParaCadastro.estados;
        }
      };

      const handleMultiselectEstado = () => {
        multiselectEstados.value = [
          CamposTypes.obterCampoMultiselect(9999, 'EX', true),
          ...vxm.cadastro.dadosIniciaisParaCadastro.estados,
        ];
      };

      const handleMultiselectPais = () => {
        multiselectPaises.value = vxm.cadastro.dadosIniciaisParaCadastro.paises.filter(({ descricao }) => descricao !== 'BRASIL');
      };

      const setaDescricaoPais = (idPais: number) => {
        const paisSelecionado = vxm.cadastro.dadosIniciaisParaCadastro.paises.find(({ id }) => idPais === id);
        if (paisSelecionado) vxm.cadastro.pessoa.enderecoCobranca.pais.descricao = paisSelecionado.sigla;
      };

      const setaDescricaoEstado = (idEstado: number) => {
        const estadoSelecionado = vxm.cadastro.dadosIniciaisParaCadastro.estados.find(({ id }) => idEstado === id);
        if (estadoSelecionado) vxm.cadastro.pessoa.enderecoCobranca.estado.descricao = estadoSelecionado.descricao;
      };

      const methods = reactive({
        btnAbreModalEncontraCepCobranca: () => {
          vxm.cadastro.abrirModalCepCobranca();
        },
        fecharModalEncontraCep: () => {
          vxm.cadastro.fecharModalCep();
        },
      });

      watchEffect(() => {
        handleCampoPais();
        handleCampoEstado();
        if (vxm.cadastro.pessoa.enderecoCobranca.logradouro.valor) faltaInserirCEPSecundario.value = false;
      });

      return {
        ...toRefs(methods),
        ...vxm,
        FiltrosExtension,
        PessoaModel,
        obterDadosEnderecoViaCep,
        isCampoCidadeDisabled,
        isCampoEstadoDisabled,
        isCampoPaisDisabled,
        multiselectPaises,
        multiselectEstados,
        setaDescricaoPais,
        setaDescricaoEstado,
        isEnderecoCobrancaInternacional,
        faltaInserirCEPSecundario,
      };
    },
  });
