
  import { defineComponent } from 'vue';
  import { vxm } from '@/store/index';

  import Campo from '@/components/shared/Campo.vue';
  import Input from '@/components/shared/Input.vue';
  import Multiselect from '@/components/shared/Multiselect.vue';

  export default defineComponent({
    name: 'CadastroFornecedor',
    components: {
      Campo,
      Input,
      Multiselect,
    },
    setup() {
      const atualizaDiferencialIcms = (pagaDiferencialIcms: any) => {
        if (!pagaDiferencialIcms.target.checked) vxm.cadastro.pessoa.fornecedor.diferencialIcms.valor = '';
      };
      const lancaIcmsIpiEntradaDevolucaoCompra = () => {
        if (vxm.parametros.parametrosCrm.sistemaTributacao !== 'S' && vxm.cadastro.isNovoCadastroPessoa)
          vxm.cadastro.pessoa.fornecedor.isLancaIcmsIpiEntradaDevolucaoCompras.valor = true;
      };
      lancaIcmsIpiEntradaDevolucaoCompra();
      return { ...vxm, atualizaDiferencialIcms };
    },
  });
