import ApiConfig from '@/api/utils/apiConfig';

class ErpAdminRoutes {
  readonly urlBase = ApiConfig.getUrlErpAdminApi();

  public autenticacao = {
    loginPorToken: `${this.urlBase}/api/Autenticacao/LoginPorToken`,
    selecionarEmpresa: `${this.urlBase}/api/Autenticacao/SelecionarEmpresa`,
  };
}

export default new ErpAdminRoutes();
