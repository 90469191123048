
  import { defineComponent, toRefs, reactive, inject, onMounted } from 'vue';
  import { vxm } from '@/store/index';

  import AlertsExtension from '../../../utils/alerts';
  import download from '../../../utils/download';
  import BaseModal from '../../shared/BaseModal.vue';
  import Campo from '@/components/shared/Campo.vue';
  import Input from '@/components/shared/Input.vue';

  export default defineComponent({
    name: 'ModalConfiguracoes',
    components: { BaseModal, Campo, Input },
    setup(props, context) {
      const data = reactive({
        nomeArquivo: '',
      });

      let arquivo: any = '';

      const emitter: any = inject('emitter');

      const atualizar = () => {
        emitter.emit('atualizar');
      };

      const methods = reactive({
        isEmptyObject(obj: any) {
          return JSON.stringify(obj) === '{}';
        },

        downloadArquivoModelo() {
          vxm.loading.showLoading();
          vxm.importacao
            .downloadArquivoModelo()
            .then((file) => {
              if (file) {
                download.downloadFile(file);
              }
            })
            .catch((error) => {
              AlertsExtension.exibirMensagemErro(error, false);
            })
            .finally(() => {
              vxm.loading.hideLoading();
            });
        },

        importarClientesPorArquivo() {
          if (arquivo.length == 0) {
            (document.getElementById('erro') as HTMLFormElement).textContent = 'Arquivo é um campo obrigatório';
          } else {
            vxm.loading.showLoading();
            let command = { ArquivoPreenchido: arquivo, NomeArquivo: data.nomeArquivo };

            this.closeModal();
            vxm.importacao
              .importarClientePorArquivo(command)
              .then(() => {
                AlertsExtension.exibirMensagemSucesso(
                  'Legal! A importação foi iniciada com sucesso. Acompanhe em tempo real o status no painel de importações.',
                  false,
                );
                atualizar();
                this.closeModal();
              })
              .catch((error) => {
                AlertsExtension.exibirMensagemErro(error, false);
              })
              .finally(() => {
                vxm.loading.hideLoading();
              });
          }
        },

        salvarModal: () => {
          // context.emit('update:atualizarListagem', true);
          //context.emit('update:is-open', false);
          salvarConfiguracoes();
        },

        closeModal: () => {
          // context.emit('update:atualizarListagem', true);
          context.emit('update:is-open', false);
        },
      });

      // TODO: Essa função poderia estar na pasta utils -> arquivo download.ts. Dessa forma ela pode ser reutilizada na aplicação.
      /*function getBase64(file: any) {
      }*/

      //TODO: Todas as funções poderiam ser declaradas como essa daqui. Sem a necessidade de usar "function" na declaração ou utilizar "const methods = reactive".
      const obterDadosIniciaisConfiguracoes = async () => {
        try {
          vxm.loading.showLoading('Buscando informações para configurações ...');
          await vxm.parametros.obterParametrosCrm();
          await vxm.parametros.obterParametrosEmpresaCrm();
        } catch (error: any) {
          AlertsExtension.exibirMensagemErro(error, false);
        } finally {
          vxm.loading.hideLoading();
        }
      };

      const salvarConfiguracoes = async () => {
        vxm.loading.showLoading('Salvando configurações ...');

        vxm.parametros
          .salvarParametrosCrm(vxm.parametros.parametrosCrmAtualizacao)
          .then(async () => {
            //this.closeModal();
            context.emit('update:is-open', false);
          })
          .catch((error) => {
            console.log(error);
            AlertsExtension.exibirMensagemErro(error, false);
          })
          .finally(() => {
            vxm.loading.hideLoading();
            //this.closeModal();
          });
      };

      //TODO: A função setup aceita receber o prefixo async, logo é possível realizar a chamada dessa forma.
      onMounted(async () => {
        await obterDadosIniciaisConfiguracoes();
      });

      return {
        ...vxm,
        ...toRefs(methods),
      };
    },
  });
