
  import { defineComponent } from 'vue';
  import { vxm } from '@/store/index';
  import { TipoChavePix } from '@/core/enums/cadastro';

  import DadosBancariosFormularioConta from '@/components/clientes/cadastro/comercial/dadosBancarios/DadosBancariosFormularioConta.vue'
  import DadosBancariosFormularioPixTipos from '@/components/clientes/cadastro/comercial/dadosBancarios/DadosBancariosFormularioPixTipos.vue';

  export default defineComponent({
    name: 'DadosBancariosFormularioPix',
    components: {
      DadosBancariosFormularioConta,
      DadosBancariosFormularioPixTipos,
    },
    props: {
      tipoChavePix: {
        type: Number as () => number | null,
        default: null,
      },
    },
    setup() {
      return {
        TipoChavePix,
        ...vxm,
      };
    },
  });
