
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'Badge',
    props: {
      texto: {
        type: String,
        required: true,
      },
      tooltip: {
        type: String,
      },
    },
    setup() {
      return {};
    },
  });
