
  import { defineComponent, reactive, toRefs, onMounted, computed, ref, onUpdated, inject } from 'vue';

  import ModalVisualizarErros from './ModalVisualizarErros.vue';

  import FiltrosExtension from '@/utils/filtros';
  import download from '../../../utils/download';
  import AlertsExtension from '../../../utils/alerts';
  import { vxm } from '@/store/index';
  import * as signalR from '@microsoft/signalr';

  import ConnectHubRoutes from '@/api/routes/connectHub';

  export default defineComponent({
    name: 'TabelaImportacao',
    props: { searchQuery: { type: String } },
    components: { ModalVisualizarErros },

    setup(props, context) {
      const emitter: any = inject('emitter');

      const data = reactive({
        disabled: true,
        hubConnection: null,
      });
      const isModalOpen = ref(false);

      onMounted(() => {
        emitter.on('atualizar', () => {
          methods.obterDadosIniciaisPainelImportacao();
        });
        methods.obterDadosIniciaisPainelImportacao();

        setUpSignalRConnection();
      });
      const habilitarButtons = computed(() => {
        return (
          vxm.importacao.importacaoSelecionada &&
          vxm.importacao.importacaoSelecionada.QtdeRegistrosInconsistentes > 0 &&
          vxm.importacao.importacaoSelecionada.IdSituacaoImportacao === 3
        );
      });
      const habilitarCancelarImportacao = computed(() => {
        return vxm.importacao.importacaoSelecionada && vxm.importacao.importacaoSelecionada.IdSituacaoImportacao === 1;
      });

      const importacoesFiltradas = computed(() => {
        if (props.searchQuery === undefined) return vxm.importacao.listaDeImportacoes;
        else {
          let searchQuery = props.searchQuery;
          return vxm.importacao.listaDeImportacoes.filter(function (importacao) {
            if (
              Object.keys(importacao)
                .map(function (prop) {
                  if (prop == 'NomeArquivo') return importacao[prop];
                  if (prop == 'DataCriacao') return methods.formatarDataHora(importacao[prop]);
                  if (prop == 'IdSituacaoImportacao') return obterDescricaoSituacao(importacao[prop]);
                })
                .toString()
                .toLowerCase()
                .includes(searchQuery.toLowerCase().trim())
            ) {
              return importacao;
            }
          });
        }
      });
      const setUpSignalRConnection = async () => {
        const loginToken = localStorage.getItem('token_api');
        const connection = new signalR.HubConnectionBuilder()
          .withAutomaticReconnect()
          .withUrl(`${ConnectHubRoutes.importacao.notificarStatusProcessamentoImportacaoClientesFornecedores()}`, {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets,
          })
          .build();

        connection.serverTimeoutInMilliseconds = 120000;

        connection.on('atualizarStatusImportacao', vxm.importacao.atualizarStatusImportacao);

        try {
          await connection.start();
        } catch (err) {
          console.log(err);
        }

        if (connection.state === signalR.HubConnectionState.Connected) {
          var groupName = vxm.autenticacao.idPortal;
          connection.invoke('JoinGroup', groupName.toString()).catch((err: Error) => {
            return console.error(err.toString());
          });
        }

        return connection;
      };

      const obterDescricaoSituacao = (idSituacao: number): string => {
        switch (idSituacao) {
          case 1:
            return 'Processando';
          case 2:
            return 'Conclu\u00eddo';
          case 3:
            return 'Conclu\u00eddo com inconsist\u00eancias';
          case 4:
            return 'Cancelado';
          case 5:
            return 'Aguardando';
          case 6:
            return 'Falha interna';
          default:
            return '';
        }
      };
      const methods = reactive({
        obterDadosIniciaisPainelImportacao() {
          vxm.loading.showLoading();
          vxm.importacao
            .obterDadosIniciaisImportacao()
            .catch((error) => {
              AlertsExtension.exibirMensagemErro(error, false);
            })
            .finally(() => {
              vxm.loading.hideLoading();
            });
        },

        formatarDataHora: (strData: Date) => {
          return FiltrosExtension.inserirMascaraDataHora(strData);
        },
        buscarClasseLinhaGrid: (idImportacao: number) => {
          if (vxm.importacao.importacaoSelecionada != null && vxm.importacao.importacaoSelecionada.Id == idImportacao)
            return 'cursor-pointer linha-grid-selecionada';

          return 'cursor-pointer';
        },
        downloadArquivoInconsistencias(idImportacao: number) {
          vxm.loading.showLoading();
          vxm.importacao
            .downloadArquivoInconsistencias(idImportacao)
            .then((file) => {
              if (file) {
                download.downloadFile(file);
              }
            })
            .catch((error) => {
              AlertsExtension.exibirMensagemErro(error, false);
            })
            .finally(() => {
              vxm.loading.hideLoading();
            });
        },
        cancelarImportacao(identificador: any) {
          vxm.loading.showLoading();
          var command = { Identificador: identificador };
          vxm.importacao
            .cancelarImportacao(command)
            .then(() => {
              AlertsExtension.exibirMensagemSucesso('Legal!! A importação foi cancelada com sucesso.', false);
            })
            .catch((error) => {
              AlertsExtension.exibirMensagemErro(error, false);
            })
            .finally(() => {
              vxm.loading.hideLoading();
            });
        },
      });

      return {
        ...vxm,
        ...toRefs(methods),
        ...toRefs(data),
        obterDescricaoSituacao,
        importacoesFiltradas,
        isModalOpen,
        habilitarButtons,
        habilitarCancelarImportacao,
      };
    },
  });
