export interface ParametrosCommerce {
  IntegradoLinxIO: boolean;
}

class ParametrosCommerceTypes {
  public static obterParametrosCommerce = (parametrosCommerce: boolean) => {
    return parametrosCommerce;
  };
}

export default ParametrosCommerceTypes;
