import ApiConfig from "../utils/apiConfig";

class ConnectHubRoutes {
  private getUrlBase = () => ApiConfig.getUrlConnectHub();
  //private getUrlBase = () => 'https://localhost:44317';

  public importacao = {
    notificarStatusProcessamentoImportacaoClientesFornecedores: () => `${this.getUrlBase()}/processaimportacaoclientesfornecedoreshub`
  }
}

export default new ConnectHubRoutes();