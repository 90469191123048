
  import { defineComponent, reactive, toRefs, ref, inject, onMounted, onUpdated, computed } from 'vue';
  import { vxm } from '@/store/index';
  import { PermissoesUsuario } from '@/core/types/clientes/listagem';
  import TableListagem from './Table.vue';
  import PesquisaAvancada from './pesquisa/PesquisaAvancada.vue';
  import Input from '@/components/shared/Input.vue';
  import ModalNovaImportacao from '../importacao/ModalNovaImportacao.vue';
  import AlertsExtension from '../../../utils/alerts';

  export default defineComponent({
    name: 'HeaderListagem',
    components: { TableListagem, ModalNovaImportacao, PesquisaAvancada, Input },
    setup() {
      const commandFiltrosPesquisa = ref({});
      const data = reactive({
        controlePaginacao: false,
        registrosPorPagina: 50,
        controleTela: {
          ExibePesquisaAvancada: false,
          SubTituloListagem: 'Últimos clientes atualizados',
        },
        permissoesUsuario: computed(() => {
          const permissoesUsuario = vxm.listagem.dadosIniciaisListagem.PermissoesUsuario
            ? vxm.listagem.dadosIniciaisListagem.PermissoesUsuario
            : ({} as PermissoesUsuario);

          return permissoesUsuario;
        }),
        listaDeClientes: [],
      });

      const isModalOpen = ref(false);
      const emitter: any = inject('emitter');
      onUpdated(() => {
        registrarEventos();
      });
      onMounted(() => {
        methods.obterDadosIniciais();
        methods.listarUltimosClientesAtualizados();
        methods.integraLinxIO();
      });
      const methods = reactive({
        obterDadosIniciais() {
          vxm.loading.showLoading();

          vxm.listagem
            .obterDadosIniciaisListagem()
            .then((parametros) => {
              if (parametros) {
                vxm.listagem.dadosIniciaisListagem = parametros;
              }
            })
            .catch((error) => {
              AlertsExtension.exibirMensagemErro(error, false);
            })
            .finally(() => {
              vxm.loading.hideLoading();
            });
        },
        listarUltimosClientesAtualizados() {
          vxm.loading.showLoading();
          vxm.listagem
            .obterListagemUltimosClientesAtualizados()
            .then((clientes) => {
              if (clientes) {
                vxm.listagem.listagemClientesAtualizados = clientes;
                data.listaDeClientes = vxm.listagem.listagemClientesAtualizados;
              }
            })
            .catch((error) => {
              AlertsExtension.exibirMensagemErro(error, false);
            })
            .finally(() => {
              vxm.loading.hideLoading();
            });
        },

        integraLinxIO: () => {
          vxm.loading.showLoading();
          vxm.parametrosCommerce
            .obterLinxIO()
            .then((linxio) => {
              if (linxio) {
                vxm.parametrosCommerce.linxIO = linxio;
              }
            })
            .catch((error) => {
              AlertsExtension.exibirMensagemErro(error, false);
            })
            .finally(() => {
              vxm.loading.hideLoading();
            });
        },

        pesquisarClientes() {
          if (vxm.listagem.filtros.TermoPesquisa?.trim() == '' && !data.controleTela.ExibePesquisaAvancada) {
            AlertsExtension.exibirMensagemAlerta('Digite algo para pesquisar ou utilize os filtros avançados.', false);
          } else {
            const filtroCamposAdicionais = vxm.listagem.filtros.CamposAdicionais;
            if (filtroCamposAdicionais && filtroCamposAdicionais.TipoCampo == vxm.listagem.tiposCamposAdicionais.Texto) {
              if (filtroCamposAdicionais.Valor.length < 3) {
                AlertsExtension.exibirMensagemAlerta('Digite pelo menos 3 caracteres para o filtro de campos adicionais.', false);
                return;
              }
            }

            data.controleTela.SubTituloListagem = 'Resultados da pesquisa';
            data.controlePaginacao = true;

            vxm.loading.showLoading();
            vxm.listagem
              .pesquisarClientes(vxm.listagem.filtros)
              .then((clientes: any) => {
                return (data.listaDeClientes = clientes.PessoasListagem);
              })
              .catch((error) => {
                AlertsExtension.exibirMensagemErro(error, false);
              })
              .finally(() => {
                vxm.loading.hideLoading();
              });
          }
        },
        toggleExibePesquisaAvancada: () => {
          data.controleTela.ExibePesquisaAvancada = !data.controleTela.ExibePesquisaAvancada;
        },
        limparCommandFiltrosPesquisa: () => {
          commandFiltrosPesquisa.value = {};
          methods.limparFiltros();
        },
        limparFiltros: () => {
          setTimeout(() => vxm.listagem.limparFiltrosPesquisa(), 500);
        },

        voltarListagemClientesAtualizados: () => {
          (data.registrosPorPagina = 50), (data.controleTela.SubTituloListagem = 'Ultimos produtos atualizados');
          methods.listarUltimosClientesAtualizados();
        },
      });

      const registrarEventos = (): void => {
        emitter.on('limpar-todos-filtros-selecionados', methods.limparCommandFiltrosPesquisa);
      };

      return { ...vxm, ...toRefs(data), ...toRefs(methods), isModalOpen, commandFiltrosPesquisa };
    },
  });
