export interface ApiErrorContract {
  status: number;
  title: string;
  detail?: string;
  errors?: { [key: string]: Array<string> };
  type?: string;
}

export class ApiError implements ApiErrorContract {
  status: number;
  title: string;
  detail?: string | undefined;
  errors?: { [key: string]: Array<string> } | undefined;
  type?: string;

  constructor(contract: ApiErrorContract) {
    this.status = contract.status;
    this.title = contract.title;
    this.detail = contract.detail;
    this.errors = contract.errors;
    this.type = contract.type;
  }
}

class FetchHandler {
  protected typedFetch = async <TResponse>(request: Request): Promise<TResponse> => {
    return fetch(request)
      .catch(this.handleRequestErrors)
      .then(this.handleApiResponse)
      .then((apiResponse) => {
        if (apiResponse.status == 200) return apiResponse.json() as Promise<TResponse>;
        else return (<any>apiResponse) as Promise<TResponse>;
      });
  };

  protected textFetch = async (request: Request): Promise<string> => {
    return fetch(request)
      .catch(this.handleRequestErrors)
      .then(this.handleApiResponse)
      .then((apiResponse) => {
        if (apiResponse.status == 200) return apiResponse.text();
        else return (<any>apiResponse) as Promise<string>;
      });
  };

  protected blobFetch = async (request: Request): Promise<Blob> => {
    return fetch(request)
      .catch(this.handleRequestErrors)
      .then(this.handleApiResponse)
      .then((apiResponse) => {
        if (apiResponse.status == 200) {
          return apiResponse.blob();
        } else return (<any>apiResponse) as Promise<Blob>;
      });
  };

  private handleRequestErrors = async (err: Error): Promise<Response> => {
    console.error(err);
    throw new Error(`Erro de conexão com o serviço: ${err.message}`);
  };

  private handleApiResponse = async (apiResponse: Response): Promise<Response> => {
    if (apiResponse.status === 200) return apiResponse;
    if (apiResponse.status === 201) return apiResponse;
    if (apiResponse.status === 204) return apiResponse;

    if (apiResponse.status === 400) return this.handleBadRequest(apiResponse);
    if (apiResponse.status === 401) return this.handleUnauthorized(apiResponse);
    if (apiResponse.status === 403) return this.handleForbidden(apiResponse);
    if (apiResponse.status === 404) return this.handleNotFound(apiResponse);
    if (apiResponse.status === 409) return this.handleConflict(apiResponse);

    return this.handleErroDesconhecido(apiResponse);
  };

  private handleDomainNotifications = async (notifications: Array<string>): Promise<Response> => {
    const strNotifications = notifications.join('<br>');
    throw new Error(strNotifications);
  };

  private handleBadRequest = async (erroApiResponse: Response): Promise<Response> => {
    return erroApiResponse.json().then((erroJsonResponse: Array<string> | ApiErrorContract) => {
      if (Array.isArray(erroJsonResponse)) return this.handleDomainNotifications(erroJsonResponse);
      else throw new ApiError(erroJsonResponse);
    });
  };

  private handleUnauthorized = async (erroApiResponse: Response): Promise<Response> => {
    throw new Error('Usuário não está autenticado.');
  };

  private handleForbidden = async (erroApiResponse: Response): Promise<Response> => {
    return erroApiResponse.json().then((erroJsonResponse: ApiErrorContract) => {
      throw new ApiError(erroJsonResponse);
    });
  };

  private handleNotFound = async (erroApiResponse: Response): Promise<Response> => {
    return erroApiResponse.json().then((erroJsonResponse: ApiErrorContract) => {
      throw new ApiError(erroJsonResponse);
    });
  };

  private handleConflict = async (erroApiResponse: Response): Promise<Response> => {
    return erroApiResponse.json().then((erroJsonResponse: ApiErrorContract) => {
      throw new ApiError(erroJsonResponse);
    });
  };

  private handleErroDesconhecido = async (erroApiResponse: Response): Promise<Response> => {
    return erroApiResponse.json().then((erroJsonResponse: ApiErrorContract) => {
      throw new ApiError(erroJsonResponse);
    });
  };
}

export default FetchHandler;
