import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueFormMultiselect = _resolveComponent("VueFormMultiselect")

  return (_openBlock(), _createBlock(_component_VueFormMultiselect, {
    searchable: true,
    label: "descricao",
    trackBy: "descricao",
    mode: "single",
    valueProp: "id",
    placeholder: "Selecione",
    noOptionsText: "Não existem registros cadastrados.",
    noResultsText: "Nenhum registro encontrado.",
    hideSelected: false,
    "close-on-select": false,
    class: "w-100",
    multipleLabel: _ctx.multipleLabel
  }, null, 8, ["multipleLabel"]))
}