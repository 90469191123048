import ApiConfig from '@/api/utils/apiConfig';

class ScarfRoutes {
  //   private getUrlBase = () => ApiConfig.getUrlScarfApi();
  private getUrlBase = () => 'http://localhost:7227/api';

  public biometria = {
    cadastrarBiometria: () => `${this.getUrlBase()}/Biometria/Cadastrar`,
    capturarBiometria: () => `${this.getUrlBase()}/Biometria/Capturar`,
  };
}

export default new ScarfRoutes();
