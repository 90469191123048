
  import { defineComponent, reactive, toRefs, inject } from 'vue';
  import { vxm } from '@/store/index';

  export default defineComponent({
    name: 'pesquisa-avancada-filtro-intervalo',
    props: {
      idFiltro: { type: Number, default: 0 },
      nomeFiltro: { type: String, default: '' },
      command: { type: String, default: '' },
    },
    setup(props) {
      const data = reactive({
        show: false,
        numeroCartao: 0,
      });
      const emitter: any = inject('emitter');
      const methods = reactive({
        removerFiltro: () => {
          emitter.emit('opcao-filtro-selecionada', props.command);
          (vxm.listagem.filtros.CartaoFidelidade = ''), emitter.emit('remover-filtro-selecionado', props.idFiltro);
        },
        fecharFiltro: () => {
          if (data.show) {
            data.show = false;
          }
        },
      });
      return {
        ...toRefs(data),
        ...toRefs(methods),
        ...vxm,
      };
    },
  });
