
  import { defineComponent, computed, ref, watchEffect, reactive, toRefs, watch } from 'vue';

  import BaseModal from '@/components/shared/BaseModal.vue';
  import ModalAcesso from '@/components/shared/ModalAcesso.vue';

  import { vxm } from '@/store/index';

  import PessoaFisica from '@/components/clientes/cadastro/identificacao/PessoaFisica.vue';
  import PessoaJuridica from '@/components/clientes/cadastro/identificacao/PessoaJuridica.vue';
  import Fornecedor from '@/components/clientes/cadastro/identificacao/Fornecedor.vue';

  import Campo from '@/components/shared/Campo.vue';
  import Input from '@/components/shared/Input.vue';
  import Multiselect from '@/components/shared/Multiselect.vue';

  import PessoaEnum, { TipoPessoa } from '@/core/enums/pessoa';
  import PessoaModel from '@/core/models/pessoa';
  import AlertsExtension from '@/utils/alerts';

  import { TipoCliente } from '@/core/enums/pessoa';
  import { SubClasse } from '@/core/types/clientes/cadastro';
  import { ApiError } from '@/api/utils/handler';
  import PessoaTypes from '@/core/types/clientes/pessoa/pessoa';

  export default defineComponent({
    name: 'SecaoIdentificacaoCadastro',
    components: {
      Campo,
      Input,
      Multiselect,
      PessoaFisica,
      PessoaJuridica,
      Fornecedor,
      BaseModal,
      ModalAcesso,
    },
    props: {
      idSecaoMenu: {
        type: String,
        default: '',
        required: true,
      },
      integraLinxIO: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      const ultimaPessoaObtidaPorDocumento = ref('');
      const labelCpfCnpj = ref('');
      const methods = reactive({
        btnAbreModalClasseSubClasse: () => {
          vxm.cadastro.abrirModalClasseSubClasse();
        },
        btnFecharAbreModaClasseSubClasse: () => {
          vxm.cadastro.fecharModalClasseSubClasse();
        },
      });

      const { fornecedor, ambos, cliente, ambosTransportador } = TipoCliente;
      const { isPermitirAlterarPessoa, isPessoaJuridica } = PessoaModel;

      const isObterPessoaPorDocumento = computed(
        (): boolean => ultimaPessoaObtidaPorDocumento.value !== vxm.cadastro.pessoa.documento.valor,
      );
      const isClienteInternacional = computed(() => vxm.cadastro.pessoa.isClienteInternacional.valor);
      const subclassesAssociadas = computed((): Array<SubClasse> => {
        if (vxm.cadastro.pessoa.classe.valor) {
          const subClassesAssociadas = vxm.cadastro.dadosIniciaisParaCadastro.subclasses.filter(
            ({ idClasse }) => idClasse === vxm.cadastro.pessoa.classe.valor,
          );
          return subClassesAssociadas;
        }
        return [] as Array<SubClasse>;
      });
      const isBloquearAlterarDocumento = computed((): boolean => {
        return !vxm.cadastro.isNovoCadastroPessoa && vxm.parametros.permissoesUsuario.isBloquearAlterarDocumento;
      });
      const isBloquearAlterarNomeRazaoSocial = computed((): boolean => {
        return !vxm.cadastro.isNovoCadastroPessoa && vxm.parametros.permissoesUsuario.isBloquearAlterarNomeRazaoSocial;
      });
      const isBloquearAlterarNomeFantasia = computed((): boolean => {
        return !vxm.cadastro.isNovoCadastroPessoa && vxm.parametros.permissoesUsuario.isBloquearAlterarNomeFantasia;
      });
      const isShowInputInscricaoEstadual = computed((): boolean => vxm.cadastro.pessoa.isInscricaoEstadual.valor);
      const isApresentarCampoRevendedor = computed((): boolean => {
        return (
          (vxm.cadastro.pessoa.tipoCliente.valor === cliente || vxm.cadastro.pessoa.tipoCliente.valor === ambos) &&
          vxm.cadastro.pessoa.tipoPessoa.valor === TipoPessoa.juridica &&
          !vxm.cadastro.pessoa.isClienteInternacional.valor
        );
      });
      const isShowComponenteFornecedor = computed(
        (): boolean => vxm.cadastro.pessoa.tipoCliente.valor === fornecedor || vxm.cadastro.pessoa.tipoCliente.valor === ambos || vxm.cadastro.pessoa.tipoCliente.valor === ambosTransportador,
      );

      const isPermitirDesabilitarPessoa = computed(() => {
        if (!vxm.cadastro.isNovoCadastroPessoa && vxm.cadastro.pessoa.isAtivo.valor) {
          if (vxm.cadastro.pessoa.tipoCliente.valor === TipoCliente.cliente)
            return vxm.parametros.permissoesUsuario.isPermitirExcluirCliente;

          if (vxm.cadastro.pessoa.tipoCliente.valor === TipoCliente.fornecedor)
            return vxm.parametros.permissoesUsuario.isPermitirExcluirFornecedor;

          if (vxm.cadastro.pessoa.tipoCliente.valor === TipoCliente.transportador)
            return vxm.parametros.permissoesUsuario.isPermitirExcluirTransportador;

          if (vxm.cadastro.pessoa.tipoCliente.valor === TipoCliente.prospect)
            return vxm.parametros.permissoesUsuario.isPermitirExcluirProspect;

          if (vxm.cadastro.pessoa.tipoCliente.valor === TipoCliente.ambos)
            return (
              vxm.parametros.permissoesUsuario.isPermitirExcluirCliente ||
              vxm.parametros.permissoesUsuario.isPermitirExcluirFornecedor
            );
          if(vxm.cadastro.pessoa.tipoCliente.valor === TipoCliente.ambosTransportador)
            return(
              vxm.parametros.permissoesUsuario.isPermitirExcluirCliente ||
              vxm.parametros.permissoesUsuario.isPermitirExcluirFornecedor ||
              vxm.parametros.permissoesUsuario.isPermitirExcluirTransportador
            );
        }

        return true;
      });

      const isShowCampoEmpresas = computed((): boolean => {
        return (
          !vxm.parametros.parametrosCrm.compartilharClientesEntreUnidades &&
          vxm.parametros.parametrosPortal.compartilharClientes != 'S'
        );
      });

      const isShowCampoConveniador = computed((): boolean => {
        return (vxm.cadastro.isPessoaAmbos  
        || vxm.cadastro.isPessoaAmbosTransportador) && vxm.parametros.parametrosCrm.usaConvenios
      });

      const multiselectTiposClientesFiltrados = () => {
        vxm.cadastro.pessoa.tipoPessoa.valor =
          vxm.cadastro.pessoa.tipoCliente.valor == 5
            ? TipoPessoa.fisica
            : isPessoaJuridica(vxm.cadastro.pessoa.documento.valor)
            ? TipoPessoa.juridica
            : TipoPessoa.fisica;
        const {
          isPermitirIncluirFornecedor,
          isPermitirIncluirCliente,
          isPermitirIncluirTransportador,
          isPermitirIncluirProspect,
        } = vxm.parametros.permissoesUsuario;
        return vxm.cadastro.dadosIniciaisParaCadastro.tiposClientes.filter(({ id }) => {
          if (!isPermitirIncluirCliente && id === TipoCliente.cliente) return false;
          if (!isPermitirIncluirFornecedor && id === TipoCliente.fornecedor) return false;
          if (!isPermitirIncluirTransportador && id === TipoCliente.transportador) return false;

          if (vxm.cadastro.isNovoCadastroPessoa) {
            if ((!isPermitirIncluirCliente || !isPermitirIncluirFornecedor) && (id === TipoCliente.ambos || id === TipoCliente.ambosTransportador)) return false;
          } else if (!isPermitirIncluirCliente && !isPermitirIncluirFornecedor && (id === TipoCliente.ambos || id === TipoCliente.ambosTransportador)) return false;

          return true;
        }).sort((a, b) => {
          if (a.descricao < b.descricao) return -1;
          if (a.descricao > b.descricao) return 1;
          return 0;
        });
      };

      const multiselectTransportadorasIOFiltrados = () => {
        return vxm.cadastro.dadosIniciaisParaCadastro.transportadorasIO;
      };

      const obterPessoaPorDocumento = () => {
        vxm.cadastro.pessoa.tipoPessoa.valor = isPessoaJuridica(vxm.cadastro.pessoa.documento.valor)
          ? TipoPessoa.juridica
          : TipoPessoa.fisica;

        if (isObterPessoaPorDocumento.value && vxm.cadastro.pessoa.documento.valor) {
          vxm.loading.showLoading('Verificando se este CPF/CNPJ já existe ...');

          const permissoesUsuario = vxm.parametros.permissoesUsuario;

          vxm.cadastro
            .obterPessoaPorDocumento(vxm.cadastro.pessoa.documento.valor)
            .then((pessoa) => {
              const labelTipoCliente = PessoaEnum.getDescricaoTipoCliente(pessoa.tipoCliente.valor as TipoCliente);
              if (isPermitirAlterarPessoa(pessoa.tipoCliente.valor as number, permissoesUsuario)) {
                vxm.cadastro.pessoa = pessoa;
                vxm.cadastro.isNovoCadastroPessoa = false;
                vxm.cadastro.possuiFoto = false;
                vxm.cadastro.exlcuirFotoCadastro = false;

                ultimaPessoaObtidaPorDocumento.value = pessoa.documento.valor;
              } else throw new Error(`Você não possui permissão para editar este ${labelTipoCliente}.`);
            })
            .catch((error: ApiError) => {
              if (vxm.cadastro.pessoa.id.valor != 0 && vxm.cadastro.pessoa.tipoCliente.valor == 5) {
                return;
              }
              const auxDocumentoPessoa = vxm.cadastro.pessoa.documento.valor;

              vxm.cadastro.isNovoCadastroPessoa = true;
              vxm.cadastro.pessoa = PessoaTypes.obterNovaPessoa();
              vxm.cadastro.atualizarInformacoesPessoa();

              if (error instanceof ApiError && error.status === 404) {
                const tipoPessoa = isPessoaJuridica(auxDocumentoPessoa) ? TipoPessoa.juridica : TipoPessoa.fisica;

                vxm.cadastro.pessoa.documento.valor = auxDocumentoPessoa;
                vxm.cadastro.pessoa.tipoPessoa.valor = tipoPessoa;
                if (vxm.cadastro.isNovoCadastroPessoa && tipoPessoa === TipoPessoa.juridica) {
                  vxm.cadastro.obterDadosReceita(auxDocumentoPessoa).then((response) => {
                    const tratarRetornoTelefone = (responseTelefone: string): void => {
                      const listaTelefones = responseTelefone.split('/');

                      if (!listaTelefones.length) {
                        vxm.cadastro.pessoa.contatos.telefonePrincipal.valor = '';
                        vxm.cadastro.pessoa.contatos.telefoneSecundario.valor = '';
                        return;
                      }

                      if (listaTelefones.length == 1) {
                        vxm.cadastro.pessoa.contatos.telefonePrincipal.valor = listaTelefones[0].trim();
                        return;
                      }

                      if (listaTelefones.length > 1) {
                        vxm.cadastro.pessoa.contatos.telefonePrincipal.valor = listaTelefones[0].trim();
                        vxm.cadastro.pessoa.contatos.telefoneSecundario.valor = listaTelefones[1].trim();
                      }
                    };

                    if (response.status.valor != 'ERROR') {
                      vxm.cadastro.pessoa.nome = response.nome;
                      vxm.cadastro.pessoa.nomeFantasia = response.fantasia;
                      vxm.cadastro.pessoa.endereco.cep = response.cep;
                      vxm.cadastro.pessoa.endereco.logradouro = response.logradouro;
                      vxm.cadastro.pessoa.endereco.numero = response.numero;
                      vxm.cadastro.pessoa.endereco.complemento = response.complemento;
                      vxm.cadastro.pessoa.endereco.bairro = response.bairro;
                      vxm.cadastro.pessoa.endereco.cidade = response.municipio;
                      vxm.cadastro.pessoa.endereco.estado = response.uf;
                      vxm.cadastro.pessoa.contatos.email = response.email;
                      tratarRetornoTelefone(response.telefone?.valor ?? '');

                      vxm.cadastro.sugerirClasseFiscalEndereco();
                      vxm.cadastro.sugerirClasseFiscalEnderecoAdicional();
                    }
                  });
                }

                ultimaPessoaObtidaPorDocumento.value = vxm.cadastro.pessoa.documento.valor;
              } else AlertsExtension.exibirMensagemErro(error, false);
            })
            .finally(() => {
              vxm.loading.hideLoading();
            });
        }
      };

      const onRemoveAssociarClassePreferencial = () => {
        setTimeout(() => {
          const classePrincipal = vxm.cadastro.pessoa.classe.valor;

          if (classePrincipal) vxm.cadastro.pessoa.pessoasClasses.valor.push(Number(classePrincipal));
        }, 100);
      };

      const onChangeAssociarClassePreferencial = (newVal: number[], oldVal: number[]) => {
        const pessoasClasses = vxm.cadastro.pessoa.pessoasClasses.valor;
        const classePrincipal = vxm.cadastro.pessoa.classe.valor;

        if (vxm.cadastro.pessoa.classe.valor && !pessoasClasses.includes(Number(classePrincipal)))
          vxm.cadastro.pessoa.pessoasClasses.valor.push(Number(classePrincipal));
      };

      const onChangeAdicionarClassePrincipalLista = (newVal: number, oldVal: number) => {
        const pessoasClasses = vxm.cadastro.pessoa.pessoasClasses.valor;

        vxm.cadastro.pessoa.subclasse.valor = '';

        if (!pessoasClasses.includes(newVal)) vxm.cadastro.pessoa.pessoasClasses.valor.push(newVal);
      };

      const onChangeIsInscricaoEstadual = () => {
        if (!vxm.cadastro.pessoa.isInscricaoEstadual.valor) vxm.cadastro.pessoa.inscricaoEstadual.valor = '';

        vxm.cadastro.sugerirClasseFiscalEndereco();
        vxm.cadastro.sugerirClasseFiscalEnderecoAdicional();
      };

      const sugerirClasseFiscal = () => {
        vxm.cadastro.sugerirClasseFiscalEndereco();
        vxm.cadastro.sugerirClasseFiscalEnderecoAdicional();
      };

      const onRemoveTipoRegistroProfissional = () => {
        vxm.cadastro.pessoa.registroProfissional.valor = '';
      };

      const isObrigatorioCampoDocumento = computed((): boolean => {
        const obrigaInformarDocumento =
          (vxm.cadastro.pessoa.tipoCliente.valor == TipoCliente.prospect &&
            vxm.parametros.parametrosCrm.isObrigatorioDocProspect) ||
          vxm.cadastro.pessoa.tipoCliente.valor != TipoCliente.prospect;

        const permiteCadastrarSemInformarDocumento =
          vxm.cadastro.dadosIniciaisParaCadastro.tiposRegistrosProfissionais.find(({ id }) => {
            return id === vxm.cadastro.pessoa.idTipoRegistroProfissional.valor;
          })?.permiteCadastrarSemInformarDocumento ?? false;

        if (permiteCadastrarSemInformarDocumento && vxm.cadastro.pessoa.tipoCliente.valor == TipoCliente.fornecedor) return false;

        return obrigaInformarDocumento;
      });

      const isTipoRegistroProfissionalPreenchido = computed((): boolean => {
        const registroSelecionadoValido =
          vxm.cadastro.dadosIniciaisParaCadastro.tiposRegistrosProfissionais.find(({ id }) => {
            return id === vxm.cadastro.pessoa.idTipoRegistroProfissional.valor;
          })?.id ?? null;
        return registroSelecionadoValido != null && vxm.cadastro.pessoa.tipoCliente.valor == TipoCliente.fornecedor;
      });

      watchEffect(() => {
        if (vxm.cadastro.pessoa.documento.valor) PessoaModel.validarDocumento(vxm.cadastro.pessoa.documento);
      });

      // #region TRANSPORTADOR / CONVENIADOR

      watchEffect(() => {
        if (vxm.cadastro.isPessoaTransportador || vxm.cadastro.isPessoaAmbosTransportador)
          vxm.cadastro.pessoa.transportadorIdentificacao.valor = true;
        else
          vxm.cadastro.pessoa.transportadorIdentificacao.valor = false;

        if(vxm.parametros.parametrosCrm.usaConvenios)
          vxm.cadastro.pessoa.conveniadorIdentificacao.valor = 
            (vxm.cadastro.isPessoaFornecedor || vxm.cadastro.isPessoaTransportador) ||
            (!vxm.cadastro.isNovoCadastroPessoa && vxm.cadastro.pessoa.conveniadorIdentificacao.valor && !vxm.cadastro.isPessoaCliente);

      });
      // #endregion

      return {
        ...vxm,
        ...toRefs(methods),
        PessoaModel,
        obterPessoaPorDocumento,
        isShowInputInscricaoEstadual,
        isShowComponenteFornecedor,
        isBloquearAlterarDocumento,
        isBloquearAlterarNomeRazaoSocial,
        isBloquearAlterarNomeFantasia,
        subclassesAssociadas,
        isClienteInternacional,
        labelCpfCnpj,
        multiselectTiposClientesFiltrados,
        isPermitirDesabilitarPessoa,
        onChangeIsInscricaoEstadual,
        onChangeAssociarClassePreferencial,
        onChangeAdicionarClassePrincipalLista,
        onRemoveAssociarClassePreferencial,
        sugerirClasseFiscal,
        isApresentarCampoRevendedor,
        isShowCampoEmpresas,
        multiselectTransportadorasIOFiltrados,
        isObrigatorioCampoDocumento,
        isTipoRegistroProfissionalPreenchido,
        onRemoveTipoRegistroProfissional,
        TipoCliente,
        isShowCampoConveniador,
      };
    },
  });
