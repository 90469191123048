<template>
  <Header />
</template>

<script>
  import Header from '../../components/clientes/importacao/Header';
  export default {
    name: 'PainelImportacoes',
    components: { Header },
  };
</script>

<style scoped></style>
