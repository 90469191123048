import CampoTypes, { Campo } from '../campos';
const { obterCampo } = CampoTypes;

export interface ParametrosEmpresaCrmGravacao{
  exigeCep: Campo<boolean>;
  exigeRua: Campo<boolean>;
  exigeNumero: Campo<boolean>;
  exigeBairro: Campo<boolean>;
  exigeCidade: Campo<boolean>;
}

class ParametrosEmpresaCrmGravacaoTypes {
  public static obterParametrosEmpresaCrmGravacao = (
    exigeCep: Campo<boolean>,
    exigeRua: Campo<boolean>,
    exigeNumero: Campo<boolean>,
    exigeBairro: Campo<boolean>,
    exigeCidade: Campo<boolean>,
  ): ParametrosEmpresaCrmGravacao => {
    return {
      exigeCep,
      exigeRua,
      exigeNumero,
      exigeBairro,
      exigeCidade,
    } as ParametrosEmpresaCrmGravacao;
  };

  public static obterNovoParametrosEmpresaCrmGravacao = (): ParametrosEmpresaCrmGravacao => {
    return {
      exigeCep: obterCampo(true),
      exigeRua: obterCampo(true),
      exigeNumero: obterCampo(true),
      exigeBairro: obterCampo(true),
      exigeCidade: obterCampo(true),
    } as ParametrosEmpresaCrmGravacao;
  };
}

export default ParametrosEmpresaCrmGravacaoTypes;
