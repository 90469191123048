import ViaCepRoutes from '@/api/routes/viaCep';
import { EnderecoViaCepResult } from '../contracts/viaCep';

import FetchHandler from '../utils/handler';
import RequestExtension from '../utils/request';
// import CrmRoutes from '/api/routes/crm';


class ViaCepClient extends FetchHandler {
  public buscarDadosEndereco = async (cep: string): Promise<EnderecoViaCepResult> => {
    const header = new Headers({
      // Accept: 'application/json',
      // 'Content-Type': 'application/json',
    });

    const request = RequestExtension.getDefaultGET(ViaCepRoutes.buscarDadosEnderecoViaCep(cep), header);
      return this.typedFetch<EnderecoViaCepResult>(request);  
  };
}

export default new ViaCepClient();
