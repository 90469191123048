import CampoTypes, { Campo, CampoMultiselect } from '../../campos';
import Utils from '@/utils/utils';

const { obterCampo } = CampoTypes;

export interface Endereco {
  cep: Campo<string>;
  logradouro: Campo<string>;
  numero: Campo<string>;
  complemento: Campo<string>;
  bairro: Campo<string>;
  cidade: Campo<number | string>;
  estado: Campo<number | string>;
  pais: Campo<number | string>;
  tipoMoradia: Campo<number | string | null>;
  enderecosAdicionais: Campo<Array<EnderecoAdicional>>;
  idClasseFiscal: Campo<number>;
}

export interface EnderecoBuscaCep {
  logradouro: Campo<string>;
  // numero: Campo<string>;
  cidade: Campo<number | string>;
  estado: Campo<number | string>;
}

export interface EnderecoCobranca {
  cep: Campo<string>;
  logradouro: Campo<string>;
  numero: Campo<string>;
  complemento: Campo<string>;
  bairro: Campo<string>;
  cidade: Campo<number | string>;
  estado: Campo<number | string>;
  pais: Campo<number | string>;
}

export interface EnderecoAdicional {
  id: Campo<number>;
  cep: Campo<string>;
  logradouro: Campo<string>;
  numero: Campo<string>;
  complemento: Campo<string>;
  bairro: Campo<string>;
  cidade: Campo<number | string>;
  estado: Campo<number | string>;
  documento: Campo<string>;
  razaoSocial: Campo<string>;
  inscricaoEstadual: Campo<string>;
  telefonePrincipal: Campo<string>;
  telefoneSecundario: Campo<string>;
  email: Campo<string>;
  isEnderecoPrincipal: Campo<boolean>;
  utilizaEnderecoDocumentoFiscal: Campo<boolean>;
  idClasseFiscal: Campo<number>;
  permiteExcluir: Campo<boolean>;
  excluido: Campo<boolean>;
  novo: Campo<boolean>;
}

export interface EnderecoAdicionalMultiselect extends CampoMultiselect {}

class PessoaEnderecoTypes {
  public static obterEnderecoCobranca = (
    cep: Campo<string>,
    logradouro: Campo<string>,
    numero: Campo<string>,
    complemento: Campo<string>,
    bairro: Campo<string>,
    cidade: Campo<number | string>,
    estado: Campo<number | string>,
    pais: Campo<number | string>,
  ): EnderecoCobranca => {
    return {
      cep,
      logradouro,
      numero,
      complemento,
      bairro,
      cidade,
      estado,
      pais,
    } as EnderecoCobranca;
  };

  public static obterEndereco = (
    cep: Campo<string>,
    logradouro: Campo<string>,
    numero: Campo<string>,
    complemento: Campo<string>,
    bairro: Campo<string>,
    cidade: Campo<number | string>,
    estado: Campo<number | string>,
    pais: Campo<string | number>,
    tipoMoradia: Campo<number | string>,
    enderecosAdicionais: Campo<Array<EnderecoAdicional>>,
    idClasseFiscal: Campo<number>,
  ): Endereco => {
    return {
      cep,
      logradouro,
      numero,
      complemento,
      bairro,
      cidade,
      estado,
      pais,
      tipoMoradia,
      enderecosAdicionais,
      idClasseFiscal,
    } as Endereco;
  };

  public static obterNovoEndereco = (
    cep?: string,
    logradouro?: string,
    numero?: string,
    complemento?: string,
    bairro?: string,
    cidade?: number | string,
    estado?: number | string,
    pais?: string | number,
    tipoMoradia?: number | string,
    enderecosAdicionais?: Array<EnderecoAdicional>,
    idClasseFiscal?: number,
    ): Endereco => {
    return {
      cep: obterCampo(cep || ''),
      bairro: obterCampo(bairro || ''),
      cidade: obterCampo(cidade || ''),
      complemento: obterCampo(complemento || ''),
      estado: obterCampo(estado || ''),
      logradouro: obterCampo(logradouro || ''),
      numero: obterCampo(numero || ''),
      pais: obterCampo(pais || ''),
      tipoMoradia: obterCampo(tipoMoradia || null),
      enderecosAdicionais: obterCampo(enderecosAdicionais || []),
      idClasseFiscal: obterCampo(idClasseFiscal || ''),
    } as Endereco;
  };

  public static obterNovoEnderecoAdicional = (
    id?: number,
    cep?: string,
    logradouro?: string,
    numero?: string,
    complemento?: string,
    bairro?: string,
    cidade?: number | string,
    estado?: number | string,
    documento?: string,
    razaoSocial?: string,
    inscricaoEstadual?: string,
    telefonePrincipal?: string,
    telefoneSecundario?: string,
    email?: string,
    isEnderecoPrincipal?: boolean,
    utilizaEnderecoDocumentoFiscal?: boolean,
    permiteExcluir?: boolean,
    idClasseFiscal?: number,
    excluido?: boolean,
    novo?: boolean,
  ): EnderecoAdicional => {
    return {
      id: obterCampo(id || Utils.obterIdAleatorio()),
      cep: obterCampo(cep || ''),
      bairro: obterCampo(bairro || ''),
      cidade: obterCampo(cidade || ''),
      complemento: obterCampo(complemento || ''),
      estado: obterCampo(estado || ''),
      logradouro: obterCampo(logradouro || ''),
      numero: obterCampo(numero || ''),
      documento: obterCampo(documento || ''),
      razaoSocial: obterCampo(razaoSocial || ''),
      inscricaoEstadual: obterCampo(inscricaoEstadual || ''),
      telefonePrincipal: obterCampo(telefonePrincipal || ''),
      telefoneSecundario: obterCampo(telefoneSecundario || ''),
      email: obterCampo(email || ''),
      isEnderecoPrincipal: obterCampo(isEnderecoPrincipal || false),
      utilizaEnderecoDocumentoFiscal: obterCampo(utilizaEnderecoDocumentoFiscal || false),
      permiteExcluir: obterCampo(permiteExcluir || true),
      idClasseFiscal: obterCampo(idClasseFiscal || ''),
      excluido: obterCampo(excluido || false),
      novo: obterCampo(novo || false),
    } as EnderecoAdicional;
  };
}

export default PessoaEnderecoTypes;
