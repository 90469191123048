
  import { computed, defineComponent } from 'vue';
  import { vxm } from '@/store/index';

  import Docs from '@/components/clientes/cadastro/identificacao/Docs.vue';

  import Campo from '@/components/shared/Campo.vue';
  import Input from '@/components/shared/Input.vue';
  import { TipoCliente } from '@/core/enums/pessoa';

  export default defineComponent({
    name: 'CadastroPessoaJuridica',
    components: {
      Campo,
      Input,
      Docs,
    },
    setup() {
      const isShowInscricaoSuframa = computed(() => {
        let isShowInscricaoSuframaFlag = false;

        if (vxm.cadastro.pessoa.tipoCliente.valor && vxm.cadastro.pessoa.tipoCliente.valor !== TipoCliente.fornecedor) {
          switch (vxm.cadastro.pessoa.endereco.estado.descricao) {
            case 'AC':
              isShowInscricaoSuframaFlag = true;
              break;
            case 'AM':
              isShowInscricaoSuframaFlag = true;
              break;
            case 'AP':
              isShowInscricaoSuframaFlag = true;
              break;
            case 'RO':
              isShowInscricaoSuframaFlag = true;
              break;
            case 'RR':
              isShowInscricaoSuframaFlag = true;
              break;
          }
        }

        return isShowInscricaoSuframaFlag;
      });
      return {
        ...vxm,
        isShowInscricaoSuframa,
      };
    },
  });
