
  /* eslint-disable */
  import { computed, onMounted, reactive } from 'vue';
  import { createModule, mutation, action } from 'vuex-class-component';
  import CrmClient from '@/api/clients/crm';
  import { vxm } from '@/store/index';
  import { ObterPermissaoPorIdDTO, RetornoObterPermissaoPorIdDTO } from '@/api/contracts/buscarPermissao';
  export default {
    name: 'ModalAcesso',
    props: {
      descricao: {
        type: String,
        required: true,
      },
      nomepermissao: {
        type: String,
        required: true,
      },
      permissao: {
        type: Number,
        required: true,
      },
    },
    setup(props: any) {
      const dados: any = reactive({
        listaDeUsuarios: [],
      });
      const ativarPermissoes = (): Promise<void> => {
        const permissao: number = props.permissao;
        const nomepermissao: string = props.nomepermissao;
        return vxm.permissao.ativarPermissao({
          idPermissao: permissao,
          nomePermissao: nomepermissao,
        });
      };
      onMounted(() => {
        vxm.loading.showLoading();
        vxm.cadastro
          .listarUsuariosSupervisores()
          .then((result) => {
            dados.listaDeUsuarios = result;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            vxm.loading.hideLoading();
          });
      });
      return {
        ativarPermissoes,
        vxm,
        dados,
      };
    },
  };
