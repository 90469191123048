import eventBus from "@/core/common/eventBus";
import ApiConfig from '@/api/utils/apiConfig';

const _URL_ERP = ApiConfig.getUrlBaseErp();

const _getFullUrl = (url = "") => {
	let fullUrl = "";

	if (!url.startsWith(_URL_ERP)) {
		fullUrl = ApiConfig.getUrlBaseErp() + "/" + url;
	} else {
		fullUrl = url;
	}

	return fullUrl;
}

const _receiveMessage = (event) => {
	if (!_URL_ERP.startsWith(event.origin)) {
		return;
	}

	let action = event.data.action;
	let message = {
		...event.data,
		origin: event.origin
	};

	eventBus.publish(action, message);
};

const openModal = (url = "", title = "") => {
	let fullUrl = _getFullUrl(url);

	if (!window.erpListener) {
		window.erpListener = true;
		window.addEventListener('message', _receiveMessage);
	}

	return new Promise((resolve, reject) => {
		if (!window.parent) {
			return reject("Falha ao comunicar com o ERP");
		}

		if (!window.parent.postMessage) {
			return reject("Falha ao comunicar com o ERP");
		}

		window.parent.postMessage({ url: fullUrl, action: "open", title }, fullUrl);
		
		eventBus.subscribe("closed", (arg) => {
			return resolve(arg);
		});
	});
};

const openNewTab = (url = "") => {
	let fullUrl = _getFullUrl(url);

	window.open(fullUrl);
}

/**
 * @param {Object} options { title: '', width: 0, height: 0, top: 0, left: 0 };
 */
const openPopup = (url = "", options = "") => {
	let fullUrl = _getFullUrl(url);

	window.open(fullUrl, '1429893142534', 'scrollbars=1');
}

const erpClient = {
	openNewTab,
	openModal,
	openPopup
};

export default erpClient;