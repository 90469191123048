
  import { defineComponent } from 'vue';
  import { vxm } from '@/store/index';
  import ScarRoutes from '@/api/clients/scarf';
  import CrmClient from '@/api/clients/crm';
  import Swal from 'sweetalert2';

  export default defineComponent({
    name: 'CadastroBiometrico',
    props: {
      idSecaoMenu: {
        type: String,
        default: '',
        required: true,
      },
    },
    setup() {
      const verificarScarfInstalado = () => {
        vxm.loading.showLoading('Buscando informações para cadastro ...');
        
        ScarRoutes.CadastrarBiometria()
          .then((data) => {
            vxm.cadastro.biometriaCapturada = String(data);            
          })
          .catch((error) => {
            if (error.message == null) return;

            let mensagemErro =
              'Para utilizar este recurso neste navegador, é necessário a instalação do aplicativo Linx Scarf.<br>Clique em <b>OK</b> para prosseguir.';

            Swal.fire({
              title: 'Atenção!',
              html: mensagemErro,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#28a745',
              confirmButtonText: 'OK',
              cancelButtonColor: '#d33',
              cancelButtonText: 'Cancelar',
            }).then((result) => {
              if (result.isConfirmed) {
                var pasta = "gestor_web";
                var indexGestorWeb = window.location.href.indexOf(pasta);
                if (indexGestorWeb > -1) {
                    var urlBase = window.location.href.substring(0, indexGestorWeb + pasta.length);
                    window.open(urlBase + "/framework/scarf/sobre.asp", "_blank");
                }
                else {
                  let paginaAtual = 'cadastro';
                  let indexCadastro = window.location.href.indexOf(paginaAtual);
                  let urlBase = window.location.href.slice(0, indexCadastro);
                  window.open(urlBase + 'scarf', '_blank');
                }
              }
            });
          })
          .finally(() => {
            vxm.loading.hideLoading();
          });
      };

      const erroDesconhecido = () => {
        Swal.fire({
          title: 'Atenção!',
          html: 'Desculpe, ocorreu um erro inesperado. Verifique com o administrador.',
          icon: 'warning',
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK',
        });
      };

      return { verificarScarfInstalado, erroDesconhecido };
    },
  });
