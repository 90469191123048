import { reactive, readonly } from 'vue';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
  isLoading: false,
};

let _state = reactive(cloneDeep(initialState));

let uiState = initialState;
uiState = readonly(_state);

const uiAction = {
  loading() {
    _state.isLoading = true;
  },

  unloading(timeout = 0) {
    if (timeout > 0) {
      setTimeout(() => {
        _state.isLoading = false;
      }, timeout);
    } else {
      _state.isLoading = false;
    }

  }
};

export {
  uiState,
  uiAction,
};