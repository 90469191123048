
  import { defineComponent, onMounted, onUpdated, reactive, toRefs, watch, computed } from 'vue';

  import FiltrosExtension from '@/utils/filtros';
  import UtilsExtension from '@/utils/utils';

  export default defineComponent({
    name: 'Input',
    props: {
      id: {
        type: String,
        default: '',
        required: true,
      },
      modelValue: {
        required: true,
      },
      campo: {
        type: String,
        default: '',
        required: false,
      },
      allowOnlyNumbers: {
        type: Boolean,
        default: false,
        required: false,
      },
      allowOnlyLetters: {
        type: Boolean,
        default: false,
        required: false,
      },
      maxLength: {
        type: Number,
        required: false,
        default: 250,
      },
      tamanho: {
        type: String,
        default: 'sm',
        required: false,
      },
    },
    setup(props, context) {
      const state = reactive({
        isInputValueClean: true as boolean,
        element: {} as HTMLInputElement,
        type: '',
        defaultStyleClass: [] as Array<string>,
        maxLength: props.maxLength,
        filteredInputValue: computed((): string => {
          let inputValueAux = String(props.modelValue);

          if (props.campo === 'CEP') inputValueAux = handleInputValueCampoCep(inputValueAux);
          if (props.campo === 'DOCUMENTO') inputValueAux = handleInputValueCampoDocumento(inputValueAux);
          if (props.campo === 'TELEFONE' || props.campo === 'CELULAR')
            inputValueAux = handleInputValueCampoTelefone(inputValueAux);
          if (props.campo === 'RG') inputValueAux = handleInputValueCampoRg(inputValueAux);
          if (props.campo === 'PORCENTAGEM') inputValueAux = handleInputValueCampoPorcentagem(inputValueAux);
          if (props.campo === 'GUID') inputValueAux = handleInputValueCampoGuid(inputValueAux);
          if (props.campo === 'EMAIL') inputValueAux = handleInputValueCampoEmail(inputValueAux);

          return inputValueAux;
        }),
      });

      const handleInputValueCampoPorcentagem = (porcentagem: string): string => {
        state.isInputValueClean = false;
        state.maxLength = 3;
        porcentagem = FiltrosExtension.inserirMascaraPorcentagem(porcentagem);
        return porcentagem;
      };

      const handleInputValueCampoCep = (cep: string): string => {
        state.isInputValueClean = false;
        state.maxLength = 9;
        cep = FiltrosExtension.inserirMascaraCep(cep);
        return cep;
      };

      const handleInputValueCampoTelefone = (telefone: string): string => {
        state.isInputValueClean = false;
        state.maxLength = 15;
        telefone = FiltrosExtension.inserirMascaraTelefone(telefone);
        return telefone;
      };

      const handleInputValueCampoRg = (rg: string): string => {
        state.isInputValueClean = false;
        rg = FiltrosExtension.inserirMascaraRg(rg);
        return rg;
      };

      const handleInputValueCampoDocumento = (documento: string): string => {
        const TAMANHO_CPF = 11;
        const TAMANHO_CNPJ = 14;

        state.isInputValueClean = false;
        state.maxLength = 18;

        if (documento.length === TAMANHO_CPF) documento = FiltrosExtension.inserirMascaraCpf(documento);
        if (documento.length === TAMANHO_CNPJ) documento = FiltrosExtension.inserirMascaraCnpj(documento);

        return documento;
      };

      const handleInputValueCampoGuid = (guid: string): string => {
        state.isInputValueClean = true;
        state.maxLength = 32;
        return FiltrosExtension.inserirMascaraGuid(guid);
      };

      const handleInputValueCampoEmail = (email: string): string => {
        state.isInputValueClean = true;
        state.maxLength = 100;
        return email;
      };

      const handleUpdateModelValue = (value: any): void => {
        if (state.type === 'checkbox') {
          return context.emit('update:modelValue', state.element.checked);
        } else {
          if (!state.isInputValueClean) value = FiltrosExtension.removerMascara(String(value));
          return context.emit('update:modelValue', value);
        }
      };

      const handleAllowOnlyNumbersOrLetters = (event: any): any => {
        event = event ? event : window.event;
        var charCode = event.which ? event.which : event.keyCode;

        if (props.allowOnlyNumbers && charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46)
          return event.preventDefault();

        const isDigitLetter = (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8;
        if (props.allowOnlyLetters && !isDigitLetter) return event.preventDefault();

        if (props.campo === 'PORCENTAGEM') {
          const valueWithoutMask = FiltrosExtension.removerMascara(state.filteredInputValue);
          const value = FiltrosExtension.inserirMascaraPorcentagem(valueWithoutMask + event.key);
          if (value.length == 4) state.maxLength = value.length;
          else if (value.length < 4) state.maxLength = 3;
        }

        return true;
      };

      onMounted(() => {
        const inputElement = document.getElementById(props.id) as HTMLInputElement;

        if (inputElement) {
          state.element = inputElement;
          state.type = inputElement.type;

          if (state.type === 'checkbox') {
            state.element.checked = Boolean(props.modelValue);
            state.defaultStyleClass = ['custom-control-input'];
          }
          if (state.type === 'text' || state.type === 'number')
            state.defaultStyleClass = ['form-control', `form-control-${props.tamanho}`];

          handleUpdateModelValue(props.modelValue);
        }
      });

      watch(
        () => props.modelValue,
        () => {
          if (state.type === 'checkbox') state.element.checked = Boolean(props.modelValue);
        },
      );

      return {
        ...toRefs(state),
        handleUpdateModelValue,
        handleAllowOnlyNumbersOrLetters,
      };
    },
  });
