
  import { defineComponent, reactive, inject, toRefs, watch, ref } from 'vue';
  import DatePicker from '@/components/shared/DatePicker.vue';
  import { vxm } from '@/store/index';

  export default defineComponent({
    name: 'pesquisa-avancada-filtro-intervalo',
    components: { DatePicker },
    props: {
      idFiltro: { type: Number, default: 0 },
      nomeFiltro: { type: String, default: '' },
      command: { type: String, default: '' },
    },

    setup(props) {
      const data = reactive({
        dataInicio: ref(''),
        dataFinal: ref(''),
        show: false,
      });
      const emitter: any = inject('emitter');
      const removerFiltro = () => {
        emitter.emit('opcao-filtro-selecionada', props.command);
        if (props.nomeFiltro == 'Data de aniversário') {
          vxm.listagem.filtros.DataAniversarioInicial = null;
          vxm.listagem.filtros.DataAniversarioFinal = null;
        }
        if (props.nomeFiltro == 'Data de cadastro') {
          vxm.listagem.filtros.DataCadastroInicial = null;
          vxm.listagem.filtros.DataCadastroFinal = null;
        }

        emitter.emit('remover-filtro-selecionado', props.idFiltro);
      };

      const fecharFiltro = () => {
        if (data.show) {
          data.show = false;
        }
      };

      function converterData(data: any) {
        if (data) {
          var dia = data.split('/')[0];
          var mes = Number(data.split('/')[1] - 1);
          var ano = data.split('/')[2];
          return new Date(ano, mes, dia).toISOString();
        }
      }
      const dataInicio = ref('');
      const dataFinal = ref('');
      watch(dataInicio, function () {
        if (props.nomeFiltro == 'Data de aniversário') {
          vxm.listagem.filtros.DataAniversarioInicial = converterData(dataInicio.value);
        }
        if (props.nomeFiltro == 'Data de cadastro') {
          vxm.listagem.filtros.DataCadastroInicial = converterData(dataInicio.value);
        }
      });
      watch(dataFinal, () => {
        if (props.nomeFiltro == 'Data de aniversário') {
          vxm.listagem.filtros.DataAniversarioFinal = converterData(dataFinal.value);
        }
        if (props.nomeFiltro == 'Data de cadastro') {
          vxm.listagem.filtros.DataCadastroFinal = converterData(dataFinal.value);
        }
      });
      return { ...vxm, ...toRefs(data), dataInicio, dataFinal, removerFiltro, fecharFiltro };
    },
  });
