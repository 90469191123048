
  import { defineComponent, reactive, toRefs, ref, inject } from 'vue';
  import Table from './Table.vue';
  import ModalNovaImportacao from './ModalNovaImportacao.vue';

  export default defineComponent({
    name: 'PainelImportacoes',
    components: { Table, ModalNovaImportacao },
    setup() {
      const data = reactive({
        searchQuery: '',
      });
      const isModalOpen = ref(false);

      const emitter: any = inject('emitter');
      const atualizar = () => {
        emitter.emit('atualizar');
      };
      return { ...toRefs(data), isModalOpen, atualizar };
    },
  });
