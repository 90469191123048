
  import { defineComponent, reactive, toRefs, inject, onMounted } from 'vue';
  import { vxm } from '@/store/index';

  export default defineComponent({
    name: 'pesquisa-avancada-filtro-intervalo',
    props: {
      idFiltro: { type: Number, default: 0 },
      nomeFiltro: { type: String, default: '' },
      command: { type: String, default: '' },
    },
    setup(props) {
      const emitter: any = inject('emitter');
      const methods = reactive({
        removerFiltro: () => {
          emitter.emit('remover-filtro-selecionado', props.idFiltro, (vxm.listagem.filtros.ExibirInativos = false));
        },
      });
      onMounted(() => {
        vxm.listagem.filtros.ExibirInativos = true;
      });
      return {
        ...toRefs(methods),
      };
    },
  });
