import ReceitaWsRoutes from '@/api/routes/receitaWs';
import { DadosCnpjReceitaResult } from '../contracts/receitaWs';

import FetchHandler from '../utils/handler';
import RequestExtension from '../utils/request';
import fetchJsonp from 'fetch-jsonp';

class CnpjReceitaClient extends FetchHandler {
  public buscarDadosCnpj = async (cnpj: string): Promise<DadosCnpjReceitaResult> => {
    const result = fetchJsonp('https://www.receitaws.com.br/v1/cnpj/' + cnpj);
    return result
      .then(function (response) {
        if (response.ok) return response.json();
        else return (<any>response) as Promise<DadosCnpjReceitaResult>;
      })
      .then(function (json) {
        return json as DadosCnpjReceitaResult;
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  };
}

export default new CnpjReceitaClient();
