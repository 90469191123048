import CampoTypes, { Campo } from '../../campos';

const { obterCampo } = CampoTypes;

export interface PessoaBanco {
  isPix: Campo<boolean>;
  idBancoCliente: Campo<number>;
  idBanco: Campo<number | string | null>;
  agencia: Campo<string>;
  agenciaDigito: Campo<string | string>;
  idTipoContaBancaria: Campo<number | null>;
  contaCorrente: Campo<string>;
  contaCorrenteDigito: Campo<string | string>;
  nomeContato: Campo<string>;
  fone: Campo<string>;
  valorLimiteConta: Campo<number | string>;
  codigoCliente: Campo<number | string>;
  isPrincipal: Campo<boolean>;
  isContaAgendamentoPagamento: Campo<boolean>;
  isAtivo: Campo<boolean>;
  dataAberturaConta: Campo<Date>;
  isNovoDadoBancario: Campo<boolean>;
  nomeTitular: Campo<string>;
  documentoTitular: Campo<number | string>;
  preencherNomeDocumentoCliente: Campo<boolean>;
  idTipoChavePix: Campo<number | null>;
  chavePix: Campo<string>;
}
export interface DadosComerciais {
  isFaturamentoLiberado: Campo<boolean>;
  idMotivoBloqueioFaturamento: Campo<number>;
  motivoBloqueioFaturamento: Campo<string>;
  faturamentoMinimo: Campo<number>;
  limiteCreditoCrediario: Campo<number>;
  limiteCreditoDinheiroCartao: Campo<number>;
  valorTolerancia: Campo<number>;
  tipoRenovacaoSaldo: Campo<number>;
  prazoValidadeLimite: Campo<number>;
  isRecebeNewsletter: Campo<boolean>;
  tabelaDePreco: Campo<number | string>;
  prazoEntrega: Campo<number>;
  utilizaCorreiosSedex: Campo<number>;
  vendedores: Campo<Array<string | number>>;
  planosPagamentos: Campo<Array<string | number>>;
  ramoOpticoLaboratorio: Campo<number | string>;
  idFreteTiposPreferencial: Campo<number | string>; //MODAJOI-37214 (US 001)
  obrigarPreenchimentoQuantidadeEspecie: Campo<boolean>; //MODAJOI-37214 (US 002)
  bancos: Campo<Array<PessoaBanco>>;
  serieFaturamento: Campo<number | string>;
  informacoesComerciais: Campo<string>;
  aceitaBoletoBancario: Campo<boolean>;
  idGrupoEmpresarial: Campo<number>;
  destacaIcmsProprio: Campo<boolean>;
  listaCnpjsVinculados: Array<string>;
  cnpjVinculado: Campo<string>;
  aceitaProgramaFidelidade: Campo<boolean>;
  percentualMaximoDevolucao: Campo<number>;
}

export type PropClicouEmSimParaDesmarcar = 'isPrincipal' | 'isContaAgendamentoPagamento';

export interface ControleClicouEmSimParaDesmarcar {
  isPrincipal: PessoaBanco;
  isContaAgendamentoPagamento: PessoaBanco;
}

class PessoaComercialTypes {
  public static obterDadosComerciais = (
    isFaturamentoLiberado: Campo<boolean>,
    idMotivoBloqueioFaturamento: Campo<number>,
    motivoBloqueioFaturamento: Campo<string>,
    faturamentoMinimo: Campo<number>,
    limiteCreditoCrediario: Campo<number>,
    limiteCreditoDinheiroCartao: Campo<number>,
    valorTolerancia: Campo<number>,
    tipoRenovacaoSaldo: Campo<number>,
    prazoValidadeLimite: Campo<number>,
    tabelaDePreco: Campo<number | string>,
    prazoEntrega: Campo<number>,
    utilizaCorreiosSedex: Campo<number>,
    isRecebeNewsletter: Campo<boolean>,
    vendedores: Campo<Array<string | number>>,
    planosPagamentos: Campo<Array<string | number>>,
    ramoOpticoLaboratorio: Campo<number | string>,
    idFreteTiposPreferencial: Campo<number | string>, //MODAJOI-37214 (US 001)
    obrigarPreenchimentoQuantidadeEspecie: Campo<boolean>, //MODAJOI-37214 (US 002)
    bancos: Campo<Array<PessoaBanco>>,
    serieFaturamento: Campo<number | string>,
    informacoesComerciais: Campo<string>,
    aceitaBoletoBancario: Campo<boolean>,
    idGrupoEmpresarial: Campo<number>,
    destacaIcmsProprio: Campo<boolean>,
    listaCnpjsVinculados: Array<string>,
    cnpjVinculado: Campo<string>,
    aceitaProgramaFidelidade: Campo<boolean>,
    percentualMaximoDevolucao: Campo<number>,
  ): DadosComerciais => {
    return {
      isFaturamentoLiberado,
      idMotivoBloqueioFaturamento,
      motivoBloqueioFaturamento,
      faturamentoMinimo,
      limiteCreditoCrediario,
      limiteCreditoDinheiroCartao,
      valorTolerancia,
      tipoRenovacaoSaldo,
      prazoValidadeLimite,
      tabelaDePreco,
      prazoEntrega,
      utilizaCorreiosSedex,
      isRecebeNewsletter,
      vendedores,
      planosPagamentos,
      ramoOpticoLaboratorio,
      idFreteTiposPreferencial, //MODAJOI-37214 (US 001)
      obrigarPreenchimentoQuantidadeEspecie, //MODAJOI-37214 (US 002)
      bancos,
      serieFaturamento,
      informacoesComerciais,
      aceitaBoletoBancario,
      idGrupoEmpresarial,
      destacaIcmsProprio,
      listaCnpjsVinculados,
      cnpjVinculado,
      aceitaProgramaFidelidade,
      percentualMaximoDevolucao,
    } as DadosComerciais;
  };
}

export default PessoaComercialTypes;
