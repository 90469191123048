
  import { defineComponent } from 'vue';

  import Loading from '@/components/shared/Loading.vue';

  import { vxm } from './store/index';

  export default defineComponent({
    name: 'App',
    components: {
      Loading,
    },
    setup() {
      return {
        ...vxm,
      };
    },
  });
