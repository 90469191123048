
  import { Campo, Erro } from '@/core/types/campos';
  import { computed, defineComponent, onMounted, onUpdated, PropType, reactive, toRefs } from 'vue';

  export default defineComponent({
    name: 'Campo',
    props: {
      id: {
        type: String,
        required: true,
      },
      modelValue: {
        type: Object as PropType<Campo<any>>,
        required: true,
      },
      label: {
        type: String,
        default: '',
      },
      isRequired: {
        type: Boolean,
        default: false,
      },
      showMensagemErro: {
        type: Boolean,
        default: true,
      },
      customErros: {
        type: Array as PropType<Array<Erro>>,
        default: () => [] as Array<Erro>,
      },
      showLabel: {
        type: Boolean,
        default: true,
      },
      icone: {
        type: String,
        default: '',
      },
      showIcone: {
        type: Boolean,
        default: false,
      },
      titleIcone: {
        type: String,
        default: '',
      },
    },
    setup(props, context) {
      const state = reactive({
        modelValueAux: {} as Campo<any>,
        defaultStyleClass: [] as Array<string>,

        obterIcone: (icone: string) => {
          return icone + ' fa-sm';
        },
        obterTitleIcone: (titleIcone: string) => {
          return titleIcone;
        },
      });

      const mensagemErro = computed((): string | null => {
        if (props.modelValue.erros.length !== 0) return props.modelValue.erros[0].mensagem;

        if (props.customErros.length !== 0) return props.customErros[0].mensagem;
        return null;
      });

      const isCheckbox = computed((): boolean => typeof props.modelValue.valor === 'boolean');

      const handleUpdateLabel = () => {
        if (!props.modelValue.label && state.modelValueAux.label) {
          const labelAux = state.modelValueAux.label;
          state.modelValueAux = props.modelValue;
          state.modelValueAux.label = labelAux;

          context.emit('update:modelValue', state.modelValueAux);
        }
      };

      onMounted(() => {
        state.modelValueAux = props.modelValue;
        state.modelValueAux.label = props.label;

        if (isCheckbox.value) state.defaultStyleClass = ['custom-control', 'custom-checkbox'];
        else state.defaultStyleClass = ['d-flex', 'flex-column', 'margin-my-2', 'flex-grow-1', 'w-100'];

        context.emit('update:modelValue', state.modelValueAux);
      });

      onUpdated(() => {
        handleUpdateLabel();
      });

      return {
        ...toRefs(state),
        mensagemErro,
        isCheckbox,
      };
    },
  });
