
  import { defineComponent, computed, onMounted } from 'vue';
  import { vxm } from '@/store/index';

  import Campo from '@/components/shared/Campo.vue';
  import Input from '@/components/shared/Input.vue';
  import Multiselect from '@/components/shared/Multiselect.vue';

  import { PessoaBanco } from '@/core/types/clientes/pessoa/comercial';
  import { TipoChavePix } from '@/core/enums/cadastro';
  import FiltrosExtension from '@/utils/filtros';

  export default defineComponent({
    name: 'DadosBancariosFormularioConta',
    components: {
      Campo,
      Input,
      Multiselect,
    },
    props: {
      isPix: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      const dadoBancarioSelecionado = computed(() => {
        const dadoBancarioSelecionado = vxm.cadastro.componenteDadosBancarios.pessoaBancoSelecionado.valor;
        return dadoBancarioSelecionado || ({} as PessoaBanco);
      });

      const opcoesInformacoesBanco = computed(() => {
        return vxm.cadastro.dadosIniciaisParaCadastro.bancos.map((banco) => ({
          id: banco.id,
          descricao: `${banco.id.toString().padStart(3, '0')} - ${banco.descricao}`,
        }));
      });

      const isContaBancaria = computed(
        () =>
          !dadoBancarioSelecionado.value.isPix.valor ||
          (dadoBancarioSelecionado.value.isPix.valor &&
            dadoBancarioSelecionado.value.idTipoChavePix.valor === TipoChavePix.ContaBancaria),
      );

      const aplicarNomeDocumentoCliente = () => {
        if (!dadoBancarioSelecionado.value.preencherNomeDocumentoCliente.valor) return;

        dadoBancarioSelecionado.value.nomeTitular.valor = vxm.cadastro.pessoa.nome.valor ?? '';
        dadoBancarioSelecionado.value.documentoTitular.valor = vxm.cadastro.pessoa.documento.valor ?? '';
      };

      const verificarTitularidade = () => {
        const nomeTitular = dadoBancarioSelecionado.value.nomeTitular.valor;
        const documentoTitular = dadoBancarioSelecionado.value.documentoTitular.valor;
        const pessoa = vxm.cadastro?.pessoa;

        if (!nomeTitular || !documentoTitular) return;

        const preencherNomeDocumentoCliente =
          nomeTitular === pessoa?.nome?.valor &&
          documentoTitular === FiltrosExtension.removerMascara(pessoa?.documento?.valor ?? '');

        dadoBancarioSelecionado.value.preencherNomeDocumentoCliente.valor = preencherNomeDocumentoCliente;
      };

      onMounted(() => verificarTitularidade());

      return {
        dadoBancarioSelecionado,
        opcoesInformacoesBanco,
        isContaBancaria,
        ...vxm,
        aplicarNomeDocumentoCliente,
      };
    },
  });
