
  import { defineComponent, toRefs, reactive, inject } from 'vue';
  import { vxm } from '@/store/index';

  import AlertsExtension from '../../../utils/alerts';
  import download from '../../../utils/download';

  import BaseModal from '../../shared/BaseModal.vue';
  import UploadArquivo from '../../shared/UploadArquivo.vue';

  export default defineComponent({
    name: 'NovaImportacao',
    components: { BaseModal, UploadArquivo },
    setup(props, context) {
      const data = reactive({
        nomeArquivo: '',
        erroArquivo: '',
        arquivo: '',
      });
      const emitter: any = inject('emitter');
      const atualizar = () => {
        emitter.emit('atualizar');
      };
      const methods = reactive({
        isEmptyObject(obj: any) {
          return JSON.stringify(obj) === '{}';
        },

        downloadArquivoModelo() {
          vxm.loading.showLoading();
          vxm.importacao
            .downloadArquivoModelo()
            .then((file) => {
              if (file) {
                download.downloadFile(file);
              }
            })
            .catch((error) => {
              AlertsExtension.exibirMensagemErro(error, false);
            })
            .finally(() => {
              vxm.loading.hideLoading();
            });
        },
        importarClientesPorArquivo() {
          if (!data.arquivo) {
            data.erroArquivo = 'Arquivo é um campo obrigatório';
          } else {
            const formData = new FormData();
            formData.append('arquivo', data.arquivo);
            vxm.loading.showLoading();
            vxm.importacao
              .importarClientePorArquivoV2(formData)
              .then(() => {
                AlertsExtension.exibirMensagemSucesso(
                  'Legal! A importação foi iniciada com sucesso. Acompanhe em tempo real o status no painel de importações.',
                  false,
                );
                atualizar();
                this.closeModal();
              })
              .catch((error) => {
                AlertsExtension.exibirMensagemErro(error, false);
              })
              .finally(() => {
                vxm.loading.hideLoading();
              });
          }
        },
        closeModal: () => {
          // context.emit('update:atualizarListagem', true);
          context.emit('update:is-open', false);
        },
      });
      function setaArquivoParaUpload(file: any) {
        let arquivoEnviado = file[0];
        data.nomeArquivo = arquivoEnviado.name;
        data.arquivo = arquivoEnviado;
        data.erroArquivo = '';
      }

      return {
        ...toRefs(methods),
        setaArquivoParaUpload,
        data,
      };
    },
  });
