class UtilsExtension {
  public static setPrimeiraLetraMaiuscula = (valor: string): string => {
    return valor.charAt(0).toUpperCase() + valor.slice(1);
  };
  public static convertStringToDate = (date: string): Date => {
    const parts = date.split('/');
    return new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
  };
  public static obterSomenteNumeros = (str: string): any => {
    return str.replace(/[^\d]+/g, '');
  };
  public static obterIdAleatorio = (): number => {
    return Math.floor(Math.random() * (2147483647 - 0 + 1) + 0);
  };
}

export default UtilsExtension;
