
  import { computed, defineComponent, watch, watchEffect, onMounted, onUpdated } from 'vue';

  import Campo from '@/components/shared/Campo.vue';
  import Input from '@/components/shared/Input.vue';
  import VueFormMultiselect from '@vueform/multiselect';

  import FiltrosExtension from '@/utils/filtros';
  import PessoaContatoTypes, { ContatoAdicional, ContatoAdicionalMultiselect } from '@/core/types/clientes/pessoa/contato';

  import { vxm } from '@/store/index';
  import AlertsExtension from '@/utils/alerts';
  import CamposTypes from '@/core/types/campos';

  export default defineComponent({
    name: 'SecaoContatoCadastro',
    components: {
      Campo,
      Input,
      VueFormMultiselect,
    },
    props: {
      idSecaoMenu: {
        type: String,
        default: '',
        required: true,
      },
      integraLinxIO: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const isInserirMascaraTelefone = computed(() => !vxm.cadastro.pessoa.isClienteInternacional.valor);

      const obterNovoContatoAdicional = (): void => {
        vxm.cadastro.contatoAdicionalSelecionado.valor = null;
        vxm.cadastro.contatoAdicional.valor = PessoaContatoTypes.obterNovoContatoAdicional();

        const contatosAdicionaisPessoa = vxm.cadastro.pessoa.contatos.contatosAdicionais.valor;
        const idsContatosAdicionaisPessoa = contatosAdicionaisPessoa.map(({ id }) => Number(id.valor));
        const maxIdContatoAdicionalPessoa = Math.max(...idsContatosAdicionaisPessoa);

        if (maxIdContatoAdicionalPessoa > 0) vxm.cadastro.contatoAdicional.valor.id.valor = maxIdContatoAdicionalPessoa + 1;
        else vxm.cadastro.contatoAdicional.valor.id.valor = 1;
      };

      const handleOnChangeMultiselectContatoAdicional = (option: ContatoAdicional): void => {
        if (option) vxm.cadastro.contatoAdicional.valor = JSON.parse(JSON.stringify(option));
        else vxm.cadastro.contatoAdicional.valor = null;
      };

      return {
        ...vxm,
        isInserirMascaraTelefone,
        obterNovoContatoAdicional,
        handleOnChangeMultiselectContatoAdicional,
      };
    },
  });
