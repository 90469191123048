import FetchHandler from '../utils/handler';
import RequestExtension from '../utils/request';

import ScarRoutes from '@/api/routes/scarf';
const { cadastrarBiometria, capturarBiometria } = ScarRoutes.biometria;
const customHeader = new Headers({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
  'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
});

class ScarfClient extends FetchHandler {
  public CadastrarBiometria = async (): Promise<string> => {
    const request = RequestExtension.getDefaultPOST(cadastrarBiometria(), customHeader);
    return this.textFetch(request);
  };

  public CapturarBiometria = async (): Promise<string> => {
    const request = RequestExtension.getDefaultPOST(capturarBiometria(), customHeader);
    return this.textFetch(request);
  };
}

export default new ScarfClient();
