import {
  ParametrosCrmResult,
  ParametrosEmpresaResult,
  ParametrosPortalResult,
  PermissoesUsuarioResult,
} from '@/api/contracts/crm/cadastro-completo/dadosIniciais';

import { ObterParametrosCrm, ObterParametrosCrmResult } from '@/api/contracts/crm/parametros/obterParametrosCrm';
import {
  ObterParametrosEmpresaCrm,
  ObterParametrosEmpresaCrmResult,
} from '@/api/contracts/crm/parametros/obterParametrosEmpresaCrm';
import ParametrosTypes, { ParametrosCrm, ParametrosPortal } from '@/core/types/parametros';
import ParametrosCrmGravacaoTypes, { ParametrosCrmGravacao } from '@/core/types/parametros/parametrosCrmGravacao';
import ParametrosEmpresaCrmGravacaoTypes, {
  ParametrosEmpresaCrmGravacao,
} from '@/core/types/parametros/parametrosEmpresaCrmGravacao';
import CamposTypes, { Campo, CampoMultiselect } from '@/core/types/campos';

import { PermissoesUsuario } from '../types/parametros';

import { ParametrosCrmDTO } from '@/api/contracts/crm/parametros/AtualizarParametrosCrm';

const { obterPermissoesUsuario, obterParametrosCrm } = ParametrosTypes;
const { obterParametrosCrmGravacao } = ParametrosCrmGravacaoTypes;
const { obterParametrosEmpresaCrmGravacao } = ParametrosEmpresaCrmGravacaoTypes;
const { obterCampoMultiselect, obterCampo } = CamposTypes;

class ParametrosMapping {
  public static obterPermissoesUsuarioMapping = (permissoesUsuarioResult: PermissoesUsuarioResult): PermissoesUsuario => {
    const permissoesUsuario = obterPermissoesUsuario(
      permissoesUsuarioResult.CrmPermissoesClientesExclusao,
      permissoesUsuarioResult.CrediarioFacilPermissaoAcessoFichaCliente,
      permissoesUsuarioResult.CrmPermissoesClientesInclusao,
      permissoesUsuarioResult.CrmPermissoesClientesPesquisaRelatorio,
      permissoesUsuarioResult.CrmPermissoesClientesAlteracao,
      permissoesUsuarioResult.CrmPermissoesFornecedoresInclusao,
      permissoesUsuarioResult.CrmPermissoesFornecedoresAlteracao,
      permissoesUsuarioResult.CrmPermissoesFornecedoresExclusao,
      permissoesUsuarioResult.CrmPermissoesTransportadoresInclusao,
      permissoesUsuarioResult.CrmPermissoesTransportadoresAlteracao,
      permissoesUsuarioResult.CrmPermissoesTransportadoresExclusao,
      permissoesUsuarioResult.CrmPermissoesClientesDocumentoNaoObrigatorio,
      permissoesUsuarioResult.CrmPermissoesFornecedoresDocumentoNaoObrigatorio,
      permissoesUsuarioResult.CrmPermissoesTransportadoresDocumentoNaoObrigatorio,
      permissoesUsuarioResult.CrmBloquearAlteracaoDocumento,
      permissoesUsuarioResult.CrmBloquearAlteracaoNomeRazaoSocial,
      permissoesUsuarioResult.CrmBloquearAlteracaoNomeFantasia,
      permissoesUsuarioResult.CrmBloquearAlteracaoIdentidade,
      permissoesUsuarioResult.CrmBloquearAlteracaoDataNascimento,
      permissoesUsuarioResult.CrmNaoAlterarLimiteCompraRenda,
      permissoesUsuarioResult.CrmBloquearAlteracaoMae,
      permissoesUsuarioResult.CrmBloquearAlteracaoPai,
      permissoesUsuarioResult.CrmInformarClasseSubClasseCliente,
      permissoesUsuarioResult.CrmValorMaximoConcessaoLimiteCredito,
      permissoesUsuarioResult.CrmAlterarPlanosPagamentoCliente,
      permissoesUsuarioResult.CrmPermissaoDesbloqueioCliente,
      permissoesUsuarioResult.CrmEditarObservacaoCliente,
      permissoesUsuarioResult.CrmAnonimizarDadosCliente,
    );

    return permissoesUsuario;
  };

  public static obterParametrosCrmMapping = (
    parametrosCrmResult: ParametrosCrmResult,
    parametrosEmpresaResult: ParametrosEmpresaResult,
  ): ParametrosCrm => {
    const parametrosCrm = obterParametrosCrm(
      parametrosCrmResult.ExigeClasse,
      parametrosCrmResult.ExigeDataNascimento,
      parametrosCrmResult.ExigeSexo,
      parametrosCrmResult.ExigeEmail,
      parametrosCrmResult.ExigeTelefone,
      parametrosCrmResult.ExigeCelular,
      parametrosEmpresaResult.ExigeRua,
      parametrosEmpresaResult.ExigeNumero,
      parametrosEmpresaResult.ExigeBairro,
      parametrosEmpresaResult.ExigeCidade,
      parametrosCrmResult.ExigeUf,
      parametrosEmpresaResult.ExigeInscricaoEstadual,
      parametrosEmpresaResult.EstadoEmp,
      parametrosEmpresaResult.SistemaTributacao,
      parametrosEmpresaResult.UsaConvenios,
      parametrosEmpresaResult.ExigeCep,
      parametrosEmpresaResult.CidadeEmp,
      parametrosEmpresaResult.FoneDDD,
      parametrosCrmResult.ExigeDocProspect,
      parametrosCrmResult.ExigeTelefoneProspect,
      parametrosCrmResult.ExigeEmailProspect,
      parametrosEmpresaResult.FaturamentoLiberado,
      parametrosEmpresaResult.CompartilharClientesEntreUnidades,
      parametrosEmpresaResult.EmpresaOptanteSimplesEstadual,
      true,
      parametrosCrmResult.PortalFranqueador,
      parametrosCrmResult.CadastroBiometricoCliente,
    );

    return parametrosCrm;
  };

  public static obterParametrosPortalMapping = (parametrosPortalResult: ParametrosPortalResult): ParametrosPortal => {
    return {
      clientesFornecInternacionais: parametrosPortalResult.ClientesFornecInternacionais,
      compartilharClientes: parametrosPortalResult.CompartilharClientes,
      utilizaIntegracaoOC: parametrosPortalResult.UtilizaIntegracaoOC,
      utilizaNewsletter: parametrosPortalResult.UtilizaNewsletter,
      empModNfe: parametrosPortalResult.EmpModNfe,
      empModSpedFiscal: parametrosPortalResult.EmpModSpedFiscal,
      empModSpedPisCofins: parametrosPortalResult.EmpModSpedPisCofins,
    } as ParametrosPortal;
  };

  public static obterParametrosCrmGravacaoMapping = (
    obterParametrosCrmGravacaoResult: ObterParametrosCrm,
  ): ParametrosCrmGravacao => {
    const parametrosCrmGravacao = obterParametrosCrmGravacao(
      obterCampo(obterParametrosCrmGravacaoResult.TipoClienteBaseCentral || ''),
      obterCampo(obterParametrosCrmGravacaoResult.AtualizarBaseCentralClientesPor || ''),
      obterCampo(obterParametrosCrmGravacaoResult.CadastroClientesCentral),
      obterCampo(obterParametrosCrmGravacaoResult.ExigeClasse),
      obterCampo(obterParametrosCrmGravacaoResult.ExigeEmail),
      obterCampo(obterParametrosCrmGravacaoResult.ExigeDataNascimento),
      obterCampo(obterParametrosCrmGravacaoResult.ExigeSexo),
      obterCampo(obterParametrosCrmGravacaoResult.ExigeTelefone),
      obterCampo(obterParametrosCrmGravacaoResult.ExigeCelular),
      obterCampo(obterParametrosCrmGravacaoResult.ExigeUf),
      obterCampo(obterParametrosCrmGravacaoResult.ExigeDocProspect),
      obterCampo(obterParametrosCrmGravacaoResult.ExigeTelefoneProspect),
      obterCampo(obterParametrosCrmGravacaoResult.ExigeEmailProspect),
      obterCampo(obterParametrosCrmGravacaoResult.PortalFranqueador),
      obterCampo(obterParametrosCrmGravacaoResult.CadastroBiometricoCliente),
    );
    return parametrosCrmGravacao;
  };

  public static obterParametrosEmpresaCrmGravacaoMapping = (
    obterParametrosEmpresaCrmGravacaoResult: ObterParametrosEmpresaCrm,
  ): ParametrosEmpresaCrmGravacao => {
    const parametrosEmpresaCrmGravacao = obterParametrosEmpresaCrmGravacao(
      obterCampo(obterParametrosEmpresaCrmGravacaoResult.ExigeCep),
      obterCampo(obterParametrosEmpresaCrmGravacaoResult.ExigeRua),
      obterCampo(obterParametrosEmpresaCrmGravacaoResult.ExigeNumero),
      obterCampo(obterParametrosEmpresaCrmGravacaoResult.ExigeBairro),
      obterCampo(obterParametrosEmpresaCrmGravacaoResult.ExigeCidade),
    );
    return parametrosEmpresaCrmGravacao;
  };

  public static obterSalvarParametrosCrmDTO = (
    parametrosCrmAtualizacao: ParametrosCrmGravacao,
    parametrosEmpresaCrmAtualizacao: ParametrosEmpresaCrmGravacao,
  ): ParametrosCrmDTO => {
    const parametrosCrmDTO = {
      TipoClienteBaseCentral: parametrosCrmAtualizacao.tipoClienteBaseCentral.valor,
      AtualizarBaseCentralClientesPor: parametrosCrmAtualizacao.atualizarBaseCentralClientesPor.valor,
      CadastroClientesCentral: parametrosCrmAtualizacao.cadastroClientesCentral.valor,
      ExigeClasse: parametrosCrmAtualizacao.exigeClasse.valor,
      ExigeEmail: parametrosCrmAtualizacao.exigeEmail.valor,
      ExigeDataNascimento: parametrosCrmAtualizacao.exigeDataNascimento.valor,
      ExigeSexo: parametrosCrmAtualizacao.exigeSexo.valor,
      ExigeTelefone: parametrosCrmAtualizacao.exigeTelefone.valor,
      ExigeCelular: parametrosCrmAtualizacao.exigeCelular.valor,
      ExigeUf: parametrosCrmAtualizacao.exigeUf.valor,
      ExigeDocProspect: parametrosCrmAtualizacao.exigeDocProspect.valor,
      ExigeTelefoneProspect: parametrosCrmAtualizacao.exigeTelefoneProspect.valor,
      ExigeEmailProspect: parametrosCrmAtualizacao.exigeEmailProspect.valor,
      ExigeCep: parametrosEmpresaCrmAtualizacao.exigeCep.valor,
      ExigeRua: parametrosEmpresaCrmAtualizacao.exigeRua.valor,
      ExigeNumero: parametrosEmpresaCrmAtualizacao.exigeNumero.valor,
      ExigeBairro: parametrosEmpresaCrmAtualizacao.exigeBairro.valor,
      ExigeCidade: parametrosEmpresaCrmAtualizacao.exigeCidade.valor,
    } as ParametrosCrmDTO;

    return parametrosCrmDTO;
  };
}

export default ParametrosMapping;
