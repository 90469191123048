
  import { computed, defineComponent } from 'vue';

  import { vxm } from '@/store/index';

  import Campo from '@/components/shared/Campo.vue';
  import Input from '@/components/shared/Input.vue';
  import Multiselect from '@/components/shared/Multiselect.vue';
  import Money from '@/components/shared/Money.vue';

  import CampoTypes, { CampoMultiselect } from '@/core/types/campos';

  export default defineComponent({
    name: 'SecaoConvenioCadastro',
    components: {
      Campo,
      Input,
      Multiselect,
      Money,
    },
    props: {
      idSecaoMenu: {
        type: String,
        default: '',
        required: true,
      },
    },
    setup() {
      const isTransportador = computed((): boolean => vxm.cadastro.pessoa.transportadorIdentificacao.valor);
      const isConveniador = computed((): boolean => vxm.cadastro.pessoa.conveniadorIdentificacao.valor);

      const multiselectOptionsDiasDoMes = computed((): Array<CampoMultiselect> => {
        const diasDoMes = [] as Array<CampoMultiselect>;
        for (let dia = 1; dia <= 31; dia++) diasDoMes.push(CampoTypes.obterCampoMultiselect(dia, dia.toString()));
        return diasDoMes;
      });

      const isShowConveniador = computed((): boolean => {
        return vxm.cadastro.isPessoaFornecedor || isConveniador.value
      });
      const isShowConveniado = computed((): boolean => {
        return vxm.cadastro.isPessoaCliente || (vxm.cadastro.isPessoaAmbos || vxm.cadastro.isPessoaAmbosTransportador) && !isConveniador.value
      });

      return {
        ...vxm,
        multiselectOptionsDiasDoMes,
        isShowConveniador,
        isShowConveniado,
      };
    },
  });
