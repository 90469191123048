/* eslint-disable no-var */
import { vxm } from '@/store/index';

import CampoTypes, { Campo, Erro, TipoValidacao } from '@/core/types/campos';
import ValidacoesExtension from '@/core/validations/validacoes';

const { isCampo } = CampoTypes;
const {
  validarCpf,
  validarCep,
  validarCnpj,
  validarEmail,
  validarObrigatoriedade,
  validarTelefone,
  validarCelular,
  validarDataFutura,
  validarMenorDeIdade,
  validarLimiteCrediario,
  validarDiferencialIcms,
  validarInscricaoEstadual,
  validarGuid,
} = ValidacoesExtension;

class ValidacoesHandler {
  public static validarCampo = <T extends number | string | boolean>(campo: Campo<T>, entidade?: any): boolean => {
    const errosAux = [] as Array<Erro>;
    const { label, valor, validacoes, erros } = campo;

    validacoes.forEach((tipoValidacao) => {
      const erro = {} as Erro;
      let isValido = true;

      switch (tipoValidacao) {
        case TipoValidacao.required:
          erro.mensagem = `${label} é um campo obrigatório.`;
          if ((label === 'Celular' || label === 'Telefone principal') && valor.toString().length == 2) isValido = false;
          else isValido = validarObrigatoriedade(valor);
          break;
        case TipoValidacao.cpf:
          erro.mensagem = `${label} inválido.`;
          isValido = validarCpf(`${valor}`);
          break;
        case TipoValidacao.cnpj:
          erro.mensagem = `${label} inválido.`;
          isValido = validarCnpj(`${valor}`);
          break;
        case TipoValidacao.cep:
          erro.mensagem = `${label} inválido.`;
          isValido = validarCep(`${valor}`);
          break;
        case TipoValidacao.email:
          erro.mensagem = `${label} inválido.`;
          isValido = validarEmail(`${valor}`);
          break;
        case TipoValidacao.telefone:
          erro.mensagem = `${label} não corresponde com o padrão brasileiro de telefonia.<br>Telefone Fixo: (99) 9999-9999 ou Celular: (99) 99999-9999`;
          isValido = validarTelefone(`${valor}`);
          break;
        case TipoValidacao.celular:
          erro.mensagem = `${label} não corresponde com o padrão brasileiro de telefonia.<br>Celular: (99) 99999-9999`;
          isValido = validarCelular(`${valor}`);
          break;
        case TipoValidacao.bloquearDataFutura:
          erro.mensagem = `${label} inválida. Não deve ser maior que a data atual.`;
          isValido = validarDataFutura(`${valor}`);
          break;
        case TipoValidacao.bloquearMenorDeIdade:
          erro.mensagem = `${label} inválida. Obrigatório ser maior que 18 anos.`;
          isValido = validarMenorDeIdade(`${valor}`);
          break;
        case TipoValidacao.limiteCrediario:
          erro.mensagem = `${label} é maior do que o limite concedido.`;
          isValido = validarLimiteCrediario(Number(valor), vxm.parametros.permissoesUsuario.valorMaximoConcessaoLimiteCredito);
          break;
        case TipoValidacao.diferencialIcms:
          erro.mensagem = `${label} não existe para clientes no mesmo estado da empresa. Desabilite o checkbox para continuar.`;
          isValido = validarDiferencialIcms(
            vxm.cadastro.pessoa.endereco.estado.descricao as string,
            vxm.parametros.parametrosCrm.estadoEmpresa,
            vxm.cadastro.pessoa.fornecedor.isDiferencialIcms.valor,
          );
          break;
        case TipoValidacao.inscricaoEstadual:
          erro.mensagem = `${label} inválida.`;
          var descricaoEstado = '';
          if (entidade.estado) descricaoEstado = entidade.estado.descricao;
          if (entidade.endereco) descricaoEstado = entidade.endereco.estado.descricao;
          isValido = validarInscricaoEstadual(`${valor}`, `${descricaoEstado}`);
          break;
        case TipoValidacao.guid:
          erro.mensagem = `${label} inválida.`;
          isValido = validarGuid(`${valor}`);
          break;
      }

      if (!isValido) errosAux.push(erro);
    });

    campo.erros = errosAux;

    return campo.erros.length === 0;
  };

  public static validarEntidade = (entidade: any, isEntidadeValida = true): boolean => {
    Object.keys(entidade).forEach((key) => {
      if (entidade[key] && isCampo(entidade[key])) {
        const isCampoValido = ValidacoesHandler.validarCampo(entidade[key], entidade);
        if (!isCampoValido) isEntidadeValida = false;
      } else if (typeof entidade[key] === 'object')
        isEntidadeValida = ValidacoesHandler.validarEntidade(entidade[key], isEntidadeValida);
      else return;
    });

    return isEntidadeValida;
  };
}

export default ValidacoesHandler;
