import ApiConfig from '@/api/utils/apiConfig';

class CrmRoutes {
  private getUrlBase = () => ApiConfig.getUrlCrmApi();
  // private getUrlBase = () => 'https://localhost:44357';

  public cadastro = {
    obterDadosIniciais: () => `${this.getUrlBase()}/api/CadastroCompleto/ObterDadosIniciais`,
    obterPessoaPorDocumento: () => `${this.getUrlBase()}/api/CadastroCompleto/ObterPessoaPorDocumento`,
    obterPessoa: () => `${this.getUrlBase()}/api/CadastroCompleto/ObterPessoa`,
    inserirPessoa: () => `${this.getUrlBase()}/api/CadastroCompleto/InserirPessoa`,
    atualizarPessoa: () => `${this.getUrlBase()}/api/CadastroCompleto/AtualizarPessoa`,
    atualizarPessoaBancos: () => `${this.getUrlBase()}/api/CadastroCompleto/AtualizarPessoaBancos`,
    anonimizarPessoa: () => `${this.getUrlBase()}/api/CadastroCompleto/AnonimizarPessoa`,
    obterCepPorEndereco: () => `${this.getUrlBase()}/api/CadastroRapido/ObterCEPporEndereco`,
    atualizarPermissoes: () => `${this.getUrlBase()}/api/CadastroCompleto/AtualizarDireitosTabela`,
    permiteExcluirContato: () => `${this.getUrlBase()}/api/Cliente/PermiteExcluirContato`
  };

  public listagem = {
    obterDadosIniciaisListagem: () => `${this.getUrlBase()}/api/ListagemClientes/ObterDadosIniciais`,
    obterListagemClientes: () => `${this.getUrlBase()}/api/ListagemClientes/ListarUltimosClientesAtualizados`,
    listarFiltrosPesquisaAvancada: () => `${this.getUrlBase()}/api/ListagemClientes/ListarFiltrosDaPesquisaAvancada`,
    listarOpcoesDoFiltro: () => `${this.getUrlBase()}/api/ListagemClientes/ListarOpcoesDoFiltro`,
    pesquisarClientes: () => `${this.getUrlBase()}/api/ListagemClientes/PesquisarClientes`,
    usuariosSupervisores: () => `${this.getUrlBase()}/api/CadastroCompleto/ListarUsuariosSupervisores`,
  };

  public importacao = {
    hub: () => `${this.getUrlBase()}/importacaoClientesHub`,
    obterDadosIniciaisPainelImportacao: () => `${this.getUrlBase()}/api/ImportacaoClientes/BuscarDadosIniciaisPainelImportacao`,
    buscarInconsistencias: () => `${this.getUrlBase()}/api/ImportacaoClientes/BuscarInconsistencias?IdImportacaoClientes=`,
    downloadArquivoInconsistencias: () =>
      `${this.getUrlBase()}/api/ImportacaoClientes/DownloadArquivoComInconsistencias?IdImportacaoClientes=`,
    downloadArquivoModelo: () => `${this.getUrlBase()}/api/ImportacaoClientes/DownloadArquivoModelo`,
    cancelarImportacao: () => `${this.getUrlBase()}/api/ImportacaoClientes/CancelarImportacao`,
    importarClientesPorArquivo: () => `${this.getUrlBase()}/api/ImportacaoClientes/ImportarClientesPorArquivo`,
    importarClientesPorArquivoV2: () => `${this.getUrlBase()}/api/ImportacaoClientes/ImportarClientesPorArquivoV2`,
  };

  public anexos = {
    inserirAnexo: () => `${this.getUrlBase()}/api/Anexo/InserirAnexo`,
    obterAnexo: () => `${this.getUrlBase()}/api/Anexo/ObterAnexo`,
    excluirAnexo: () => `${this.getUrlBase()}/api/Anexo/ExcluirAnexo`,
    listarAnexosPessoa: () => `${this.getUrlBase()}/api/Anexo/ListarAnexosPessoa`,
    ListarAnexosPessoaPorDoc: () => `${this.getUrlBase()}/api/Anexo/ListarAnexosPessoaPorDoc`,
  };

  public docs = {
    inserirDoc: () => `${this.getUrlBase()}/api/Documento/InserirDocumento`,
    obterDoc: () => `${this.getUrlBase()}/api/Documento/ObterDocumento`,
    excluirDoc: () => `${this.getUrlBase()}/api/Documento/ExcluirDocumento`,
    listarDocsPessoa: () => `${this.getUrlBase()}/api/Documento/ListarDocumentosPessoa`,
    ListarDocsPessoaPorDoc: () => `${this.getUrlBase()}/api/Documento/ListarDocumentosPessoaPorDoc`,
    obterFotoPessoa: () => `${this.getUrlBase()}/api/Documento/ObterFotoPessoa`,
    inserirFotoPessoa: () => `${this.getUrlBase()}/api/Documento/InserirFotoPessoa`,
    excluirFoto: () => `${this.getUrlBase()}/api/Documento/ExcluirFoto`,
  };

  public parametros = {
    obterParametrosCrm: () => `${this.getUrlBase()}/api/Parametros/ObterParametrosCrm`,
    atualizarParametrosCrm: () => `${this.getUrlBase()}/api/Parametros/AtualizarParametrosCrm`,
    obterParametrosEmpresaCrm: () => `${this.getUrlBase()}/api/Parametros/ObterParametrosEmpresaCrm`,
  };

  public biometria = {
    atualizar: () => `${this.getUrlBase()}/api/Biometria/Atualizar`,
    validar: () => `${this.getUrlBase()}/api/Biometria/Validar`,
  };
  
  public analiseCredito = {
    gerarAnaliseCredito: () => `${this.getUrlBase()}/api/AnaliseCredito/GerarAnaliseCredito`,
  };
}

export default new CrmRoutes();
