
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'AvisoScarf',

    setup() {
      const titulo = 'Scarf';

      const instalarAplicativo = () => {
        window.open('https://setupbr.microvix.com.br/scarf/Linx_Microvix_Scarf.exe', '_self');
      };
      const iniciarAplicativo = () => {
        window.open('linxscarf:', '_self');
      };

      return { titulo, instalarAplicativo, iniciarAplicativo };
    },
  });
