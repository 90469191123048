
  import { defineComponent, reactive, toRefs, onMounted, computed, inject, watchEffect, watch, ref, nextTick } from 'vue';
  import { vxm } from '@/store/index';
  import Campo from '@/components/shared/Campo.vue';
  import Multiselect from '@/components/shared/Multiselect.vue';
  import Input from '@/components/shared/Input.vue';
  import AlertsExtension from '@/utils/alerts';

  export default defineComponent({
    name: 'pesquisa-filtro-campos-adicionais',
    components: {
      Campo,
      Multiselect,
      Input,
    },
    props: {
      idFiltro: { type: Number, default: 0 },
      nomeFiltro: { type: String, default: '' },
      command: { type: String, default: '' },
    },
    setup(props) {
      const data = reactive({
        show: false,
        idCampoAdicionalSelecionado: 0 as number,
        valorCampoAdicional: '' as string,
        listaCamposAdicionais: [] as any,
        listaCamposAdicionaisDetalhes: [] as any,
        listaCamposAdicionaisValores: [] as any,
        tipoCampoSelecionado: 0,
      });

      const getTipoCampo = (): number => {
        if (data.idCampoAdicionalSelecionado) {
          const campoAdicional = data.listaCamposAdicionais.find((campo: any) => campo.id == data.idCampoAdicionalSelecionado);
          return campoAdicional?.tipoCampo ?? 0;
        }
        return 0;
      };

      const limparFiltroPesquisaCamposAdicionais = (): void => {
        vxm.listagem.filtros.CamposAdicionais = null;
      };

      const setFiltroPesquisaCamposAdicionais = (valor: string): void => {
        if (data.idCampoAdicionalSelecionado) {
          vxm.listagem.filtros.CamposAdicionais = {
            IdCampoAdicional: data.idCampoAdicionalSelecionado ?? 0,
            TipoCampo: getTipoCampo(),
            Valor: valor,
          };
        }
      };

      const tratarFiltroCamposAdicionais = (response: any): void => {
        data.listaCamposAdicionais = response.Opcoes.map((opcao: any) => {
          return {
            id: opcao.Id,
            descricao: opcao.Descricao,
            tipoCampo: opcao.TipoCampo,
          };
        });

        data.listaCamposAdicionaisDetalhes = response.Opcoes.map((opcao: any) => {
          let detalhes = [];
          if (opcao.TipoCampo === vxm.listagem.tiposCamposAdicionais.SimNao) {
            detalhes = [
              {
                id: 'S',
                descricao: 'Sim',
              },
              {
                id: 'N',
                descricao: 'Não',
              },
            ];
          } else if (opcao.TipoCampo === vxm.listagem.tiposCamposAdicionais.PreOpcional) {
            detalhes = opcao.Detalhes.map((detalhe: any) => {
              return {
                id: detalhe.Id,
                descricao: detalhe.Descricao,
              };
            });
          }

          return {
            id: opcao.Id,
            detalhes: detalhes,
          };
        });
      };

      const getListaCamposDetalhes = (): Promise<any> => {
        return nextTick(() => {
          if (data.idCampoAdicionalSelecionado) {
            return (
              data.listaCamposAdicionaisDetalhes.find((d: any) => d.id == data.idCampoAdicionalSelecionado)?.detalhes ??
              ([] as any)
            );
          }

          return [] as any;
        });
      };

      onMounted(() => {
        methods.buscarCamposAdicionais();
      });

      watchEffect(() => {
        if (data.idCampoAdicionalSelecionado) {
          data.valorCampoAdicional = '';
          setFiltroPesquisaCamposAdicionais('');
        } else {
          data.valorCampoAdicional = '';
          limparFiltroPesquisaCamposAdicionais();
        }

        data.tipoCampoSelecionado = getTipoCampo();
        getListaCamposDetalhes().then((value) => (data.listaCamposAdicionaisValores = value));
      });

      watch(
        () => data.valorCampoAdicional,
        (value) => {
          setFiltroPesquisaCamposAdicionais(value);
        },
      );

      const erros = computed(() => {
        if (data.tipoCampoSelecionado === vxm.listagem.tiposCamposAdicionais.Texto) {
          if (data.valorCampoAdicional.length < 3) {
            return [
              {
                mensagem: 'Informe no minímo 3 caracteres',
              },
            ];
          }
        }
        return [];
      });

      const camposAdicionais = computed(() => {
        return {
          label: '',
          descricao: '',
          valor: '',
          erros: [] as any,
          validacoes: [] as any,
        };
      });

      const disableInput = computed(() => !data.idCampoAdicionalSelecionado);
      const emitter: any = inject('emitter');
      const methods = reactive({
        buscarCamposAdicionais: () => {
          vxm.loading.showLoading('Buscando opções de filtro...');
          const command = {
            IdFiltro: props.idFiltro,
          };

          vxm.listagem
            .listaOpcoesDoFiltro(command)
            .then((response: any) => {
              tratarFiltroCamposAdicionais(response);
            })
            .catch((error) => {
              AlertsExtension.exibirMensagemErro(error, false);
            })
            .finally(() => vxm.loading.hideLoading());
        },
        removerFiltro: () => {
          data.idCampoAdicionalSelecionado = 0;
          emitter.emit('opcao-filtro-selecionada', props.command);
          emitter.emit('remover-filtro-selecionado', props.idFiltro);
        },
        fecharFiltro: () => {
          data.show = false;
        },
      });

      return {
        ...toRefs(data),
        ...toRefs(methods),
        camposAdicionais,
        disableInput,
        erros,
        vxm,
      };
    },
  });
