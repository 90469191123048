export enum FormaPagamento {
    Dinheiro = 1,
    ChqVista = 2,
    ChqPrazo =  3,
    Crediario = 4,
    Cartao = 5,
    Convenio = 6
  };

  class PagamentoEnum{

    public static getDescricaoFormaPagamento = (formaPagamento: FormaPagamento): string => {
        switch (formaPagamento) {
        case FormaPagamento.Dinheiro:
            return 'Dinheiro';
        case FormaPagamento.ChqVista:
            return 'Cheque á Vista';
        case FormaPagamento.ChqPrazo:
            return 'Cheque á Prazo';
        case FormaPagamento.Crediario:
            return 'Crediário';
        case FormaPagamento.Cartao:
            return 'Cartão';
        case FormaPagamento.Convenio:
            return 'Convênio';
        default:
            return 'Não informado';
        }
    };
}

export default PagamentoEnum;