import { createModule, mutation, action } from 'vuex-class-component';

const VuexModule = createModule({
  namespaced: 'loading',
  strict: false,
});

export default class ParametrosModule extends VuexModule {
  public isLoading = false;
  private mensagem = '';
  private quantidadeRequisicoes = 0;

  @mutation
  public showLoading(mensagem?: string): void {
    this.mensagem = mensagem ? mensagem : '';
    this.quantidadeRequisicoes++;
    this.isLoading = this.quantidadeRequisicoes != 0 ? true : false;
  }

  @mutation
  public hideLoading(): void {
    this.quantidadeRequisicoes--;
    this.isLoading = this.quantidadeRequisicoes != 0 ? true : false;
  }
}
