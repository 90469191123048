
  import { defineComponent, PropType } from 'vue';
  import VueScrollTo from 'vue-scrollto';

  import { Secao } from '@/core/types/menu';

  export default defineComponent({
    name: 'MenuNavegacao',
    props: {
      containerId: {
        type: String,
        default: '',
        required: true,
      },
      secoes: {
        type: Array as PropType<Array<Secao>>,
        default: () => [],
        required: false,
      },
    },
    setup(props) {
      const scrollar = (secao: Secao): void => {
        const el = document.getElementById(secao.id);
        const options = {
          container: `#${props.containerId}`,
        };
        VueScrollTo.scrollTo(el, 500, options);
      };
      return {
        scrollar,
      };
    },
  });
