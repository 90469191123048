
  import { defineComponent, reactive, toRefs, onMounted, computed, ref } from 'vue';
  import AlertsExtension from '../../../utils/alerts';
  import BaseModal from '../../shared/BaseModal.vue';
  import { vxm } from '@/store/index';

  export default defineComponent({
    name: 'ModalVisualizarErros',
    components: { BaseModal },
    setup(props, context) {
      const data = reactive({
        nomeArquivo: '',
        registrosPorPagina: ref(10),
        ordenacao: {
          Campo: 'Linha',
          Tipo: 1,
          Enum: { Asc: 1, Desc: 0 },
        },
        paginacao: {
          paginaAtual: 1,
        },
      });
      onMounted(() => {
        methods.buscarInconsistencias();
      });

      const listaOrdenada = computed(() => {
        if (!vxm.importacao.inconsistenciasDaImportacaoSelecionada) return [];
        return vxm.importacao.inconsistenciasDaImportacaoSelecionada.Inconsistencias.sort((a: any, b: any): number => {
          if (data.ordenacao.Tipo == data.ordenacao.Enum.Asc) return a[data.ordenacao.Campo] - b[data.ordenacao.Campo];
          if (data.ordenacao.Tipo == data.ordenacao.Enum.Desc) return b[data.ordenacao.Campo] - a[data.ordenacao.Campo];
          return a[data.ordenacao.Campo] - b[data.ordenacao.Campo];
        });
      });
      const totaldePaginas = computed(() => {
        return Math.ceil(listaOrdenada.value.length / data.registrosPorPagina);
      });

      const indexInicial = computed(() => {
        return data.registrosPorPagina * (data.paginacao.paginaAtual - 1);
      });

      const indexFinal = computed(() => {
        let indexFinal = Number(data.registrosPorPagina) * (data.paginacao.paginaAtual - 1) + Number(data.registrosPorPagina);

        return (indexFinal = +indexFinal);
      });
      const registrosTotais = computed(() => {
        return listaOrdenada.value.length;
      });
      const registrosAtuais = computed(() => {
        if (registrosTotais.value === 0) return 0;
        if (registrosTotais.value < data.registrosPorPagina) return registrosTotais.value;

        if (indexFinal.value > registrosTotais.value) return registrosTotais.value;

        return indexFinal.value;
      });
      const registrosComeco = computed(() => {
        if (registrosTotais.value === 0) return 0;

        if (data.registrosPorPagina > registrosTotais.value) return 1;

        return indexInicial.value + 1;
      });

      const methods = reactive({
        buscarInconsistencias() {
          vxm.loading.showLoading();
          let idImportacao = vxm.importacao.importacaoSelecionada?.Id;
          if (!idImportacao) return;
          vxm.importacao
            .buscarInconsistenciasImportacao(idImportacao)
            .then(() => {
              if (vxm.importacao.inconsistenciasDaImportacaoSelecionada)
                data.nomeArquivo = vxm.importacao.inconsistenciasDaImportacaoSelecionada.NomeArquivo;
            })
            .catch((error) => {
              AlertsExtension.exibirMensagemErro(error, false);
            })
            .finally(() => {
              vxm.loading.hideLoading();
            });
        },
        obterClasseOrdenacao: (nomeColuna: string) => {
          document.getElementById('icon');
          let sort = 'fas fa-sort';
          let sortAsc = 'fas fa-sort-up';
          let sortDesc = 'fas fa-sort-down';

          if (nomeColuna != data.ordenacao.Campo) return sort;

          if (nomeColuna == data.ordenacao.Campo && data.ordenacao.Tipo == data.ordenacao.Enum.Asc) return sortAsc;

          return sortDesc;
        },
        ordenarPor: (nomeColuna: string) => {
          if (nomeColuna != data.ordenacao.Campo) {
            data.ordenacao.Campo = nomeColuna;
            data.ordenacao.Tipo = data.ordenacao.Enum.Asc;
          }

          if (nomeColuna == data.ordenacao.Campo && data.ordenacao.Tipo == data.ordenacao.Enum.Asc)
            return (data.ordenacao.Tipo = data.ordenacao.Enum.Desc);

          return (data.ordenacao.Tipo = data.ordenacao.Enum.Asc);
        },
        verificarSeExibeBotaoPagina: (pagina: number) => {
          return (
            data.paginacao.paginaAtual == pagina ||
            data.paginacao.paginaAtual + 1 == pagina ||
            data.paginacao.paginaAtual - 1 == pagina ||
            1 == pagina ||
            2 == pagina
          );
        },
        setaDataValoresDefault: () => {
          data.registrosPorPagina = 10;
          data.ordenacao = {
            Campo: 'Linha',
            Tipo: 1,
            Enum: { Asc: 1, Desc: 0 },
          };
          data.paginacao = {
            paginaAtual: 1,
          };
        },
        closeModal: () => {
          context.emit('update:is-open', false);
        },
      });

      return {
        ...vxm,
        ...toRefs(methods),
        listaOrdenada,
        totaldePaginas,
        indexInicial,
        indexFinal,
        registrosAtuais,
        registrosComeco,
        registrosTotais,
        ...toRefs(data),
      };
    },
  });
