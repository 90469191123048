import { mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("input", _mergeProps(_ctx.$attrs, {
    id: _ctx.id,
    class: _ctx.defaultStyleClass,
    maxlength: _ctx.maxLength,
    onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleUpdateModelValue($event.target.value))),
    value: _ctx.filteredInputValue,
    onKeypress: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleAllowOnlyNumbersOrLetters($event)))
  }), null, 16, ["id", "maxlength", "value"]))
}