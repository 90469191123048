
  import { defineComponent, reactive, ref, toRefs } from 'vue';
  import { useDropzone } from 'vue3-dropzone';
  import AlertsExtension from '../../utils/alerts';
  import mitt from 'mitt';
  export default defineComponent({
    name: 'UploadArquivo',
    props: {
      msg: String,
      arquivo: [],
      nomeArquivo: String,
    },
    emits: ['arquivo-inserido'],
    setup(props, context) {
      const eventBus = mitt();
      let myVueDropzone = ref(null);
      function onDrop(acceptedFiles: any, rejectReasons: any) {
        if (acceptedFiles.length < 0) {
          myVueDropzone = ref(null);
        }
        if (rejectReasons.length > 0) {
          AlertsExtension.exibirMensagemErro('Oops!! Apenas arquivos com a extensão .xls ou .xlsx são aceitos.', false);
        }
        if (acceptedFiles.length > 0) {
          options.arquivoInserido = true;
          options.nomeArquivo = acceptedFiles[0].name;
          context.emit('arquivo-inserido', acceptedFiles);
          AlertsExtension.exibirMensagemSucesso('Legal!! O upload foi realizado com sucesso.', false);
        }
      }

      function updateTitle(acceptedFiles: any) {
        var file = acceptedFiles.getAcceptedFiles();
        eventBus.emit('update:arquivo', file);
      }
      const methods = reactive({
        disparaEventoImportarArquivo(acceptedFiles: any) {
          let file = acceptedFiles.getAcceptedFiles();
          eventBus.emit('arquivo-inserido', file);
        },
        removeArquivoManualmente: function (acceptedFiles: any, rejectReasons: any) {
          var file = acceptedFiles.getAcceptedFiles();
          file[0] && rejectReasons.removeFile(file[0]);
        },
      });

      const options = reactive({
        mostraTextoDropzone: true,
        multiple: false,
        onDrop,
        accept: '.xls, .xlsx',
        addRemoveLinks: true,
        arquivoInserido: false,
        nomeArquivo: ref(''),
      });
      const { getRootProps, getInputProps, ...rest } = useDropzone(options);
      return {
        ...toRefs(options),
        myVueDropzone,
        getRootProps,
        getInputProps,
        ...rest,
        ...toRefs(methods),
        updateTitle,
      };
    },
  });
