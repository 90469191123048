
  import { defineComponent, computed } from 'vue';
  import { vxm } from '@/store/index';

  import Campo from '@/components/shared/Campo.vue';
  import Input from '@/components/shared/Input.vue';
  import Money from '@/components/shared/Money.vue';
  import { Anexo } from '@/core/types/clientes/pessoa/adicionais';
  import AlertsExtension from '@/utils/alerts';
  import { useDropzone } from 'vue3-dropzone';
  import { FileUploadOptions } from 'vue3-dropzone/dist/useDropzone';
  import { TipoCliente } from '@/core/enums/pessoa';

  export default defineComponent({
    name: 'SecaoDadosAdicionais',
    props: {
      idSecaoMenu: {
        type: String,
        default: '',
        required: true,
      },
    },
    components: {
      Campo,
      Input,
      Money,
    },
    setup() {
      const { transportador, ambos, fornecedor } = TipoCliente;

      const isApresentarCampoUrlRastreioIO = computed((): boolean => {
        return vxm.cadastro.pessoa.tipoCliente.valor === transportador || vxm.cadastro.pessoa.tipoCliente.valor === ambos;
      });

      const isFornecedor = computed((): boolean => {
        return vxm.cadastro.pessoa.tipoCliente.valor === fornecedor || vxm.cadastro.pessoa.tipoCliente.valor === ambos;
      });

      const downloadAnexo = async (anexo: Anexo, indexAnexo: number): Promise<void> => {
        if (anexo.id) {
          await vxm.cadastro
            .downloadAnexo(anexo.id)
            .then((blobResponse) => {
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blobResponse, anexo.nomeAnexo);
                return;
              }
              const data = window.URL.createObjectURL(blobResponse);
              const link = document.createElement('a');
              link.href = data;
              link.download = anexo.nomeAnexo;
              link.click();
              setTimeout(function () {
                window.URL.revokeObjectURL(data);
              }, 100);
              return;
            })
            .catch((error) => {
              AlertsExtension.exibirMensagemErro(error, false);
            });
        }
      };
      const excluirAnexo = async (anexo: Anexo, indexAnexo: number): Promise<void> => {
        if (anexo.id) {
          const idAnexo = anexo.id;
          AlertsExtension.exibirMensagemPergunta('Confirmar exclusão', 'Tem certeza que deseja excluir este anexo?', (isSim) => {
            if (isSim)
              vxm.cadastro
                .excluirAnexo(idAnexo)
                .then(() => {
                  AlertsExtension.exibirMensagemSucesso('Anexo excluído com sucesso!', true);
                })
                .catch((error) => {
                  AlertsExtension.exibirMensagemErro(error, false);
                });
          });
        } else {
          vxm.cadastro.pessoa.dadosAdicionais.anexos.valor.splice(indexAnexo, 1);
        }
      };
      const removerDosAnexosParaInserir = (index: number): void => {
        vxm.cadastro.anexosParaInserir.splice(index, 1);
      };
      const tamanhoMaximoAnexo = 2097152;
      const tiposAceitos = ['.rar', '.txt', '.jpg', '.jpeg', '.gif', '.doc', '.docx', '.zip', '.pdf', '.xls', '.xlsx', '.png'];
      const mensagensRejeicao = {
        'file-invalid-type': `Formato do arquivo inválido. Tipos permitidos: ${tiposAceitos.join(', ')}`,
        'file-too-large': `Tamanho do arquivo excedido. Tamanho máximo: ${(tamanhoMaximoAnexo / 1024) / 1024}MB`,
        'file-too-small': 'Arquivo muito pequeno.',
        'too-many-files': 'Muitos arquivos simultâneos.',
      } as { [key: string]: string };
      const options: Partial<FileUploadOptions> = {
        accept: tiposAceitos,
        disabled: false,
        maxSize: tamanhoMaximoAnexo,
        minSize: 0,
        multiple: true,
        maxFiles: 10,
        onDrop: (acceptedFiles: any[], rejectReasons: any[], event: Event) => {
          acceptedFiles.forEach((anexo) => {
            vxm.cadastro.anexosParaInserir.push(anexo);
          });
          if (rejectReasons.length > 0) {
            let mensagemErro = '';
            rejectReasons.forEach((reason) => {
              mensagemErro += `${reason.file.name}: ${reason.errors
                .map((error: { code: string; message: string }) => mensagensRejeicao[error.code])
                .join(' ')}`;
              mensagemErro += '<br>';
            });
            AlertsExtension.exibirMensagemErro(mensagemErro, false);
          }
        },
        preventDropOnDocument: true,
        noClick: false,
        noKeyboard: false,
        noDrag: true,
        noDragEventsBubbling: true,
      };
      const { getRootProps, getInputProps, ...rest } = useDropzone(options);
      return {
        ...vxm,
        downloadAnexo,
        excluirAnexo,
        removerDosAnexosParaInserir,
        getRootProps,
        getInputProps,
        isApresentarCampoUrlRastreioIO,
        isFornecedor,
        ...rest,
      };
    },
  });
