<template>
  <div id="webcam">
    <div v-if="isCameraOpen" v-show="!isLoadingCamera" :class="{ flash: isShotPhoto }">
      <video v-show="!isPhotoTaken" ref="camera" :width="275" :height="200" autoplay></video>
      <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="275" :height="200"></canvas>
    </div>

    <div v-show="isCameraOpen && isLoadingCamera" class="camera-loading">
      <ul class="loader-circle">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>

    <div class="camera-button">
      <span v-if="!isCameraOpen"
        ><a href="#" @click="toggleCamera"><i class="fas fa-camera" style="font-size: 25px"></i><br />Abrir câmera</a></span
      >
      <span v-else><a href="#" @click="toggleCamera">Fechar câmera</a></span>
    </div>

    <div v-if="isCameraOpen && !isLoadingCamera" class="camera-shoot">
      <button type="button" class="btn btn-default w-100" @click="takePhoto">{{ labelCamera }}</button>
    </div>
  </div>
</template>
<script>
  import { vxm } from '@/store';
  export default {
    name: 'Webcam',
    data() {
      return {
        isCameraOpen: false,
        isPhotoTaken: false,
        isShotPhoto: false,
        isLoading: false,
        isLoadingCamera: false,
        labelCamera: 'Tirar Foto ',
        isLabelCamera: false,
      };
    },

    methods: {
      toggleCamera() {
        if (this.isCameraOpen) {
          this.isCameraOpen = false;
          this.isPhotoTaken = false;
          this.isShotPhoto = false;
          this.stopCameraStream();
          this.labelCamera = 'Tirar Foto';
          vxm.cadastro.cameraAberta = false;
        } else {
          this.isCameraOpen = true;
          vxm.cadastro.cameraAberta = true;
          this.camera = false;
          this.isLabelCamera = false;
          this.createCameraElement();
        }
      },

      createCameraElement() {
        this.isLoadingCamera = true;

        const constraints = (window.constraints = {
          audio: false,
          video: true,
        });

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            this.isLoadingCamera = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch((error) => {
            this.isLoadingCamera = false;
            alert('Pode o navegador não suportar ou há alguns erros.');
          });
      },

      stopCameraStream() {
        let tracks = this.$refs.camera.srcObject.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      },

      takePhoto() {
        if (!this.isPhotoTaken) {
          this.isShotPhoto = true;

          const FLASH_TIMEOUT = 50;

          setTimeout(() => {
            this.isShotPhoto = false;
          }, FLASH_TIMEOUT);
        }

        this.isPhotoTaken = !this.isPhotoTaken;

        const context = this.$refs.canvas.getContext('2d');
        context.drawImage(this.$refs.camera, 0, 0, 275, 200);

        this.uploadPhoto();

        if (this.isLabelCamera == false) {
          this.labelCamera = 'Tirar Nova Foto';
          this.isLabelCamera = true;
        } else {
          this.labelCamera = 'Tirar Foto';
          this.isLabelCamera = false;
        }
      },

      uploadPhoto() {
        const dataURL = this.$refs.canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
        let uniquePictureName = this.generateCapturePhotoName();
        let capturedPhotoFile = this.dataURLtoFile(dataURL, uniquePictureName + '.jpg');
        vxm.cadastro.fotoNovaParaInserir = capturedPhotoFile;
        vxm.cadastro.fotoCadastro = [
          {
            source: 1,
            options: {
              type: 'limbo',
              file: capturedPhotoFile,
            },
          },
        ];
      },

      generateCapturePhotoName() {
        return Math.random().toString(36).substring(2, 15);
      },

      dataURLtoFile(dataURL, filename) {
        let arr = dataURL.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .camera-box {
    border: 2px dashed #5b2e90;
    padding-top: 5px;
    padding-right: 5px;
    padding-left: 5px;
    min-height: 100px;
  }

  .camera-button {
    text-align: center;
    font-size: 14px;
  }

  .camera-loading {
    margin: 3rem 0 0 -1.2rem;

    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
      text-align: center;
    }

    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 5%;
      left: 90%;
      transform: translateY(-50%);
      transform: translateX(-50%);
      position: absolute;
      width: 100%;
      padding: 0;

      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background: #999;
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
</style>
