import { ParametrosEmpresaResult } from '@/api/contracts/crm/cadastro-completo/dadosIniciais';

export interface PermissoesUsuario {
  isPermitirExcluirCliente: boolean;
  isPermitirCrediarioAcessoFichaCliente: boolean;
  isPermitirIncluirCliente: boolean;
  isPermitirPesquisaRelatorioCliente: boolean;
  isPermitirAlterarCliente: boolean;
  isPermitirIncluirFornecedor: boolean;
  isPermitirAlterarFornecedor: boolean;
  isPermitirExcluirFornecedor: boolean;
  isPermitirIncluirTransportador: boolean;
  isPermitirAlterarTransportador: boolean;
  isPermitirExcluirTransportador: boolean;
  isPermitirDocumentoInvalidoCliente: boolean;
  isPermitirDocumentoInvalidoFornecedor: boolean;
  isPermitirDocumentoInvalidoTransportador: boolean;
  isPermitirInformarClasseSubclasse: boolean;
  isBloquearAlterarDocumento: boolean;
  isBloquearAlterarNomeRazaoSocial: boolean;
  isBloquearAlterarNomeFantasia: boolean;
  isBloquearAlterarIdentidade: boolean;
  isBloquearAlterarDataNascimento: boolean;
  isBloquearAlterarLimiteCompraRenda: boolean;
  isBloquearAlterarNomeMae: boolean;
  isBloquearAlterarNomePai: boolean;
  valorMaximoConcessaoLimiteCredito: number;
  alterarPlanosPagamentoCliente: boolean;
  desbloqueioClienteFaturamento: boolean;
  isPermitirEditarObservacaoCliente: boolean;
  isPermitirIncluirProspect: boolean;
  isPermitirAlterarProspect: boolean;
  isPermitirExcluirProspect: boolean;
  isPermitirDocumentoInvalidoProspect: boolean;
  isPermitirAnonimizarCliente: boolean;
}

export interface ParametrosCrm {
  isObrigatorioClasse: boolean;
  isObrigatorioDataNascimento: boolean;
  isObrigatorioSexo: boolean;
  isObrigatorioTelefonePrincipal: boolean;
  isObrigatorioTelefoneCelular: boolean;
  isObrigatorioEmail: boolean;
  isObrigatorioLogradouro: boolean;
  isObrigatorioNumero: boolean;
  isObrigatorioBairro: boolean;
  isObrigatorioCidade: boolean;
  isObrigatorioEstado: boolean;
  isObrigatorioInscricaoEstadual: boolean;
  estadoEmpresa: string;
  sistemaTributacao: string;
  usaConvenios: boolean;
  isObrigatorioCEP: boolean;
  cidadeEmpresa: string;
  foneDDD: string;
  isObrigatorioDocProspect: boolean;
  isObrigatorioTelefoneProspect: boolean;
  isObrigatorioEmailProspect: boolean;
  FaturamentoLiberado: boolean;
  compartilharClientesEntreUnidades: boolean;
  isEmpresaOptanteSimplesEstadual: boolean;
  isObrigatorioTransportadoraIO: boolean;
  portalFranqueador: number;
  isCadastroBiometricoCliente: boolean;
}

export interface ParametrosEmpresa {
  EstadoEmp: boolean;
  ExigeBairro: boolean;
  ExigeCep: boolean;
  ExigeCidade: boolean;
  ExigeInscricaoEstadual: boolean;
  ExigeNumero: boolean;
  ExigeRua: boolean;
  SistemaTributacao: string;
  UsaConvenios: boolean;
  UtilizaAnexo: boolean;
  cidadeEmp: string;
  FoneDDD: string;
}

export interface ParametrosPortal {
  utilizaIntegracaoOC: boolean;
  utilizaNewsletter: boolean;
  compartilharClientes: string;
  clientesFornecInternacionais: boolean;
  empModNfe: boolean;
  empModSpedFiscal: boolean;
  empModSpedPisCofins: boolean;
}

export interface Parametros {
  parametrosCrm: ParametrosCrm;
  parametrosPortal: ParametrosPortal;
  permissoesUsuario: PermissoesUsuario;
  parametrosEmpresa: ParametrosEmpresa;
}

class ParametrosTypes {
  public static obterPermissoesUsuario = (
    isPermitirExcluirCliente = false,
    isPermitirCrediarioAcessoFichaCliente = false,
    isPermitirIncluirCliente = false,
    isPermitirPesquisaRelatorioCliente = false,
    isPermitirAlterarCliente = false,
    isPermitirIncluirFornecedor = false,
    isPermitirAlterarFornecedor = false,
    isPermitirExcluirFornecedor = false,
    isPermitirIncluirTransportador = false,
    isPermitirAlterarTransportador = false,
    isPermitirExcluirTransportador = false,
    isPermitirDocumentoInvalidoCliente = false,
    isPermitirDocumentoInvalidoFornecedor = false,
    isPermitirDocumentoInvalidoTransportador = false,
    isBloquearAlterarDocumento = true,
    isBloquearAlterarNomeRazaoSocial = true,
    isBloquearAlterarNomeFantasia = true,
    isBloquearAlterarIdentidade = true,
    isBloquearAlterarDataNascimento = true,
    isBloquearAlterarLimiteCompraRenda = true,
    isBloquearAlterarNomeMae = true,
    isBloquearAlterarNomePai = true,
    isPermitirInformarClasseSubclasse = false,
    valorMaximoConcessaoLimiteCredito = 0,
    alterarPlanosPagamentoCliente = false,
    desbloqueioClienteFaturamento = true,
    isPermitirEditarObservacaoCliente = false,
    isPermitirIncluirProspect = true,
    isPermitirAlterarProspect = true,
    isPermitirExcluirProspect = true,
    isPermitirDocumentoInvalidoProspect = true,
    isPermitirAnonimizarCliente = false,
  ): PermissoesUsuario => {
    return {
      isPermitirCrediarioAcessoFichaCliente,
      isPermitirExcluirCliente,
      isPermitirExcluirFornecedor,
      isPermitirExcluirTransportador,
      isPermitirExcluirProspect,
      isPermitirIncluirCliente,
      isPermitirIncluirFornecedor,
      isPermitirIncluirTransportador,
      isPermitirIncluirProspect,
      isPermitirPesquisaRelatorioCliente,
      isPermitirAlterarCliente,
      isPermitirAlterarFornecedor,
      isPermitirAlterarTransportador,
      isPermitirAlterarProspect,
      isPermitirInformarClasseSubclasse,
      isPermitirDocumentoInvalidoCliente,
      isPermitirDocumentoInvalidoFornecedor,
      isPermitirDocumentoInvalidoTransportador,
      isPermitirDocumentoInvalidoProspect,
      isBloquearAlterarDocumento,
      isBloquearAlterarNomeFantasia,
      isBloquearAlterarNomeRazaoSocial,
      isBloquearAlterarDataNascimento,
      isBloquearAlterarIdentidade,
      isBloquearAlterarLimiteCompraRenda,
      isBloquearAlterarNomeMae,
      isBloquearAlterarNomePai,
      valorMaximoConcessaoLimiteCredito,
      alterarPlanosPagamentoCliente,
      desbloqueioClienteFaturamento,
      isPermitirEditarObservacaoCliente,
      isPermitirAnonimizarCliente,
    } as PermissoesUsuario;
  };

  public static obterParametrosCrm = (
    isObrigatorioClasse = true,
    isObrigatorioDataNascimento = true,
    isObrigatorioSexo = true,
    isObrigatorioEmail = true,
    isObrigatorioTelefonePrincipal = true,
    isObrigatorioTelefoneCelular = true,
    isObrigatorioLogradouro = true,
    isObrigatorioNumero = true,
    isObrigatorioBairro = true,
    isObrigatorioCidade = true,
    isObrigatorioEstado = true,
    isObrigatorioInscricaoEstadual = true,
    estadoEmpresa = '',
    sistemaTributacao = '',
    usaConvenios = false,
    isObrigatorioCEP = true,
    cidadeEmpresa = '',
    foneDDD = '',
    isObrigatorioDocProspect = true,
    isObrigatorioTelefoneProspect = true,
    isObrigatorioEmailProspect = true,
    FaturamentoLiberado = false,
    compartilharClientesEntreUnidades = false,
    isEmpresaOptanteSimplesEstadual = false,
    isObrigatorioTransportadoraIO = true,
    portalFranqueador = 0,
    isCadastroBiometricoCliente = false,
  ): ParametrosCrm => {
    return {
      isObrigatorioClasse,
      isObrigatorioDataNascimento,
      isObrigatorioSexo,
      isObrigatorioEmail,
      isObrigatorioTelefonePrincipal,
      isObrigatorioTelefoneCelular,
      isObrigatorioBairro,
      isObrigatorioCidade,
      isObrigatorioEstado,
      isObrigatorioLogradouro,
      isObrigatorioNumero,
      isObrigatorioInscricaoEstadual,
      estadoEmpresa,
      sistemaTributacao,
      usaConvenios,
      isObrigatorioCEP,
      cidadeEmpresa,
      foneDDD,
      isObrigatorioDocProspect,
      isObrigatorioTelefoneProspect,
      isObrigatorioEmailProspect,
      FaturamentoLiberado,
      compartilharClientesEntreUnidades,
      isEmpresaOptanteSimplesEstadual,
      isObrigatorioTransportadoraIO,
      portalFranqueador,
      isCadastroBiometricoCliente,
    } as ParametrosCrm;
  };

  public static obterParametrosPortal = (
    utilizaIntegracaoOC = false,
    utilizaNewsletter = false,
    compartilharClientes = '',
    clientesFornecInternacionais = false,
    empModNfe = false,
    empModSpedFiscal = false,
    empModSpedPisCofins = false,
  ) => {
    return {
      utilizaIntegracaoOC,
      utilizaNewsletter,
      compartilharClientes,
      clientesFornecInternacionais,
      empModNfe,
      empModSpedFiscal,
      empModSpedPisCofins,
    } as ParametrosPortal;
  };

  public static obterParametros = (
    parametrosCrm: ParametrosCrm = {} as ParametrosCrm,
    permissoesUsuario: PermissoesUsuario = {} as PermissoesUsuario,
    parametrosPortal: ParametrosPortal = {} as ParametrosPortal,
    parametrosEmpresa: ParametrosEmpresa = {} as ParametrosEmpresa,
  ): Parametros => {
    return {
      parametrosCrm,
      permissoesUsuario,
      parametrosPortal,
      parametrosEmpresa,
    } as Parametros;
  };
}

export default ParametrosTypes;
