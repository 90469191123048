import { NavigationGuardNext, RouteLocationNormalizedLoaded } from 'vue-router';

import { vxm } from '@/store/index';
import { Login } from '@/core/types/autenticacao';

class RouterMiddlewares {
  public static authGuardMiddleware = (
    to: RouteLocationNormalizedLoaded,
    from: RouteLocationNormalizedLoaded,
    next: NavigationGuardNext,
  ) => {
    if (!vxm.autenticacao.isAutenticado && to.name !== 'Autenticacao') return next({ name: 'Autenticacao', query: to.query });
    if (vxm.autenticacao.isAutenticado && to.path === '/AutenticacaoPorToken')
      return next({ name: 'Autenticacao', query: to.query });
    return next();
  };
}

export default RouterMiddlewares;
