import { Campo } from "../../campos";

export interface Anexo{
  id: number | null
  nomeAnexo: string
  tamanhoAnexo: number | null
}

export interface DadosAdicionais{
  anexos: Campo<Array<Anexo>>
  observacoes: Campo<string>
  observacoesPedidoCompra: Campo<string>
  urlRastreioIO: Campo<string>
  compraMinima: Campo<number>
}

class PessoaDadosAdicionaisTypes {
  public static ObterAnexo = (id: number | null, nomeAnexo: string, tamanhoAnexo: number | null):Anexo => {
    return {
      id,
      nomeAnexo,
      tamanhoAnexo
    } as Anexo
  }
  public static ObterDadosAdicionais = (anexos: Campo<Array<Anexo>>, observacoes: Campo<string>, observacoesPedidoCompra: Campo<string>, urlRastreioIO: Campo<string>, compraMinima: Campo<number>): DadosAdicionais => {
    return {
      anexos,
      observacoes,
      observacoesPedidoCompra,
      urlRastreioIO,
      compraMinima
    } as DadosAdicionais;
  }
}

export default PessoaDadosAdicionaisTypes;