import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store/index';
import mitt from 'mitt';
import VueSweetalert2 from 'vue-sweetalert2';
import VueScrollTo from 'vue-scrollto';

import 'sweetalert2/dist/sweetalert2.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import '@vueform/multiselect/themes/default.css';
import '@/assets/css/vue-multiselect.min.css';
import '@/assets/css/vue-sidebar.min.css';
import '@/assets/css/switch.css';

const app = createApp(App);
const emitter = mitt();
app.use(VueSweetalert2);
app.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

const clickOutside = {
  beforeMount: function (el: any, binding: any, vnode: any) {
    binding.event = function (event: any) {
      if (!(el === event.target || el.contains(event.target))) {
        if (binding.value instanceof Function) {
          binding.value(event);
        }
      }
    };
    document.body.addEventListener('click', binding.event);
  },
  unmounted: function (el: any, binding: any, vnode: any) {
    document.body.removeEventListener('click', binding.event);
  },
};

app.use(store);
app.use(router);
app.directive('click-outside', clickOutside);
app.provide('emitter', emitter);
app.mount('#app');
