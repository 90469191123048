//MODAJOI-37214 (US 001)
import FetchHandler from '../utils/handler';
import RequestExtension from '../utils/request';

import EsRoutes from '@/api/routes/es';
import { ObterDadosFreteTiposResult } from '../contracts/es/notaSimplificada';
import { ObterParametrosCommerceResult } from '../contracts/es/parametrosCommerce';
const { obterListagemFreteTipos } = EsRoutes.notaSimplificada;
const { obterParametrosCommerce } = EsRoutes.obterParametrosCommerce;

class EsClient extends FetchHandler {
  public obterListagemFreteTipos = async (): Promise<Array<ObterDadosFreteTiposResult>> => {
    const request = RequestExtension.getDefaultGET(obterListagemFreteTipos());
    return this.typedFetch<Array<ObterDadosFreteTiposResult>>(request);
  };

  public obterParametrosCommerce = async (): Promise<boolean> => {
    const request = RequestExtension.getDefaultGET(obterParametrosCommerce());
    return this.typedFetch<boolean>(request);
  };
}

export default new EsClient();
