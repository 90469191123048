
  import { defineComponent, reactive, toRefs } from 'vue';

  export default defineComponent({
    name: 'Clientes',
    setup() {
      const event = reactive({});
      return toRefs(event);
    },
  });
