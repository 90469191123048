import { Campo, CampoMultiselect } from '@/core/types/campos';
import { Parametros } from '@/core/types/parametros';
import { FileWithPath } from 'file-selector';

export interface Conveniador extends CampoMultiselect {
  nomeFantasia: string;
}

export interface Classe extends CampoMultiselect {
  cor: string;
}

export interface PessoasClasses extends CampoMultiselect {
  cor: string;
}

export interface SubClasse extends CampoMultiselect {
  idClasse: number;
}

export interface Pais extends CampoMultiselect {
  sigla: string;
}

export interface PlanoPagamento extends CampoMultiselect {
  tipoPlano: string;
  formaPgto: string;
}

export interface TipoRegistroProfissional extends CampoMultiselect {
  permiteCadastrarSemInformarDocumento: boolean;
}

export interface CategoriaFinanceira extends CampoMultiselect {}

export interface TabelaDePreco extends CampoMultiselect {}

export interface Escolaridade extends CampoMultiselect {}

export interface EstadoCivil extends CampoMultiselect {}

export interface MotivoBloqueio extends CampoMultiselect {}

export interface Parentesco extends CampoMultiselect {}

export interface TiposPessoa extends CampoMultiselect {}

export interface TipoCliente extends CampoMultiselect {}

export interface TipoMoradia extends CampoMultiselect {}

export interface TipoDoc extends CampoMultiselect {}

export interface Estado extends CampoMultiselect {}

export interface Vendedor extends CampoMultiselect {}

export interface Sexo extends CampoMultiselect {}

export interface RamoOpticoLaboratorio extends CampoMultiselect {}

export interface Banco extends CampoMultiselect {}
export interface SerieFaturamento extends CampoMultiselect {}

export interface GrupoEmpresarial extends CampoMultiselect {}

export interface Empresa extends CampoMultiselect {}

export interface TransportadoraIO extends CampoMultiselect {}

export interface TipoContaBancaria extends CampoMultiselect {}
export interface TipoChavePix extends CampoMultiselect {}

export interface CadastroCamposAdicionais {
  id: number;
  nomeCampo: string;
  ordemCampo: number;
  tipoCampo: number;
  ativo: boolean;
  valores: Array<CadastroCamposAdicionaisValores>;
  detalhes: Array<CadastroCamposAdicionaisDetalhes>;
}

export interface CadastroCamposAdicionaisValores {
  id: number;
  idCampo: number;
  contentCampo: string;
  idPessoa: number;
}

export interface CadastroCamposAdicionaisDetalhes {
  id: number;
  idCampo: number;
  descricao: string;
}

export interface ClassesFiscais extends CampoMultiselect {}

export interface ClasseFiscalRegra extends CampoMultiselect {
  contribuinte: boolean;
  uf: string;
  idClasseFiscal: number;
}

export interface DadosIniciaisCadastro {
  classes: Array<Classe>;
  subclasses: Array<SubClasse>;
  estadosCivis: Array<EstadoCivil>;
  sexos: Array<Sexo>;
  tiposClientes: Array<TipoCliente>;
  escolaridades: Array<Escolaridade>;
  estados: Array<Estado>;
  tiposMoradias: Array<TipoMoradia>;
  tiposDocs: Array<TipoDoc>;
  paises: Array<Pais>;
  tabelasDePrecos: Array<TabelaDePreco>;
  vendedores: Array<Vendedor>;
  planosPagamentos: Array<PlanoPagamento>;
  conveniadores: Array<Conveniador>;
  parentescos: Array<Parentesco>;
  motivosBloqueios: Array<MotivoBloqueio>;
  categoriasFinanceiras: Array<CategoriaFinanceira>;
  politicaDeDados: string;
  ramoOpticoLaboratorios: Array<RamoOpticoLaboratorio>;
  camposAdicionais: Array<CadastroCamposAdicionais>;
  classesFiscais: Array<ClassesFiscais>;
  classesFiscaisRegra: Array<ClasseFiscalRegra>;
  bancos: Array<Banco>;
  seriesFaturamento: Array<SerieFaturamento>;
  gruposEmpresariais: Array<GrupoEmpresarial>;
  empresas: Array<Empresa>;
  pessoasClasses: Array<PessoasClasses>;
  transportadorasIO: Array<TransportadoraIO>;
  tiposRegistrosProfissionais: Array<TipoRegistroProfissional>;
  tiposContasBancarias: Array<TipoContaBancaria>;
  tiposChavePix: Array<TipoChavePix>;
}

export interface DadosIniciaisCadastroComParametros {
  dadosIniciaisCadastro: DadosIniciaisCadastro;
  parametros: Parametros;
  utilizaIntegracaoSalesForce: boolean;
  analiseDeCreditoAtiva: boolean;
}

export interface DocParaInserir {
  descricao: string;
  tipoDoc?: number;
  file: FileWithPath;
}

class PessoaCadastroTypes {
  public static obterConveniador = (id = 0, nomeRazaoSocial = '', nomeFantasia = '', ativo = true): Conveniador => {
    return { id, descricao: nomeRazaoSocial, nomeFantasia, ativo } as Conveniador;
  };

  public static obterClasse = (id = 0, descricao = '', cor = '', ativo = true): Classe => {
    return { id, descricao, cor, ativo } as Classe;
  };

  public static obterPessoasClasses = (id = 0, descricao = '', cor = '', ativo = true): PessoasClasses => {
    return { id, descricao, cor, ativo } as PessoasClasses;
  };

  public static obterSubClasse = (id = 0, descricao = '', idClasse = 0, ativo = true): SubClasse => {
    return { id, descricao, idClasse, ativo } as SubClasse;
  };

  public static obterPais = (id = 0, descricao = '', sigla = '', ativo = true): Pais => {
    return { id, descricao, sigla, ativo } as Pais;
  };

  public static obterPlanoPagamento = (id = 0, nomePLano = '', tipoPlano = '', formaPgto = '', ativo = true): PlanoPagamento => {
    return { id, descricao: nomePLano, tipoPlano, formaPgto, ativo } as PlanoPagamento;
  };

  public static obterCampoTipoRegistroProfissional = (
    id = 0,
    descricao = '',
    permiteCadastrarSemInformarDocumento = false,
  ): TipoRegistroProfissional => {
    return { id, descricao, permiteCadastrarSemInformarDocumento } as TipoRegistroProfissional;
  };

  public static obterClassesFiscaisRegra = (
    id = 0,
    contribuinte = false,
    ativo = false,
    idClasseFiscal = 0,
    uf = '',
  ): ClasseFiscalRegra => {
    return { id, ativo: !ativo, contribuinte: contribuinte, idClasseFiscal: idClasseFiscal, uf: uf } as ClasseFiscalRegra;
  };

  public static obterCadastroCamposAdicionais = (
    id = 0,
    nomeCampo = '',
    ordemCampo = 0,
    tipoCampo = 0,
    ativo = false,
    detalhes = [] as Array<CadastroCamposAdicionaisDetalhes>,
  ): CadastroCamposAdicionais => {
    return { id, nomeCampo, ordemCampo, tipoCampo, ativo, detalhes } as CadastroCamposAdicionais;
  };

  public static obterCadastroCamposAdicionaisValores = (
    id = 0,
    idCampo = 0,
    contentCampo = '',
    idPessoa = 0,
  ): CadastroCamposAdicionaisValores => {
    return { id, idCampo, contentCampo, idPessoa } as CadastroCamposAdicionaisValores;
  };

  public static obterCadastroCamposAdicionaisDetalhes = (
    id = 0,
    idCampo = 0,
    descricao = '',
  ): CadastroCamposAdicionaisDetalhes => {
    return { id, idCampo, descricao } as CadastroCamposAdicionaisDetalhes;
  };

  public static obterDadosIniciaisCadatro = (
    classes: Array<Classe> = [],
    subclasses: Array<SubClasse> = [],
    estadosCivis: Array<EstadoCivil> = [],
    sexos: Array<Sexo> = [],
    tiposClientes: Array<TipoCliente> = [],
    escolaridades: Array<Escolaridade> = [],
    estados: Array<Estado> = [],
    tiposMoradias: Array<TipoMoradia> = [],
    tiposDocs: Array<TipoDoc> = [],
    paises: Array<Pais> = [],
    tabelasDePrecos: Array<TabelaDePreco> = [],
    vendedores: Array<Vendedor> = [],
    planosPagamentos: Array<PlanoPagamento> = [],
    conveniadores: Array<Conveniador> = [],
    parentescos: Array<Parentesco> = [],
    motivosBloqueios: Array<MotivoBloqueio> = [],
    categoriasFinanceiras: Array<CategoriaFinanceira> = [],
    politicaDeDados = '',
    ramoOpticoLaboratorios: Array<RamoOpticoLaboratorio> = [],
    camposAdicionais: Array<CadastroCamposAdicionais> = [],
    classesFiscais: Array<ClassesFiscais> = [],
    classesFiscaisRegra: Array<ClasseFiscalRegra> = [],
    bancos: Array<Banco> = [],
    seriesFaturamento: Array<SerieFaturamento> = [],
    gruposEmpresariais: Array<GrupoEmpresarial> = [],
    empresas: Array<Empresa> = [],
    pessoasClasses: Array<PessoasClasses> = [],
    transportadorasIO: Array<TransportadoraIO> = [],
    tiposRegistrosProfissionais: Array<TipoRegistroProfissional> = [],
    tiposContasBancarias: Array<TipoContaBancaria> = [],
    tiposChavePix: Array<TipoChavePix> = [],
  ): DadosIniciaisCadastro => {
    return {
      classes,
      subclasses,
      estadosCivis,
      sexos,
      tiposClientes,
      escolaridades,
      estados,
      tiposMoradias,
      tiposDocs,
      paises,
      conveniadores,
      planosPagamentos,
      tabelasDePrecos,
      vendedores,
      categoriasFinanceiras,
      motivosBloqueios,
      parentescos,
      politicaDeDados,
      ramoOpticoLaboratorios,
      camposAdicionais,
      classesFiscais,
      classesFiscaisRegra,
      bancos,
      seriesFaturamento,
      gruposEmpresariais,
      empresas,
      pessoasClasses,
      transportadorasIO,
      tiposRegistrosProfissionais,
      tiposContasBancarias,
      tiposChavePix,
    } as DadosIniciaisCadastro;
  };

  public static obterDadosIniciaisCadastroComParametros = (
    dadosIniciaisCadastro: DadosIniciaisCadastro = {} as DadosIniciaisCadastro,
    parametros: Parametros = {} as Parametros,
    utilizaIntegracaoSalesForce = false,
    analiseDeCreditoAtiva = false,
  ): DadosIniciaisCadastroComParametros => {
    return {
      dadosIniciaisCadastro,
      parametros,
      utilizaIntegracaoSalesForce,
      analiseDeCreditoAtiva,
    } as DadosIniciaisCadastroComParametros;
  };
}

export default PessoaCadastroTypes;
