export enum TipoCliente {
  cliente = 1,
  fornecedor = 2,
  ambos = 3,
  transportador = 4,
  prospect = 5,
  ambosTransportador = 6,
}

export enum TipoPessoa {
  fisica = 1,
  juridica = 2,
}

export enum EstadoCivil {
  naoInformado = 1,
  casado = 2,
  solteiro = 3,
  divorciado = 4,
  viuvo = 5,
  outros = 6,
  concubinato = 7
}

export enum Sexo {
  masculino = 1,
  feminino = 2,
}

export enum Escolaridade {
  fundamentalIncompleto = 1,
  fundamentalCompleto = 2,
  medioCompleto = 3,
  graduacaoCompleto = 4,
  posGraduacaoCompleto = 5,
}

class PessoaEnum {
  public static getDescricaoTipoCliente = (tipoCliente: TipoCliente): string => {
    switch (tipoCliente) {
      case TipoCliente.cliente:
        return 'cliente';
      case TipoCliente.ambos:
        return 'cliente/fornecedor';
      case TipoCliente.fornecedor:
        return 'fornecedor';
      case TipoCliente.transportador:
        return 'transportador';
      case TipoCliente.ambosTransportador:
        return 'cliente/fornecedor/transportador';
      case TipoCliente.prospect:
        return 'prospect';
      default:
        return 'Não informado';
    }
  };

  public static getDescricaoTipoPessoa = (tipoPessoa: TipoPessoa): string => {
    switch (tipoPessoa) {
      case TipoPessoa.fisica:
        return 'física';
      case TipoPessoa.juridica:
        return 'jurídica';
      default:
        return 'Não informado';
    }
  };

  public static getDescricaoEstadoCivil = (estadoCivil: EstadoCivil): string => {
    switch (estadoCivil) {
      case EstadoCivil.solteiro:
        return 'solteiro';
      case EstadoCivil.casado:
        return 'casado';
      case EstadoCivil.divorciado:
        return 'divorciado';
      case EstadoCivil.viuvo:
        return 'viúvo';
      default:
        return 'Não informado';
    }
  };
}

export default PessoaEnum;
