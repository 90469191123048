class FiltrosExtension {
  public static inserirMascaraCpf = (cpf: string): string => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  public static inserirMascaraCnpj = (cnpj: string): string => {
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  };

  public static inserirMascaraDocumento = (documento: string): string => {
    const doc = FiltrosExtension.removerMascara(documento);
    if (!doc) return '';

    if (doc.length <= 11) return FiltrosExtension.inserirMascaraCpf(documento);
    return FiltrosExtension.inserirMascaraCnpj(documento);
  };

  public static inserirMascaraRg = (rg: string): string => {
    rg = rg.replace(/\D/g, '');
    rg = rg.replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/, '$1.$2.$3-$4');
    return rg;
  };

  public static inserirMascaraCep = (cep: string): string => {
    const regex = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;
    return cep.replace(regex, '$1$2-$3');
  };

  public static inserirMascaraPorcentagem = (valor: string): string => {
    valor = valor.trim();
    const isValid = /^(0|[1-9][0-9]?|100)$/.test(valor);
    if (!isValid) valor = valor.slice(0, -1);
    return valor.replace(/^(\d*)/, '$1%');
  };

  public static inserirMascaraTelefone = (telefone: string): string => {
    telefone = telefone.replace(/^0/, '');

    if (telefone.length > 10) {
      telefone = telefone.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (telefone.length > 5) {
      telefone = telefone.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
    } else if (telefone.length > 2) {
      telefone = telefone.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else if (telefone.length == 2) {
      telefone = telefone.replace(/^(\d*)/, '($1)');
    } else {
      telefone = telefone.replace(/^(\d*)/, '$1');
    }

    return telefone;
  };

  public static inserirMascaraDataHora = (date: Date): string => {
    date = new Date(date);
    let day = String(date.getDate());
    let month = String(date.getMonth() + 1);
    const year = date.getFullYear();
    let hour = date.getHours() + '';
    let minutes = date.getMinutes() + '';
    let seconds = date.getSeconds() + '';
    day = day.length === 2 ? day : '0' + day;
    month = month.length === 2 ? month : '0' + month;
    hour = hour.length === 2 ? hour : '0' + hour;
    minutes = minutes.length === 2 ? minutes : '0' + minutes;
    seconds = seconds.length === 2 ? seconds : '0' + seconds;

    return day + '/' + month + '/' + year + ' ' + hour + ':' + minutes + ':' + seconds;
  };

  public static inserirMascaraData = (date: Date | string): string => {
    date = new Date(date);
    let day = String(date.getDate());
    let month = String(date.getMonth() + 1);
    const year = date.getFullYear();
    day = day.length === 2 ? day : '0' + day;
    month = month.length === 2 ? month : '0' + month;

    return day + '/' + month + '/' + year;
  };

  public static removerCaracteresEspeciais = (valor: string): string => {
    return valor.replace(/\W/g, '').trim();
  };

  public static inserirMascaraGuid = (valor: string): string => {
    valor = FiltrosExtension.removerCaracteresEspeciais(valor);
    return valor.replace(/(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/, '$1-$2-$3-$4-$5');
  };

  public static permitirApenasNumeros = (valor: string): string => {
    const valorFormatado = valor.match(/\d+/g)?.join('');
    return valorFormatado || '';
  };

  public static permitirApenasLetras = (valor: string): string => {
    const valorFormatado = valor.replace(/[^a-zA-Z ]/g, '');
    return valorFormatado || '';
  };

  public static removerMascara = (valor: string): string => {
    return valor.replace(/\D/g, '');
  };
}

export default FiltrosExtension;
