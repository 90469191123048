class DownLoadExtension {
  public base64toBlob(b64Data: any, contentType: string, sliceSize: number) {
    contentType = '';
    sliceSize = 512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  public downloadFile(file: any) {
    const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;';
    const blobFile = this.base64toBlob(file.Content, type, 512);

    if (typeof window.navigator.msSaveBlob !== 'undefined') window.navigator.msSaveBlob(blobFile, file.Filename);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blobFile);
    link.download = file.Filename;
    link.click();
  }
  public blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return <File>theBlob;
  };
}

export default new DownLoadExtension();
