
  import { defineComponent, computed, reactive, watch } from 'vue';
  import { vxm } from '@/store/index';

  import { TipoChavePix } from '@/core/enums/cadastro';
  import { PessoaBanco } from '@/core/types/clientes/pessoa/comercial';

  import Campo from '@/components/shared/Campo.vue';
  import Input from '@/components/shared/Input.vue';

  export default defineComponent({
    name: 'DadosBancariosFormularioPixTipos',
    components: {
      Campo,
      Input,
    },
    props: {
      tipoChavePix: {
        type: Number as () => number | null,
        default: null,
      },
    },
    setup(props) {
      const objetoChavePixPadrao = {
        placeholder: 'Escolha um Tipo Chave Pix acima',
        maxLength: 20,
        campo: '',
        disabled: true,
        tipo: 'text',
      };

      const objetoChavePix = reactive({ ...obterObjetoChavePix(props.tipoChavePix) });

      const dadoBancarioSelecionado = computed(() => {
        const dadoBancarioSelecionado = vxm.cadastro.componenteDadosBancarios.pessoaBancoSelecionado.valor;
        return dadoBancarioSelecionado || ({} as PessoaBanco);
      });

      function obterObjetoChavePix(tipoChavePix: number | null) {
        const objChavePix = { ...objetoChavePixPadrao };

        if (!tipoChavePix) return objChavePix;

        switch (tipoChavePix) {
          case TipoChavePix.CpfCnpj:
            objChavePix.placeholder = 'Insira aqui o documento (CPF ou CNPJ)';
            objChavePix.maxLength = 20;
            objChavePix.campo = 'DOCUMENTO';
            objChavePix.disabled = false;
            return objChavePix;
          case TipoChavePix.Email:
            objChavePix.placeholder = 'Insira aqui o e-mail';
            objChavePix.maxLength = 100;
            objChavePix.campo = 'EMAIL';
            objChavePix.disabled = false;
            objChavePix.tipo = 'email';
            return objChavePix;
          case TipoChavePix.Celular:
            objChavePix.placeholder = 'Insira aqui o número com DDD';
            objChavePix.maxLength = 20;
            objChavePix.campo = 'TELEFONE';
            objChavePix.disabled = false;
            objChavePix.tipo = 'tel';
            return objChavePix;
          case TipoChavePix.ChaveAleatoria:
            objChavePix.placeholder = 'Insira aqui a chave aleatória';
            objChavePix.maxLength = 32;
            objChavePix.campo = 'GUID';
            objChavePix.disabled = false;
            return objChavePix;
          default:
            return objChavePix;
        }
      };

      watch(
        () => props.tipoChavePix,
        (tipoChavePix) => Object.assign(objetoChavePix, obterObjetoChavePix(tipoChavePix)),
      );

      return {
        TipoChavePix,
        dadoBancarioSelecionado,
        ...vxm,
        objetoChavePix,
      };
    },
  });
