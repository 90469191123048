
  import { defineComponent, computed } from 'vue';
  import { vxm } from '@/store/index';

  import Campo from '@/components/shared/Campo.vue';
  import Multiselect from '@/components/shared/Multiselect.vue';
  import Input from '@/components/shared/Input.vue';
  import Money from '@/components/shared/Money.vue';
  import DadosBancariosFormularioConta from '@/components/clientes/cadastro/comercial/dadosBancarios/DadosBancariosFormularioConta.vue';
  import DadosBancariosFormularioPix from '@/components/clientes/cadastro/comercial/dadosBancarios/DadosBancariosFormularioPix.vue';

  import { PessoaBanco } from '@/core/types/clientes/pessoa/comercial';
  import { TipoChavePix } from '@/core/enums/cadastro';

  export default defineComponent({
    name: 'SecaoDadosBancariosCadastro',
    components: {
      Campo,
      Multiselect,
      Input,
      Money,
      DadosBancariosFormularioConta,
      DadosBancariosFormularioPix,
    },
    props: {
      idSecaoMenu: {
        type: String,
        required: true,
      },
      ehCadastroParcial: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['salvar-pessoa', 'excluir-cadastro-parcial'],
    setup(props, { emit }) {
      const contaPrincipalFake = false;

      const dadoBancarioSelecionado = computed(() => {
        const dadoBancarioSelecionado = vxm.cadastro.componenteDadosBancarios.pessoaBancoSelecionado.valor;
        return dadoBancarioSelecionado || ({} as PessoaBanco);
      });

      const dadoBancarioSelecionadoValorLimiteConta = computed({
        get() {
          return Number(dadoBancarioSelecionado.value.valorLimiteConta.valor);
        },
        set(newValue: number) {
          dadoBancarioSelecionado.value.valorLimiteConta.valor = newValue;
        },
      });

      const isContaBancaria = computed(
        () =>
          !dadoBancarioSelecionado.value.isPix.valor ||
          (dadoBancarioSelecionado.value.isPix.valor &&
            dadoBancarioSelecionado.value.idTipoChavePix.valor === TipoChavePix.ContaBancaria),
      );

      const tooltipContaAgendamentoPagamento = computed(
        () =>
          `Com essa opção ativada será possível realizar o agendamento de pagamentos por Transferências ou PIX automaticamente com a Linx Conta Digital Integrada (Stone Banking)${
            vxm.cadastro.contaAgendamentoPagamentoDesabilitada ? '. É necessário ter uma Conta Digital Integrada no portal' : ''
          }`,
      );

      const mostrarMensagemCamposEssenciais = computed(
        () =>
          isContaBancaria.value &&
          (!dadoBancarioSelecionado.value.nomeTitular?.valor ||
            !dadoBancarioSelecionado.value.documentoTitular?.valor ||
            !dadoBancarioSelecionado.value.idTipoContaBancaria?.valor),
      );

      const atualizarTipoChavePix = () => {
        const ehContaBancaria =
          dadoBancarioSelecionado.value.isPix.valor &&
          !!dadoBancarioSelecionado.value.agencia.valor &&
          !!dadoBancarioSelecionado.value.contaCorrente.valor;

        vxm.cadastro.atualizarTipoChavePix(ehContaBancaria ? TipoChavePix.ContaBancaria : null);
      };

      const excluirDadoBancario = async () => {
        const excluirDadoBancarioSelecionado = await vxm.cadastro.excluirDadoBancarioSelecionado(props.ehCadastroParcial);
        if (!excluirDadoBancarioSelecionado) return;

        if (props.ehCadastroParcial) emit('excluir-cadastro-parcial');
      };

      const salvarDadoBancario = async () => {
        const salvarDadoBancarioSelecionado = await vxm.cadastro.salvarDadoBancarioSelecionado(props.ehCadastroParcial);
        if (!salvarDadoBancarioSelecionado) return;

        if (props.ehCadastroParcial) emit('salvar-pessoa');
      };

      return {
        TipoChavePix,
        contaPrincipalFake,
        dadoBancarioSelecionado,
        dadoBancarioSelecionadoValorLimiteConta,
        isContaBancaria,
        tooltipContaAgendamentoPagamento,
        mostrarMensagemCamposEssenciais,
        ...vxm,
        atualizarTipoChavePix,
        excluirDadoBancario,
        salvarDadoBancario,
      };
    },
  });
