import { ApiError } from '@/api/utils/handler';
import Swal, { SweetAlertOptions, SweetAlertIcon } from 'sweetalert2';

enum TipoAlert {
  sucesso,
  erro,
  atencao,
}

class AlertsExtension {
  private static exibirMensagem = (mensagem: string, tipo: TipoAlert, isToast: boolean, tituloCustom?: string) => {
    let titulo = '';
    let icon: SweetAlertIcon = 'success';

    const propsSwal: SweetAlertOptions = {
      html: mensagem,
      confirmButtonText: 'Fechar',
      customClass: {
        confirmButton: 'btn btn-primary mr-1',
        cancelButton: 'btn btn-default',
      },
      buttonsStyling: false,
    };

    switch (tipo) {
      case TipoAlert.sucesso:
        icon = 'success';
        titulo = 'Legal!!';
        break;
      case TipoAlert.erro:
        icon = 'error';
        titulo = 'Oops!!';
        break;
      case TipoAlert.atencao:
        icon = 'warning';
        titulo = 'Atenção!!';
        break;
    }

    propsSwal.title = tituloCustom ? tituloCustom : titulo;
    propsSwal.icon = icon;

    if (isToast) {
      propsSwal.showCancelButton = false;
      propsSwal.showConfirmButton = false;
      propsSwal.toast = true;
      propsSwal.position = 'top';
      propsSwal.timer = 3000;
      propsSwal.didOpen = () => {
        Swal.getPopup()?.addEventListener('mouseenter', Swal.stopTimer);
        Swal.getPopup()?.addEventListener('mouseleave', Swal.resumeTimer);
      }
      propsSwal.willClose = () => {
        Swal.getPopup()?.addEventListener('mouseenter', Swal.stopTimer);
        Swal.getPopup()?.addEventListener('mouseleave', Swal.resumeTimer);
      }
    } else {
      propsSwal.heightAuto = false;
      propsSwal.allowOutsideClick = false;
    }

    Swal.fire(propsSwal);
  };

  public static exibirMensagemSucesso = (mensagem: string, isToast: boolean, tituloCustom?: string) => {
    AlertsExtension.exibirMensagem(mensagem, TipoAlert.sucesso, isToast, tituloCustom);
  };

  public static exibirMensagemErro = (mensagem: string | ApiError | Error, isToast: boolean, tituloCustom?: string) => {
    if (mensagem instanceof ApiError) AlertsExtension.handleApiError(mensagem, isToast);
    else if (mensagem instanceof Error) AlertsExtension.exibirMensagem(mensagem.message, TipoAlert.erro, isToast, tituloCustom);
    else AlertsExtension.exibirMensagem(mensagem, TipoAlert.erro, isToast, tituloCustom);
  };

  private static handleApiError = (apiError: ApiError, isToast: boolean) => {
    const mensagens = new Array<string>();
    if (apiError.detail) mensagens.push(apiError.detail);
    if (apiError.errors) {
      const errosPorProp = apiError.errors;
      const msgErrors = Object.keys(errosPorProp)
        .map((propName) => `${propName}: ${errosPorProp[propName].join(' ')}`)
        .join('<br>');
      mensagens.push(msgErrors);
    }
    AlertsExtension.exibirMensagem(mensagens.join('<br>'), TipoAlert.erro, isToast, apiError.title);
  };

  public static exibirMensagemAlerta = (mensagem: string, isToast: boolean, tituloCustom?: string) => {
    AlertsExtension.exibirMensagem(mensagem, TipoAlert.atencao, isToast, tituloCustom);
  };

  public static exibirMensagemPergunta = (
    titulo: string,
    mensagem: string,
    fn: <T>(result: boolean) => T | void,
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      Swal.fire({
        heightAuto: false,
        title: titulo,
        html: mensagem,
        showCloseButton: true,
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary mr-1',
          cancelButton: 'btn btn-default',
        },
        buttonsStyling: false,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) fn(true);
        else fn(false);
        resolve(result.value);
      });
    });
  };
}

export default AlertsExtension;
