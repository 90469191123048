
  import { computed, defineComponent } from 'vue';

  import { vxm } from '@/store/index';

  import Campo from '@/components/shared/Campo.vue';
  import VueFormMultiselect from '@vueform/multiselect';

  import { PessoaBanco } from '@/core/types/clientes/pessoa/comercial';
  import { TipoChavePix } from '@/core/enums/cadastro';
  import FiltrosExtension from '@/utils/filtros';

  export default defineComponent({
    name: 'DadosBancariosSelecao',
    components: {
      Campo,
      VueFormMultiselect,
    },
    setup() {
      const adicaoBloqueada = computed(() => vxm.cadastro.componenteDadosBancarios.bloquearAdicaoDadosBancarios);

      const formatarChavePix = (chavePix: string, idTipoChavePix: number): string => {
        switch (idTipoChavePix) {
          case TipoChavePix.Celular:
            return FiltrosExtension.inserirMascaraTelefone(chavePix);
          case TipoChavePix.ChaveAleatoria:
            return FiltrosExtension.inserirMascaraGuid(chavePix);
          case TipoChavePix.CpfCnpj:
            return FiltrosExtension.inserirMascaraDocumento(chavePix);
          default:
            return chavePix ?? '';
        }
      };

      const obterLabelMutiselectContaBancaria = (option: PessoaBanco): string => {
        if (!option?.idBanco?.valor) return 'Não informado';

        const labelBanco =
          vxm.cadastro.dadosIniciaisParaCadastro.bancos.find(({ id }) =>
            option?.idBanco?.valor ? parseInt(option?.idBanco?.valor?.toString().substring(0, 3)) == id : false,
          )?.descricao || 'Não informado';
        const labelContaCorrente = option.contaCorrente.valor
          ? `${option.contaCorrente.valor}-${option.contaCorrenteDigito.valor}`
          : 'Não informado';

        const labelAgencia = option.agencia.valor ? `${option.agencia.valor}-${option.agenciaDigito.valor}` : 'Não informado';

        return `Banco: ${labelBanco} | Conta Corrente: ${labelContaCorrente} | Agência: ${labelAgencia}`;
      };

      const obterLabelMutiselectPix = (option: PessoaBanco): string => {
        if (!option.idTipoChavePix.valor) return '';

        const inicioLabel = 'PIX: Tipo Chave PIX:';

        if (option.idTipoChavePix.valor === TipoChavePix.ContaBancaria)
          return `${inicioLabel} Dados Bancários | ${obterLabelMutiselectContaBancaria(option)}`;

        const labelPix =
          vxm.cadastro.dadosIniciaisParaCadastro.tiposChavePix.find(({ id }) =>
            option?.idTipoChavePix?.valor ? option?.idTipoChavePix?.valor == id : false,
          )?.descricao || 'Não informada';
        const chavePix = option.chavePix.valor
          ? `${formatarChavePix(option.chavePix.valor, option.idTipoChavePix.valor)}`
          : 'Não informada';

        return `${inicioLabel} ${labelPix} | Chave PIX: ${chavePix}`;
      };

      const obterLabelMutiselectDadosBancarios = (option: PessoaBanco): string => {
        if (option.isPix.valor) return obterLabelMutiselectPix(option);
        return obterLabelMutiselectContaBancaria(option);
      };

      const obterLabelMultiselectValueDadosBancarios = (value: PessoaBanco): string => {
        const isNovoDadoBancario = vxm.cadastro.isNovoDadoBancario;
        if (isNovoDadoBancario) return 'Novo dado bancário';
        else return obterLabelMutiselectDadosBancarios(value);
      };

      return {
        ...vxm,
        adicaoBloqueada,
        obterLabelMutiselectDadosBancarios,
        obterLabelMultiselectValueDadosBancarios,
      };
    },
  });
