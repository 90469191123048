import ApiConfig from '@/api/utils/apiConfig';
class RequestExtension {
  private getDefaultHeaders = (): Headers => {
    return new Headers({
      Authorization: ApiConfig.getTokenJWT(),
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    });
  };

  public getDefaultPOST = (route: string, payload: any, header?: Headers): Request => {
    const init: RequestInit = {
      method: 'POST',
      headers: header ? header : this.getDefaultHeaders(),
      body: JSON.stringify(payload),
    };
    const request = new Request(route, init);
    return request;
  };

  public getFormDataPOST = (route: string, payload: FormData, headers?: Headers): Request => {
    const init: RequestInit = {
      method: 'POST',
      headers: headers
        ? headers
        : new Headers({
            Authorization: ApiConfig.getTokenJWT(),
          }),
      body: payload,
    };
    const request = new Request(route, init);
    return request;
  };

  public getDefaultGET = (route: string, header?: Headers): Request => {
    const init: RequestInit = {
      method: 'GET',
      headers: header ? header : this.getDefaultHeaders(),
    };
    const request = new Request(route, init);
    return request;
  };

  public getDefaultDELETE = (route: string, header?: Headers): Request => {
    const init: RequestInit = {
      method: 'DELETE',
      headers: header ? header : this.getDefaultHeaders(),
    };
    const request = new Request(route, init);
    return request;
  };
}

export default new RequestExtension();
