export type Login = {
  token: string;
  rotina: string;
  appOrigem: string;
};

export enum ProdutosApi {
  ES = 'LMX.ES.WebApi',
  NFE = 'LMX.API.NFe 4.0',
  CRM = 'LMX.CRM.WebApi',
  Fiscal = 'LMX.Fiscal.WebApi',
  Faturamento = 'LMX.Faturamento.WebApi',
  AdmFinanceiro = 'LMX.AdmFinanceiro.WebApi',
  LIS = 'LinxMicrovix.WebApi.LIS',
  Suprimentos = 'LMX.Suprimentos.WebApi',
  ProdutosImagens = 'LMX.Produtos.Imagens.WebApi',
  MicrovixERP = 'MicrovixERP',
  ConnectHub = 'LMX.ConnectHub.WebApi',
}

export enum Rotinas {
  Importacao = 'ImportacaoClientes',
  Listagem = 'ListagemClientes',
  Cadastro = 'CadastroClientes',
}

class AutenticacaoTypes {
  public static obterNomesProdutosApi = (): Array<string> => {
    return [
      ProdutosApi.ES,
      ProdutosApi.NFE,
      ProdutosApi.CRM,
      ProdutosApi.Fiscal,
      ProdutosApi.Faturamento,
      ProdutosApi.AdmFinanceiro,
      ProdutosApi.LIS,
      ProdutosApi.Suprimentos,
      ProdutosApi.ProdutosImagens,
      ProdutosApi.MicrovixERP,
      ProdutosApi.ConnectHub,
    ];
  };
}

export default AutenticacaoTypes;
