
  import { defineComponent, reactive, toRef, toRefs } from 'vue';
  export default defineComponent({
    name: 'BaseModal',
    props: {
      tituloModal: {
        type: String,
        required: false,
      },

      telaInteira: {
        type: Boolean,
        required: false,
        default: false,
      },
      classesAdicionaisBackDrop: {
        type: String,
        required: false,
        default: '',
      },
      classesAdicionaisModal: {
        type: String,
        required: false,
        default: '',
      },
      classesAdicionaisHeader: {
        type: String,
        required: false,
        default: 'p-2 cabecalho-modal-customizado-lmx',
      },
      classesAdicionaisBody: {
        type: String,
        required: false,
        default: '',
      },
      classesAdicionaisFooter: {
        type: String,
        required: false,
        default: 'p-2',
      },
      idBotaoFechar: {
        type: String,
        required: false,
        default: 'lmxta-basemodal-btn-fechar',
      },
      idBotaoSalvar: {
        type: String,
        required: false,
        default: 'lmxta-basemodal-btn-salvar',
      },
      temBotoesFooter: {
        type: String,
        required: false,
        default: '',
      },
    },
    emits: ['close', 'salvar'],
    setup(props, { emit }) {
      const methods = reactive({
        handleClose() {
          emit('close');
        },
        salvar() {
          emit('salvar');
        },
      });
      return {
        ...toRefs(methods),
      };
    },
  });
