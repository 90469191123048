import CampoTypes, { Campo } from '../../campos';

const { obterCampo } = CampoTypes;

export interface ConvenioFornecedor {
  diaFechamento: Campo<number | string>;
  diaCobranca: Campo<number | string>;
  limitePadrao: Campo<number>;
  isBloquearConveniador: Campo<boolean>;
}

export interface ConvenioCliente {
  conveniador: Campo<number | string>;
  matricula: Campo<string>;
  limite: Campo<number>;
  isBloquearConveniado: Campo<boolean>;
}

class PessoaConvenioTypes {
  public static obterConvenioCliente = (
    conveniador: Campo<number | string>,
    matricula: Campo<string>,
    limite: Campo<number>,
    isBloquearConveniado: Campo<boolean>,
  ): ConvenioCliente => {
    return {
      conveniador,
      matricula,
      limite,
      isBloquearConveniado,
    } as ConvenioCliente;
  };

  public static obterConvenioFornecedor = (
    limitePadrao: Campo<number>,
    isBloquearConveniador: Campo<boolean>,
    diaFechamento: Campo<number | string>,
    diaCobranca: Campo<number | string>,
  ): ConvenioFornecedor => {
    return {
      diaFechamento,
      diaCobranca,
      limitePadrao,
      isBloquearConveniador,
    } as ConvenioFornecedor;
  };
}

export default PessoaConvenioTypes;
