
  import { computed, defineComponent, reactive, ref, toRefs, watchEffect } from 'vue';

  import { vxm } from '@/store/index';

  import Campo from '@/components/shared/Campo.vue';
  import Input from '@/components/shared/Input.vue';
  import Multiselect from '@/components/shared/Multiselect.vue';
  import BaseModal from '@/components/shared/BaseModal.vue';
  import EncontraCEP from '@/components/clientes/cadastro/EncontraCEP.vue';
  import VueFormMultiselect from '@vueform/multiselect';

  import FiltrosExtension from '@/utils/filtros';
  import AlertsExtension from '@/utils/alerts';

  import PessoaModel from '@/core/models/pessoa';
  import { Estado, Pais } from '@/core/types/clientes/cadastro';
  import CamposTypes from '@/core/types/campos';
  import { ApiError } from '@/api/utils/handler';
  import PessoaEnderecoTypes, { Endereco, EnderecoAdicional } from '@/core/types/clientes/pessoa/endereco';

  export default defineComponent({
    name: 'SecaoEnderecoCadastro',
    components: {
      Campo,
      Input,
      Multiselect,
      BaseModal,
      EncontraCEP,
      VueFormMultiselect,
    },
    props: {
      idSecaoMenu: {
        type: String,
        default: '',
        required: true,
      },
    },
    setup() {
      const ultimoCepEnviadoViaCep = ref('');
      const idBrasilMultiselect = ref(0);
      const multiselectPaises = ref([] as Array<Pais>);
      const multiselectEstados = ref([] as Array<Estado>);
      const ultimoEstadoSelecionado = ref(0);
      const ultimoPaisSelecionado = ref(0);
      const isCampoCidadeDisabled = ref(false);
      const isCampoEstadoDisabled = ref(false);
      const isCampoPaisDisabled = ref(false);
      const faltaInserirCEP = ref(true);

      const { validarCep, isPessoaFisica } = PessoaModel;

      const isNovoCep = computed(() => ultimoCepEnviadoViaCep.value !== vxm.cadastro.pessoa.endereco.cep.valor);
      const isShowMultiselectTipoMoradia = computed(
        () => !vxm.cadastro.pessoa.isClienteInternacional.valor && isPessoaFisica(vxm.cadastro.pessoa.documento.valor),
      );

      const data = reactive({
        isCampoCidadeDisabled: vxm.cadastro.isCampoCidadeDisabled,
        isCampoEstadoDisabled: vxm.cadastro.isCampoEstadoDisabled,
        isCampoPaisDisabled: vxm.cadastro.isCampoPaisDisabled,
        faltaInserirCEP: vxm.cadastro.faltaInserirCEP,
      });

      const enderecosAdicionaisAtivos = computed(() =>
        vxm.cadastro.pessoa.endereco.enderecosAdicionais.valor.filter((e) => !e.excluido.valor),
      );

      const labelMultiselectEnderecoAdicional = (option: EnderecoAdicional): string => {
        if (!vxm.cadastro.isNovoEnderecoAdicional)
          return `${option.logradouro.valor}, ${option.numero.valor} - CEP: ${option.cep.valor}, ${option.bairro.valor} - ${option.cidade.valor} - ${option.estado.descricao}`;
        else return 'Novo endereço adicional';
      };

      const isCidadeObrigatorio = computed(() => {
        return (
          vxm.parametros.parametrosCrm.isObrigatorioCidade ||
          ((vxm.parametros.parametrosPortal.empModNfe ||
            vxm.parametros.parametrosPortal.empModSpedFiscal ||
            vxm.parametros.parametrosPortal.empModSpedPisCofins) &&
            !vxm.cadastro.pessoa.isClienteInternacional.valor)
        );
      });

      const mappingEnderecoClienteViaCepToEnderecoCliente = (enderecoCliente: Endereco) => {
        if (enderecoCliente) {
          const { cep, logradouro, numero, bairro, estado, cidade, complemento, pais, tipoMoradia } = enderecoCliente;
          vxm.cadastro.pessoa.endereco.cep.valor = cep.valor;
          vxm.cadastro.pessoa.endereco.logradouro.valor = logradouro.valor;
          vxm.cadastro.pessoa.endereco.numero.valor = numero.valor;
          vxm.cadastro.pessoa.endereco.bairro.valor = bairro.valor;
          vxm.cadastro.pessoa.endereco.estado.valor = estado.valor;
          vxm.cadastro.pessoa.endereco.cidade.valor = cidade.valor;
          vxm.cadastro.pessoa.endereco.complemento.valor = complemento.valor;
          vxm.cadastro.pessoa.endereco.pais.valor = pais.valor;
          vxm.cadastro.pessoa.endereco.tipoMoradia.valor = tipoMoradia.valor;
        }
      };

      const obterDadosEnderecoViaCep = () => {
        const isCepValido = validarCep(vxm.cadastro.pessoa.endereco.cep);

        if (vxm.cadastro.pessoa.endereco.cep.valor && isCepValido && isNovoCep.value) {
          vxm.loading.showLoading('Buscando dados do endereço ...');

          vxm.cadastro
            .obterDadosEnderecoViaCep(vxm.cadastro.pessoa.endereco.cep.valor)
            .then((enderecoCliente) => {
              vxm.cadastro.inseriuCep(true);

              mappingEnderecoClienteViaCepToEnderecoCliente(enderecoCliente);
              ultimoCepEnviadoViaCep.value = enderecoCliente.cep.valor;
              faltaInserirCEP.value = false;

              if (enderecoCliente.estado.valor && enderecoCliente.cidade.valor) {
                isCampoCidadeDisabled.value = true;
                isCampoEstadoDisabled.value = true;
                // vxm.cadastro.desabilitaCidadeEstado(true);
              } else {
                isCampoCidadeDisabled.value = false;
                isCampoEstadoDisabled.value = false;
                // vxm.cadastro.desabilitaCidadeEstado(false);
              }
            })
            .catch((error) => {
              if (error instanceof ApiError && error.status === 404) {
                const idEstado =
                  vxm.cadastro.dadosIniciaisParaCadastro.estados.find(
                    ({ descricao }) => descricao === vxm.parametros.parametrosCrm.estadoEmpresa,
                  )?.id || '';
                faltaInserirCEP.value = false;
                ultimoCepEnviadoViaCep.value = vxm.cadastro.pessoa.endereco.cep.valor;
                isCampoCidadeDisabled.value = false;
                isCampoEstadoDisabled.value = false;
                // vxm.cadastro.desabilitaCidadeEstado(false);
                const enderecosAdicionaisAux = vxm.cadastro.pessoa.endereco.enderecosAdicionais;
                vxm.cadastro.pessoa.endereco = PessoaEnderecoTypes.obterNovoEndereco(vxm.cadastro.pessoa.endereco.cep.valor);
                vxm.cadastro.pessoa.endereco.enderecosAdicionais = enderecosAdicionaisAux;
                //vxm.cadastro.pessoa.endereco.estado.valor = idEstado;
                AlertsExtension.exibirMensagemAlerta(error.title, true);
              } else AlertsExtension.exibirMensagemErro(error, false);
            })
            .finally(() => {
              vxm.loading.hideLoading();
            });
        }
      };

      const handleCampoPais = () => {
        if (idBrasilMultiselect.value === 0) {
          const idBrasil = vxm.cadastro.dadosIniciaisParaCadastro.paises.find(({ descricao }) => {
            return descricao === 'BRASIL';
          })?.id;
          if (idBrasil) idBrasilMultiselect.value = idBrasil;
        }

        if (vxm.cadastro.pessoa.isClienteInternacional.valor) {
          if (vxm.cadastro.pessoa.endereco.pais.valor !== 30)
            ultimoPaisSelecionado.value = Number(vxm.cadastro.pessoa.endereco.pais.valor);

          isCampoPaisDisabled.value = false;
          // vxm.cadastro.desabilitaPais(false);
          vxm.cadastro.pessoa.endereco.pais.valor = ultimoPaisSelecionado.value || '';
          handleMultiselectPais();
        } else {
          multiselectPaises.value = vxm.cadastro.dadosIniciaisParaCadastro.paises;
          vxm.cadastro.pessoa.endereco.pais.valor = idBrasilMultiselect.value;
          isCampoPaisDisabled.value = true;
          // vxm.cadastro.desabilitaPais(true);
        }
      };

      const handleCampoEstado = () => {
        const exibeAlertaClasseFiscal = ultimoEstadoSelecionado.value != vxm.cadastro.pessoa.endereco.estado.valor;
        if (vxm.cadastro.pessoa.endereco.estado.valor !== 9999)
          ultimoEstadoSelecionado.value = Number(vxm.cadastro.pessoa.endereco.estado.valor);

        if (vxm.cadastro.pessoa.isClienteInternacional.valor) {
          handleMultiselectEstado();
          vxm.cadastro.pessoa.endereco.estado.valor = 9999;
          // isCampoEstadoDisabled.value = true;
          vxm.cadastro.desabilitaEstado(true);
        } else {
          vxm.cadastro.pessoa.endereco.estado.valor = ultimoEstadoSelecionado.value || '';
          if (!vxm.cadastro.pessoa.endereco.estado.valor) isCampoEstadoDisabled.value = false;
          // if (!vxm.cadastro.pessoa.endereco.estado.valor) vxm.cadastro.desabilitaEstado(false);
          multiselectEstados.value = vxm.cadastro.dadosIniciaisParaCadastro.estados;
        }

        setaDescricaoEstado(Number(vxm.cadastro.pessoa.endereco.estado.valor));
        vxm.cadastro.sugerirClasseFiscalEndereco(exibeAlertaClasseFiscal);
      };

      const handleMultiselectEstado = () => {
        multiselectEstados.value = [
          CamposTypes.obterCampoMultiselect(9999, 'EX', true),
          ...vxm.cadastro.dadosIniciaisParaCadastro.estados,
        ];
      };

      const handleMultiselectPais = () => {
        multiselectPaises.value = vxm.cadastro.dadosIniciaisParaCadastro.paises.filter(({ descricao }) => descricao !== 'BRASIL');
      };

      const setaDescricaoPais = (idPais: number) => {
        const paisSelecionado = vxm.cadastro.dadosIniciaisParaCadastro.paises.find(({ id }) => idPais === id);
        if (paisSelecionado) vxm.cadastro.pessoa.endereco.pais.descricao = paisSelecionado.sigla;
      };

      const setaDescricaoEstado = (idEstado: number) => {
        const estadoSelecionado = vxm.cadastro.dadosIniciaisParaCadastro.estados.find(({ id }) => idEstado === id);
        if (estadoSelecionado) vxm.cadastro.pessoa.endereco.estado.descricao = estadoSelecionado.descricao;
      };

      const ufAlterado = (idEstado: number) => {
        setaDescricaoEstado(idEstado);
        vxm.cadastro.sugerirClasseFiscalEndereco();
      };

      // 2 - Chamar função na alteracao dos campos Estado(UF), Inscrição Estadual e Contribuinte de ICMS
      // const sugerirClasseFiscal = () => {
      //   const estado = vxm.cadastro.pessoa.endereco.estado.descricao;
      //   const contribuiente = vxm.cadastro.pessoa.isContribuinteIcms.valor;

      //   vxm.cadastro.pessoa.endereco.idClasseFiscal.valor = vxm.cadastro.obterSugestaoClasseFiscal(estado, contribuiente, vxm.cadastro.pessoa.endereco.idClasseFiscal.valor);
      // };

      const handleOnChangeMultiselectEnderecoAdicional = (option: EnderecoAdicional): void => {
        if (option) {
          vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor = JSON.parse(JSON.stringify(option));
        } else vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor = null;
      };

      const setaEstadoEmpresaLogada = (): void => {
        var parametros = vxm.parametros.parametrosCrm.isObrigatorioEstado;
        const ufEmpresaLogado = vxm.cadastro.dadosIniciaisParaCadastro.estados.find(
          ({ descricao }) => vxm.parametros.parametrosCrm.estadoEmpresa === descricao,
        );

        if (ufEmpresaLogado) vxm.cadastro.pessoa.endereco.estado.valor = ufEmpresaLogado.id;
      };

      const setaCidadeEmpresaLogada = (): void => {
        vxm.cadastro.pessoa.endereco.cidade.valor = vxm.parametros.parametrosCrm.cidadeEmpresa;
      };

      const methods = reactive({
        btnAbreModalEncontraCep: () => {
          vxm.cadastro.abrirModalCep();
        },
        fecharModalEncontraCep: () => {
          vxm.cadastro.fecharModalCep();
        },
      });

      watchEffect(() => {
        handleCampoPais();
        handleCampoEstado();
        if (vxm.cadastro.pessoa.endereco.logradouro.valor) faltaInserirCEP.value = false;
        if (!vxm.cadastro.pessoa.endereco.estado.valor && vxm.parametros.parametrosCrm.isObrigatorioEstado)
          setaEstadoEmpresaLogada();
        if (!vxm.cadastro.pessoa.endereco.cidade.valor && vxm.parametros.parametrosCrm.isObrigatorioCidade)
          setaCidadeEmpresaLogada();
      });

      return {
        ...toRefs(methods),
        ...toRefs(data),
        ...vxm,
        FiltrosExtension,
        PessoaModel,
        obterDadosEnderecoViaCep,
        isCampoCidadeDisabled,
        isCampoEstadoDisabled,
        isCampoPaisDisabled,
        isShowMultiselectTipoMoradia,
        multiselectPaises,
        multiselectEstados,
        setaDescricaoPais,
        setaDescricaoEstado,
        isCidadeObrigatorio,
        faltaInserirCEP,
        handleOnChangeMultiselectEnderecoAdicional,
        setaEstadoEmpresaLogada,
        setaCidadeEmpresaLogada,
        labelMultiselectEnderecoAdicional,
        enderecosAdicionaisAtivos,
        ufAlterado,
      };
    },
    mounted() {
      //if (!vxm.parametros.parametrosCrm.isObrigatorioEstado) this.setaEstadoEmpresaLogada();
      //if (!vxm.parametros.parametrosCrm.isObrigatorioCEP) this.setaCidadeEmpresaLogada();
    },
  });
