export enum TipoValidacao {
  required,
  cep,
  email,
  cpf,
  cnpj,
  telefone,
  bloquearDataFutura,
  bloquearMenorDeIdade,
  limiteCrediario,
  diferencialIcms,
  celular,
  cidade,
  inscricaoEstadual,
  ClasseFiscal,
  guid,
}

export type Erro = {
  mensagem: string;
};

export type Campo<T> = {
  label: string;
  descricao: T | string;
  valor: T;
  erros: Array<Erro>;
  validacoes: Array<TipoValidacao>;
};

export type CampoMultiselect = {
  id: number;
  descricao: string;
  ativo: boolean;
};

class CamposTypes {
  public static obterCampo = <T>(valor: T, descricao: T | string = '', label = '', erros = [], validacoes = []): Campo<T> => {
    if (!descricao) descricao = valor;

    return { valor, label, descricao, erros, validacoes } as Campo<T>;
  };

  public static obterCampoMultiselect = (id: number | string = 0, descricao = '', ativo = true): CampoMultiselect => {
    return { id, descricao, ativo } as CampoMultiselect;
  };

  public static obterErro = (mensagem: string): Erro => {
    return { mensagem } as Erro;
  };

  public static isCampo = (campo: any): boolean => {
    return (
      campo.descricao !== undefined &&
      campo.valor !== undefined &&
      campo.erros !== undefined &&
      campo.validacoes !== undefined &&
      campo.descricao !== undefined
    );
  };
}

export default CamposTypes;
