
  import { computed, defineComponent } from 'vue';

  export default defineComponent({
    name: 'Loading',
    props: {
      mensagem: {
        type: String,
        default: () => 'Aguarde...',
      },
    },
    setup(props) {
      const loadingMensagem = computed(() => {
        if (!props.mensagem) return 'Aguarde...';
        return props.mensagem;
      });

      return {
        loadingMensagem,
      };
    },
  });
